/* eslint-disable react-hooks/exhaustive-deps  */
import {
  Stack,
  Typography,
  createTheme,
  ThemeProvider,
  Button,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import averta from "../../assets/image/averta.png";
// import BTC from "../../assets/image/BTC.png";
import { Box } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { alpha } from "@mui/material/styles";

// eslint-disable-next-line no-unused-vars
import {
  getAllBotsByOwner,
  getAllSaleBotsByOwner,
} from "../../services/smart contract/Smartcontract";
// eslint-disable-next-line no-unused-vars
import TradingBot from "../../services/smart contract/TradingBot";
// eslint-disable-next-line no-unused-vars
import { id } from "ethers/lib/utils";
// eslint-disable-next-line no-unused-vars
import { PostAddSharp } from "@mui/icons-material";
// eslint-disable-next-line no-unused-vars

import { ethers } from "ethers";
import { getUserById } from "../../services/api";
import { useParams } from "react-router-dom";

const theme = createTheme({
  // Tell MUI what the font-size on the html element is.
  typography: {
    subtitle1: {
      fontSize: 33,
      fontFamily: "Montserrat",
      fontWeight: 300,
      color: "#F5FBF2",
      letterSpacing: "0.0075em",
      verticalAlign: "middle",
      alignItems: "center",
      textAlign: "center",
      main: "#F5FBF2",
      marginTop: 20,
    },
    subtitle2: {
      fontSize: 17,
      fontFamily: "Montserrat",
      fontWeight: 300,
      color: "#FFFFFF",
      letterSpacing: "0.0075em",
      verticalAlign: "middle",
      alignItems: "center",
      textAlign: "left",
      main: "#F5FBF2",
      marginTop: 20,
    },
    leftsearch: {
      alignItems: "right",
      textAlign: "right",
    },
    tableToptext: {
      fontWeight: 600,
    },
  },
  palette: {
    neutral: {
      main: "#FFCE4E",
      contrastText: "#000000",
    },
    AppColor: {
      main: "#302034",
      contrastText: "#000000",
    },
    createbutton: {
      main: "#FFFFFF",
      contrastText: "#FFFFFF",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 20,
    fontWeight: 600,
    backgroundColor: "#251928",
    color: "#f0f0f0",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "#f0f0f0",
    backgroundColor: "#251928",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
// eslint-disable-next-line no-unused-vars
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const ViewOtherProfile = () => {
  const [data, setData] = useState([]);
  const [botList, setBotList] = useState([]);
  // const [botOnSaleList, setBotOnSaleList] = useState([]);

  const params = useParams();
  const userID = params.id;

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function GetAllBotsByOwner() {
    const tradingBotList = await getAllBotsByOwner(userID);
    setBotList(tradingBotList);
  }
  async function GetAllSaleBotsByOwner() {
    const tradingBotList = await getAllSaleBotsByOwner(userID);
    setBotList(tradingBotList);
  }

  async function checkauthaddress() {
    try {
      const res = await getUserById(userID);
      setData(res);
    } catch (error) {
      console.error(`Could not get products: ${error}`);
    }
  }

  useEffect(() => {
    checkauthaddress();
    if (userID && value === 0) {
      GetAllBotsByOwner();
    } else if (userID && value === 1) {
      GetAllSaleBotsByOwner();
    }
  }, [userID, value]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        {/* <Typography variant="subtitle1">Profile address:{userID}</Typography> */}
        <Box direction="column" pb={10} m={3}>
          <Stack direction="column">
            <Box>
              <Stack direction="row">
                <Stack pr={10}>
                  <Avatar
                    alt="Remy Sharp"
                    src={data.image}
                    sx={{ width: 160, height: 160 }}
                  />
                </Stack>
                <Box direction="column">
                  {/* {data.map(() => { */}
                  <Stack direction="column" pr={50}>
                    {/* <Typography variant="subtitle2">{data.name}</Typography> */}
                    <Typography variant="subtitle2" pb={2}>
                      {/* BIO */}
                      ID{" : "}
                      {data._id}
                    </Typography>
                    <Typography variant="subtitle2">
                      {/* binance API:  */}
                      Name {": "}
                      {data.name}
                      {/* {userID} */}
                      {/* {data._id} */}
                    </Typography>
                    <Typography variant="subtitle2" pb={2}>
                      {/* BIO */}
                      Role{" : "}
                      {data.role}
                    </Typography>
                    {/* <Button
                      href="edituserprofile"
                      variant="outlined"
                      color="neutral"
                      sx={{ fontSize: 15, width: "130px" }}
                    >
                      Edit profile
                    </Button> */}
                  </Stack>
                  ;{/* })} */}
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Box width={500} variant="subtitle2">
          <Search
            sx={{
              mt: 2,
            }}
          >
            <StyledInputBase
              placeholder="Search me here ... "
              inputProps={{
                "aria-label": "search",
                style: { fontFamily: "Arial", color: "white" },
              }}
              fontcolor="green"
            />
          </Search>
        </Box>

        <Box direction="column" m={3}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Collection" />
            <Tab label="Bot on Sale" />
          </Tabs>
          <Paper>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">ID</StyledTableCell>
                    <StyledTableCell align="center">Bot</StyledTableCell>
                    <StyledTableCell align="center">
                      Description
                    </StyledTableCell>
                    <StyledTableCell align="center">Creator</StyledTableCell>
                    <StyledTableCell align="center">Price</StyledTableCell>
                    <StyledTableCell align="center">On Sale</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {botList.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Box direction="row">
                          <Stack direction="row">
                            <Button
                              // href={`viewotherprofile` + data._id}
                              disabled
                            >
                              <Grid
                                pr={2}
                                display="flex"
                                justifyContent="flex-start"
                              >
                                <Avatar alt="Remy Sharp" src={row.image} />
                              </Grid>
                              <Typography textAlign="center" color="white">
                                {row.displayName}
                              </Typography>
                            </Button>
                          </Stack>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Stack>
                          <Typography color="white">
                            {row.description}
                          </Typography>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.OwnerAddress}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {ethers.utils.formatEther(row.price)} ETH
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box direction="row">
                          <Stack direction="row" justifyContent="center">
                            <Button href="viewownprofile">
                              <Grid pr={2}>
                                <Avatar alt="Remy Sharp" src={averta} />
                              </Grid>
                              <Typography textAlign="center" color="white">
                                {row.creator}
                              </Typography>
                            </Button>
                          </Stack>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          variant="contained"
                          color="neutral"
                          href={`/viewbotdetail/` + row.id}
                        >
                          Detail
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default ViewOtherProfile;
