import Web3 from "web3";
import { ethers } from "ethers";
async function ValidatechainId() {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const chainId = await provider.getNetwork().then(network => network.chainId);
  window.localStorage.setItem("ChainId", chainId);
  console.log("this is my chain id",chainId);
  return chainId;
}
const web3 = new Web3(window.ethereum);
// create local storage var user have metamask or not
// if User have metamask var metamask = true
if (window.ethereum) {
  window.localStorage.setItem("metamask", "true");
  window.ethereum.request({ method: "eth_requestAccounts" });
  ValidatechainId();
} else {
  window.localStorage.setItem("metamask", "false");
}

export default web3;
