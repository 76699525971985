import {
  Avatar,
  Button,
  ButtonBase,
  createTheme,
  Typography,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import { getBotById } from "../../services/smart contract/Smartcontract";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { buyTradingBot } from "../../../src/services/smart contract/Smartcontract";
import { styled } from "@mui/material/styles";
import {getOwnUserDetails} from '../../services/api'
import { useEffect } from "react";
import moment from "moment";
import { ethers } from "ethers";
import { v4 as uuidv4 } from "uuid";

import {
  createContainer,
  removeContainer,
  stopContainer,
} from "../../services/dockerApi";

const Eachbotforsale = (DisplayBalance) => {
  // eslint-disable-next-line no-unused-vars
  const theme = createTheme({
    // Tell MUI what the font-size on the html element is.
    typography: {
      subtitle1: {
        fontSize: 33,
        fontFamily: "Montserrat",
        fontWeight: 300,
        color: "#F5FBF2",
        letterSpacing: "0.0075em",
        verticalAlign: "middle",
        alignItems: "center",
        textAlign: "center",
        main: "#F5FBF2",
        marginTop: 20,
      },
    },
  });
  // eslint-disable-next-line no-unused-vars
  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  });
  // eslint-disable-next-line no-unused-vars
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: "60px",
  }));
  const [data, setData] = useState({});
  const [UserAccount, SetUserAccount] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [ownerbot, setOwnerBot] = useState([]);
  const [isowner, setIsOwner] = useState(false);
  const [selectedAPI, setSelectedAPI] = useState([]);
  const [inputcredentail, setInputcredentail] = useState(false);
  const params = useParams();
  const userID = params.id;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const lightTheme = createTheme({ palette: { mode: "light" } });
  const [formData, setFormData] = useState({
    price: 0,
  });

  // eslint-disable-next-line no-unused-vars
  const { id, price } = formData;
  // eslint-disable-next-line no-unused-vars
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  // eslint-disable-next-line no-unused-vars
  const handleClickOpen = () => {
    setOpen(true);
  };

  const Close = () => {
    setInputcredentail(false);
  };
  const Open = () => {
    setInputcredentail(true);
  };

  const buyeraccount = window.localStorage.getItem("useraddress");
  const tokens = window.localStorage.getItem("token");
  const account = window.localStorage.getItem("useraddress");

  const onSubmit = async (pre_containerName, imageName) => {
    formData.id = data.id - 1;

    const containerName = uuidv4();
    try {
      if (DisplayBalance.DisplayBalance >= data.price) {
        setOpen(false);

        console.log("previous container name", pre_containerName);
        console.log("seleteAPI", selectedAPI)
        // split value of selectedAPI into API_KEY and API_SECRET
        const [binanceCredentialID, API_KEY, API_SECRET] = selectedAPI.split(',');
         // Marge API_KEY and API_SECRET into Array Formate [API_KIEY=API_KEY,API_SECRET=API_SECRET]
        const API_Value = [ `API_KEY=${API_KEY}`, `API_SECRET=${API_SECRET}`];

        await stopContainer(pre_containerName);
        await removeContainer(pre_containerName);
        console.log("new container name", containerName, "image name", imageName, "selected api", API_Value)

        await createContainer(containerName,imageName,API_Value );
        console.log("id bot", formData.id, "buyer account", buyeraccount, "price", data.price)
        // botId, newDockerContainerID, newBinanceCredentialID, buyerAddress, value
        await buyTradingBot(formData.id,containerName,binanceCredentialID, buyeraccount, data.price);
        navigate("/viewownprofile");
      } else if (DisplayBalance < ethers.utils.formatEther(data.price)) {
        setOpen(false);
      }
    } catch (error) {}
  };
  const handleClose = () => {
    setOpen(false);
  };
  // async function stop() {
  //   await stopContainer(containerName);
  // }

  // async function start() {
  //   await startContainer(containerName);
  // }

  useEffect(() => {
    // GetAllBotsByOwner()
    async function checkauthaddress() {
      try {
        try {
          const res = await getBotById(userID);
          setData({ ...res, price: ethers.utils.formatEther(res.price) });
        } catch (error) {
          console.error(`Could not get products: ${error}`);
        }
        if (account === data.OwnerAddress) {
          setIsOwner(false);
        } else if (account !== data.OwnerAddress) {
          setIsOwner(true);
        }
      } catch (error) {
        console.error(`Could not get products: ${error}`);
      }
    }

    async function GetOwnUserDetials(){
      const res = await getOwnUserDetails(account, tokens);
      SetUserAccount(res);
      console.log("UserAccount", res);
    }
    if (account !== null && tokens !== null) {
      GetOwnUserDetials();
    }
    checkauthaddress();
  }, [isowner, userID, account, data.OwnerAddress,tokens]);

  return (
    <Paper
      sx={{
        p: 3,
        mt: 10,
        background: "#302034",
      }}
    >
      <Paper
        sx={{
          p: 2,

          margin: "auto",
          maxWidth: 1000,
          backgroundColor: "#170F1A",
        }}
      >
        <Grid container spacing={2} sx={{ backgroundColor: "#170F1A" }}>
          <Grid item>
            <ButtonBase sx={{ width: 300, height: 120 }}>
              <Avatar sx={{ width: 60, height: 60 }} src={data.image} />
              <Typography
                sx={{ cursor: "pointer", fontWeight: 500, fontSize: 15, ml: 2 }}
                color="#A6A6A6"
                variant="body2"
              >
                Bot Name : {data.displayName}
              </Typography>
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item>
                <Typography
                  sx={{ mt: 6, ml: 5, fontWeight: 400, fontSize: 15 }}
                  color="#A6A6A6"
                  variant="body2"
                >
                  {data.price} ETH
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item>
                <Typography
                  sx={{ mt: 6, ml: 5, fontWeight: 400, fontSize: 15 }}
                  color="#A6A6A6"
                  variant="body2"
                >
                  {moment.unix(Number(data.timeStamp)).format("L")}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              sx={{
                mt: 5,
                ml: 10,
                fontWeight: 30,
                color: "#A6A6A6",
                cursor: "pointer",
                fontSize: 20,
              }}
              variant="body2"
            >
              share
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ color: "white" }}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  sx={{ fontWeight: 600, fontSize: 20 }}
                  component="div"
                  color="#A6A6A6"
                >
                  Bot Details
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ color: "#A6A6A6", mt: 2 }}
                >
                  {data.description}
                </Typography>
                <Typography variant="subtitle1" component="p" color="#ffcc33">
                  created by: 1030114
                </Typography>
              </Grid>
              <Grid item>
              { data.isSaleBot === true && isowner ? (
                <Grid>
                  <Dialog open={open} onClose={handleClose}>
                    <Box
                      style={{
                        width: 400,
                        height: 220,
                        background: "#302034",
                      }}
                    >
                      <DialogTitle color="#ededed">Buy Trading Bot</DialogTitle>
                      <DialogContent>
                        <DialogContentText color="#ededed" sx={{ fontSize: 15 }}>
                          Comnfirm Payment
                        </DialogContentText>
                        <DialogContentText
                          color="#b28900"
                          sx={{ fontSize: 15, mt: 3 }}
                        >
                          {data.price} ETH
                          {/* {ethers.utils.formatEther(data.price)} ETH */}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions
                        sx={{
                          background: "#302034",
                        }}
                      >
                        <Button
                          sx={{
                            ":hover": {
                              color: "#12db00",
                            },
                          }}
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          sx={{
                            ":hover": {
                              color: "#12db00",
                            },
                          }}
                          // onClick={onSubmit}
                          onClick={Open}
                        >
                          Ok
                        </Button>
                      </DialogActions>
                    </Box>
                  </Dialog>

                    <Dialog open={inputcredentail} onClose={Close}>
                      <Box
                        style={{
                          width: 400,
                          height: 350,
                          background: "#302034",
                        }}
                      >
                        <DialogTitle
                          fontSize="2.4rem"
                          fontWeight="800"
                          color="#ededed"
                        >
                          Selete API
                        </DialogTitle>
                        <DialogContent>
                          <Select
                            sx={{
                                color: "white",
                                width: "100%!important",
                                ".MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(228, 219, 233, 0.25)",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                  // borderColor: "rgba(228, 219, 233, 0.25)",
                                  borderColor: "white",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "rgba(228, 219, 233, 0.25)",
                                },
                                ".MuiSvgIcon-root ": {
                                  fill: "white !important",
                                },
                              }} 
                            value={selectedAPI} 
                            onChange={(e) => setSelectedAPI(e.target.value)}>
                            {UserAccount?.credentials?.map((credential, index) => (
                              <MenuItem color="black" key={index} value={`${credential.credentialID},${credential.apiKey},${credential.secretKey}`}>
                                {credential.credentialName}
                              </MenuItem>
                            ))}
                          </Select>

                        </DialogContent>
                        <DialogActions
                          sx={{
                            background: "#302034",
                          }}
                        >
                          <Button
                            sx={{
                              color: "#BA6074",
                              ":hover": {
                                color: "#DB0014",
                              },
                            }}
                            onClick={Close}
                          >
                            Cancel
                          </Button>
                          <Button
                            sx={{
                              color: "#60BA6D",
                              ":hover": {
                                color: "#12db00",
                              },
                            }}
                            onClick={ () => {onSubmit(data.dockerContainerID,data.dockerImageID)}}
                          >
                            Ok
                          </Button>
                          {/* <Button onClick={Logcredentail}>Log</Button> */}
                        </DialogActions>
                      </Box>
                    </Dialog>

                  <Button
                    onClick={() => {
                      setOpen(true);
                    }}
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{
                      fontSize: 15,
                      borderRadius: 12.5,
                      ml: 2,
                      cursor: "pointer",
                    }}
                  >
                    BUY
                  </Button>

                </Grid>
              )
              : <></>}
                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

export default Eachbotforsale;
