import {
  Button,
  Container,
  createTheme,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { addUserBinanceCredential } from "../../services/api";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import Alert from "@mui/material/Alert";
import { useEffect } from "react";

const CreateAPIKey = () => {
  // get input img
  const tokens = window.localStorage.getItem("token");
  const account = window.localStorage.getItem("useraddress");

  //creating a refernce for TextField Component
  const [APIKey, setAPIKey] = useState(" ");
  const [SecreteKey, setSecretKey] = useState("");
  const [APIName, setAPIName] = useState("");
  const [alert, setAlert] = useState(false);
  const [ResMessage, setResMessage] = useState("");
  const [AlertType, setAlertType] = useState("error");

  const navigate = useNavigate();
  //eslint-disable-next-line no-unused-vars

  const APIChange = (e) => {
    setAPIKey(e.target.value);
  };
  const SecretChange = (e) => {
    setSecretKey(e.target.value);
  };

  const APINameChange = (e) => {
    setAPIName(e.target.value);
  };
  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, 4000);
  })

  // theme styles
  const theme = createTheme({
    typography: {
      title: {
        fontFamily: "Oxanium, cursive",
        fontSize: 40,
        fontWeight: 600,
        color: "White",
      },
      subtitle: {
        fontFamily: "Oxanium, sans-serif",
        fontSize: 24,
        fontWeight: 600,
        color: "White",
      },
      caption: {
        fontFamily: "Work sans, cursive",
        fontSize: 16,
        fontWeight: 200,
        color: "White",
      },
    },
  });
  // textFiel Style
  const customTextFieldStyling = {
    "& label.Mui-focused": {
      color: "white",
    },
    "&:placeholder": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        borderWidth: "2px",
        color: "#FFF",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
      color: "white",
    },
  };

  // eslint-disable-next-line no-unused-vars
  async function CreateAPI() {
   
    // const smartRes = 
    // console.log(smartRes);
    // console.log(smartRes.response.data.message);
    console.log(2);
    let Clean_APIKey = APIKey.trim();
    let Clean_SecreteKey = SecreteKey.trim();
    console.log('Clean_APIKey', Clean_APIKey,'Clean_SecreteKey', Clean_SecreteKey)
    
    const res = await addUserBinanceCredential(
      account,
      tokens,
      Clean_APIKey,
      Clean_SecreteKey,
      APIName
    );
    console.log('res', res);
    console.log('res =', res.status);
    if(res.status === 400){
      setAlert(true);
      setResMessage(res.data.message)
    } else if(res.status === 201){
      setAlertType("success");
      setResMessage("API Key Added Successfully")
      setAlert(true);
      setTimeout(() => {
        
        navigate(`/viewownprofile/${account}`)
      }, 4000);
      
    }
    // Post succeue 201 and 400 Error
    
      
    
    // navigate(`/viewownprofile/${account}`);
    
    }
  return (
    <form>
      <ThemeProvider theme={theme}>
        <Container maxWidth="lg" className="p-4 pb-20">
          <Stack direction="column" spacing={4}>
            <div>
              <Typography
                display="block"
                variant="title"
                className="text-white"
              >
                Create API
              </Typography>
              <Typography display="block" variant="caption">
                <span style={{ marginRight: 2, color: "#e91e63" }}>*</span>
                Required fields
              </Typography>
            </div>
            {/* <div className="space-y-4">
              <SndTitle text="Image" isRequired={true} />
              <CustomImageInput image={image} onImageChange={onImagechange} />
            </div> */}
            <div className="space-y-4">
              <SndTitle text="API Name" isRequired={true} />
              <TextField
                fullWidth
                value={APIName}
                onChange={APINameChange}
                type="text"
                variant="outlined"
                placeholder="API name"
                className="w-full"
                required
                sx={customTextFieldStyling}
              />
            </div>
            <div className="space-y-4">
              <SndTitle text="API Key" isRequired={true} />
              <TextField
                fullWidth
                value={APIKey}
                onChange={APIChange}
                type="text"
                variant="outlined"
                placeholder="API Key"
                className="w-full"
                required
                sx={customTextFieldStyling}
              />
            </div>
            <div className="space-y-4">
              <SndTitle text="Secrete Key" isRequired={true} />
              <TextField
                fullWidth
                value={SecreteKey}
                onChange={SecretChange}
                type="text"
                variant="outlined"
                placeholder="Secrete Key"
                className="w-full"
                required
                sx={customTextFieldStyling}
              />
            </div>
          </Stack>
          <div>
            <Button
              // href="viewownprofile"
              variant="outlined"
              onClick={CreateAPI}
              color="secondary"
              style={{
                fontWeight: "bold",
                width: 100,
                margin: "auto",
                marginTop: 30,
                marginBottom: 30,
                fontSize: 16,
                padding: "6px 12px",
                display: "flex",
                color: "#212121",
                backgroundColor: "#ffc400",
                "&:hover": {
                  backgroundColor: "#F5FBF2",
                },
              }}
            >
              Create
            </Button>
            {/* <Button onClick={test}>Test</Button> */}
          </div>
        </Container>
        <Dialog open={alert}>
          <Alert variant="filled" severity={AlertType}>
            {ResMessage}
          </Alert>
        </Dialog>
      </ThemeProvider>
    </form>
  );
};
function SndTitle({ text, isRequired = false }) {
  return (
    <Typography display="block" variant="subtitle">
      {text}
      {isRequired && <span style={{ color: "#e91e63", marginLeft: 3 }}>*</span>}
    </Typography>
  );
}

export default CreateAPIKey;
