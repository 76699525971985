import Landingpage from '../pages/Landing-Page/Landing-Page'
import Footer from '../components/Footer/Footer'

const Main = ({DisplayBalance, account}) =>{
     
   
    return(
       <div >
      
        <Landingpage DisplayBalance={DisplayBalance} account={account} />
        <Footer />
        
        
       </div>
    );
}

export default Main;