import styled from "@emotion/styled";
import {
  Avatar,
  Button,
  ButtonBase,
  Grid,
  Typography,
  Box,
  Dialog,
  createTheme,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem
} from "@mui/material";
import { buyTradingBot } from "../../../src/services/smart contract/Smartcontract";
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { ethers } from "ethers";
import { useState } from "react";
import {getOwnUserDetails, connectBinanceCredential} from '../../services/api'
import { v4 as uuidv4 } from "uuid";
import {
  createContainer,
  removeContainer,
  stopContainer,
} from "../../services/dockerApi";

// eslint-disable-next-line no-unused-vars
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const Cardallbotforsale = ({ props, DisplayBalance }) => {
  const [open, setOpen] = React.useState(false);
  const [inputcredentail, setInputcredentail] = useState(false);
  const [selectedAPI, setSelectedAPI] = useState([]);
  const [UserAccount, SetUserAccount] = useState([]);

  const [formData, setFormData] = useState({
    price: 0,
  });
  // eslint-disable-next-line no-unused-vars
  const { id, price } = formData;
  // eslint-disable-next-line no-unused-vars
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  // eslint-disable-next-line no-unused-vars
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const Close = () => {
    setInputcredentail(false);
  };
  const Open = () => {
    setInputcredentail(true);
  };

  // const containerName = props.dockerContainerID;
  const tokens = window.localStorage.getItem("token");
  const account = window.localStorage.getItem("useraddress");

  // async function RestartContainer () {
  //   const result = await restartContainer(containerName)
  //   console.log("result here : ",result);
  // }

  const buyeraccount = window.localStorage.getItem("useraddress");
  const navigate = useNavigate();


  const onSubmit = async (e) => {
    formData.id = props.id - 1;
    const pre_containerName = props.dockerContainerID;
    const imageName = props.dockerImageID;
    const containerName = uuidv4();
    try {
      if (DisplayBalance >= ethers.utils.formatEther(props.price)) {
        formData.price = ethers.utils.formatEther(props.price);
        setOpen(false);

        console.log("previous container name", pre_containerName);
        console.log("seleteAPI", selectedAPI)

        // split value of selectedAPI into API_KEY and API_SECRET
        const [binanceCredentialID, API_KEY, API_SECRET] = selectedAPI.split(',');
         // Marge API_KEY and API_SECRET into Array Formate [API_KIEY=API_KEY,API_SECRET=API_SECRET]
        const API_Value = [ `API_KEY=${API_KEY}`, `API_SECRET=${API_SECRET}`];
        
        await stopContainer(pre_containerName);
        await removeContainer(pre_containerName);
        console.log("new container name", containerName, "image name", imageName, "selected api", API_Value)
        await createContainer(containerName,imageName,API_Value);

        await buyTradingBot(formData.id,containerName,binanceCredentialID, buyeraccount, formData.price);
        await connectBinanceCredential(account,binanceCredentialID, tokens)

        navigate("/viewownprofile");
      } else if (DisplayBalance < ethers.utils.formatEther(props.price)) {
        setOpen(false);
      }
    } catch (error) {}
  };

  const theme = createTheme({
    // Tell MUI what the font-size on the html element is.
    typography: {
      subtitle1: {
        fontSize: 33,
        fontFamily: "Montserrat",
        fontWeight: "bold",
        color: "#F5FBF2",
        letterSpacing: "0.0075em",
        verticalAlign: "middle",
        alignItems: "center",
        textAlign: "center",
        main: "#F5FBF2",
      },
      subtitle2: {
        color: "#9B9E9A",
        main: "#9B9E9A",
        fontSize: 22,
      },
      yellofond: {
        color: "#FFCE4E",
        fontWeight: "bold",
      },
    },
    palette: {
      primary: {
        main: "#0971f1",
        darker: "#053e85",
      },
      neutral: {
        main: "#FFCE4E",
        contrastText: "#000000",
      },
      createbutton: {
        main: "#FFFFFF",
        contrastText: "#FFFFFF",
      },
    },
  });

  useEffect(() => {
    async function GetOwnUserDetials(){
      const res = await getOwnUserDetails(account, tokens);
      SetUserAccount(res);
    }
    if(account && tokens){
      GetOwnUserDetials();
    }
  },[account, tokens]);
  return (
    <Grid
      theme={theme}
      container
      spacing={2}
      sx={{
        backgroundColor: "#170F1A",
        borderRadius: 3,
      }}
    >
      <Grid item>
        <ButtonBase
          href={`/eachbotforsale/` + props.id}
          sx={{ width: 128, height: 128 }}
        >
          <Avatar sx={{ width: 90, height: 90 }} src={props.image} />
        </ButtonBase>
      </Grid>
      <Grid item xs={12} sm container>
        <Grid item xs container direction="column" spacing={1}>
          <Grid item>
            <Typography
              sx={{ cursor: "pointer", mt: 6, fontWeight: 500, fontSize: 15 }}
              variant="body2"
            >
              {props.displayName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs container direction="column" spacing={3}>
          <Grid item>
            <Typography
              sx={{ cursor: "pointer", mt: 6, fontWeight: 400, fontSize: 15 }}
              variant="body2"
            >
              {ethers.utils.formatEther(props.price)} Ether
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs container direction="column" spacing={1}>
          <Grid item>
            <Typography
              sx={{
                cursor: "pointer",
                mt: 6,
                ml: 10,
                fontWeight: 10,
                color: "#ffcc33 ",
              }}
              variant="body2"
            >
              {moment.unix(Number(props.timeStamp)).format("L")}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs container direction="column" spacing={1}>
          <Grid item>
            {props.isSaleBot === true && props.OwnerAddress !== account ? (
              <Grid
                sx={{
                  cursor: "pointer",
                  mt: 5,
                  ml: 10,
                  fontWeight: 10,
                }}
              >
                <Dialog open={open} onClose={handleClose}>
                  <Box
                    style={{
                      width: 400,
                      height: 220,
                      background: "#302034",
                    }}
                  >
                    <DialogTitle color="#ededed">Buy Trading Bot</DialogTitle>
                    <DialogContent>
                      <DialogContentText color="#ededed" sx={{ fontSize: 15 }}>
                        Comnfirm Payment
                      </DialogContentText>
                      <DialogContentText color="#b28900" sx={{ fontSize: 40 }}>
                        {ethers.utils.formatEther(props.price)} ETH
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      sx={{
                        background: "#302034",
                      }}
                    >
                      <Button
                        sx={{
                          ":hover": {
                            color: "#12db00",
                          },
                        }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          ":hover": {
                            color: "#12db00",
                          },
                        }}
                        onClick={Open}
                      >
                        Ok
                      </Button>
                    </DialogActions>
                  </Box>
                </Dialog>

                <Dialog open={inputcredentail} onClose={Close}>
                    <Box
                      style={{
                        width: 400,
                        height: 350,
                        background: "#302034",
                      }}
                    >
                      <DialogTitle
                        fontSize="2.4rem"
                        fontWeight="800"
                        color="#ededed"
                      >
                        Selete API
                      </DialogTitle>
                      <DialogContent>
                        <Select
                          sx={{
                              color: "white",
                              width: "100%!important",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                // borderColor: "rgba(228, 219, 233, 0.25)",
                                borderColor: "white",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(228, 219, 233, 0.25)",
                              },
                              ".MuiSvgIcon-root ": {
                                fill: "white !important",
                              },
                            }} 
                          value={selectedAPI} 
                          onChange={(e) => setSelectedAPI(e.target.value)}>
                          {UserAccount?.credentials?.map((credential, index) => (
                            <MenuItem color="black" key={index} value={`${credential.credentialID},${credential.apiKey},${credential.secretKey}`}>
                              {credential.credentialName}
                            </MenuItem>
                          ))}
                        </Select>

                      </DialogContent>
                      <DialogActions
                        sx={{
                          background: "#302034",
                        }}
                      >
                        <Button
                          sx={{
                            color: "#BA6074",
                            ":hover": {
                              color: "#DB0014",
                            },
                          }}
                          onClick={Close}
                        >
                          Cancel
                        </Button>
                        <Button
                          sx={{
                            color: "#60BA6D",
                            ":hover": {
                              color: "#12db00",
                            },
                          }}
                          onClick={ () => {onSubmit()}}
                        >
                          Ok
                        </Button>
                        {/* <Button onClick={Logcredentail}>Log</Button> */}
                      </DialogActions>
                    </Box>
                  </Dialog>

                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  sx={{
                    fontSize: 12,
                    fontWeight: "bold",
                    width: 140,
                    color: "#333",
                    backgroundColor: "#ffcc33",
                  }}
                >
                  Buy Now
                </Button>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Cardallbotforsale;
