import TeamMemberStyleWrapper from "./teawmmember.style"

const TeamMember = ({ averta, name, designation, socialLinks}) => {
    return(
        <TeamMemberStyleWrapper className="team-item" style={{borderRadius:16}}>
            <a href={`/team-details/`+ name}>
                <img src={averta} alt="Team-thumb" style={{borderRadius:16}} />
                
            </a>

            <h4 className="team-title mb-8">
                <a href={`/team-details/`+ name}>{name}</a>
            </h4>
            <div className="dsc">{designation}</div>
            <ul className="team-icon-list">
                {socialLinks?.map((item, i) => (
                <li key={i}>
                    <a href={item.url} target="_blank">{item.icon}</a>
                </li>
                ))}
            </ul>


        </TeamMemberStyleWrapper>


    )
}


export default TeamMember;