import {
  InputBase,
  styled,
  Typography,
  Grid,
  Paper,
  IconButton,
  Divider,
  TextField,
  Button,
} from "@mui/material";
import { Box, createTheme, ThemeProvider } from "@mui/system";
import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SearchIcon from "@mui/icons-material/Search";
// import { ContentCopy } from "@mui/icons-material";

const theme = createTheme({
  // Tell MUI what the font-size on the html element is.
  typography: {
    subtitle1: {
      fontSize: 50,
      fontFamily: "Oxanium, cursive",
      fontWeight: 300,
      color: "#F5FBF2",
      letterSpacing: "0.0075em",
      verticalAlign: "middle",
      marginRight: 'auto',
      alignItems: "center",
      textAlign: "center",
      main: "#F5FBF2",
      marginTop: 100,
    },
    subtitle2: {
      fontSize: 20,
      fontFamily: "Oxanium, cursive",
      fontWeight: 100,
      textAlign: "center",
      color: "#F5FBF2",
      main: "#F5FBF2",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#4E2658",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  flexDirection: "row",
  color: theme.palette.text.secondary,
}));

const SupportPage = () => {
  const [text, setText] = useState();

  const textChange = (e) => {
    setText(e.target.value);
  };
  function sendText() {
    console.log(text);
  }
  return (
    <div>
      {" "}
      <ThemeProvider theme={theme}>
        <Typography variant="subtitle1">How can we help you?</Typography>
      </ThemeProvider>
      {/* Search  */}
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          margin: "auto",
          marginTop: 5,
          width: 800,
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="search"
          inputProps={{ "aria-label": "search" }}
        />
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>

        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      </Paper>
      <Box
        sx={{
          margin: "auto",
          marginTop: 10,
          alignItems: "center",
          paddingX: 5,

          width: 800,
          height: 300,
        }}
      >
        <Grid
          margin={"auto"}
          alignItems="center"
          container
          rowSpacing={8}
          columnSpacing={10}
        >
          <Grid item xs={5}>
            <Box>
              {" "}
              <Item>
                <ThemeProvider theme={theme}>
                  {" "}
                  <Typography variant="subtitle2">Help & Support</Typography>
                </ThemeProvider>
              </Item>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Item>
              <ThemeProvider theme={theme}>
                {" "}
                <Typography variant="subtitle2">Item Details</Typography>
              </ThemeProvider>
            </Item>
          </Grid>
          <Grid item xs={5}>
            <Item>
              <ThemeProvider theme={theme}>
                {" "}
                <Typography variant="subtitle2">Author Profile</Typography>
              </ThemeProvider>
            </Item>
          </Grid>
          <Grid item xs={5}>
            <Item>
              <ThemeProvider theme={theme}>
                {" "}
                <Typography variant="subtitle2">Collection</Typography>
              </ThemeProvider>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <Divider
        sx={{
          width: "100%",
          margin: "auto",
          mt: 3,
          mb: 3,
          maxWidth: 1000,
          bgcolor: "background.paper",
        }}
      />
      <ThemeProvider theme={theme}>
        {" "}
        <Typography variant="subtitle2">
          Haven't found the answer you're looking for?
        </Typography>
      </ThemeProvider>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          margin: "auto",
          marginTop: 5,
          width: 800,
        }}
      >
        <TextField
          fullWidth
          value={text}
          onChange={textChange}
          type="text"
          variant="outlined"
          placeholder="please feel free to submit your ticket"
          sx={{}}
        />
        <Button
          color="secondary"
          onClick={sendText}
          style={{
            fontWeight: "bold",
            width: 200,
            margin: "auto",
            fontSize: 16,
            padding: "6px 12px",
            display: "flex",
            color: "White",
            height: 80,
            backgroundColor: "#4E2658",
            "&:hover": {
              backgroundColor: "#F5FBF2",
            },
          }}
        >
          submit
        </Button>
      </Paper>
    </div>
  );
};

export default SupportPage;
