// import { Web3Storage } from 'web3.storage'
import { Web3Storage } from "web3.storage/dist/bundle.esm.min.js";

import {
  getDownloadURL,
  ref,
  uploadBytes, // uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../config/firebase";

// Store function
export async function storeImageIPFS(imageFile, caption) {
  const web3storage = new Web3Storage({
    token: process.env.REACT_APP_IPFS_TOKEN,
  });
  const cid = await web3storage.put([imageFile], { name: caption });
  const imageGatewayURL = `https://${cid}.ipfs.dweb.link/${encodeURIComponent(
    imageFile.name
  )}`;
  return imageGatewayURL;
}

export async function storeFirebaseImage(imageFile) {
  const imageRef = ref(storage, `files/${imageFile[0].file.name}`);
  await uploadBytes(imageRef, imageFile[0].file);
  const downImg = await getDownloadURL(imageRef);
  return downImg;
}
