import { Button, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Cardgridtopcretor from "./Cardgridtopcretor";
import { getAllCreator } from "../../../services/TopCreator";
import SkeletonTopcreator from "./SkeletonTopcreator ";
import { SectionTitle } from "../../sectionTitle/sectionTitle";
// eslint-disable-next-line no-unused-vars
const data = [
  {
    image:
      "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEimKJOmH-GNZZMqb_U_AGt1Jcugz22S3nhhotXk5UeX4knecLKWETU-JxN5b_sBRdW6E5fY8gVgTjjb8Zg9YDUGtmoGtlPK4F36k8Doea6GS0kTJqjOtimAEJqPyyOscx7f1KAiL-X-11pMVu-J3atU5QDNqQX6w2kEl-4_I3pXAIq2pDxsImjIkubn_A/s55/averta-image.png",
    name: "Wzard Dee",
    description: "4.89 ETH",
    id: 1,
  },
  {
    image:
      "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEimKJOmH-GNZZMqb_U_AGt1Jcugz22S3nhhotXk5UeX4knecLKWETU-JxN5b_sBRdW6E5fY8gVgTjjb8Zg9YDUGtmoGtlPK4F36k8Doea6GS0kTJqjOtimAEJqPyyOscx7f1KAiL-X-11pMVu-J3atU5QDNqQX6w2kEl-4_I3pXAIq2pDxsImjIkubn_A/s55/averta-image.png",
    name: "Wzard Dee",
    description: "4.89 ETH",
    id: 2,
  },
  {
    image:
      "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEimKJOmH-GNZZMqb_U_AGt1Jcugz22S3nhhotXk5UeX4knecLKWETU-JxN5b_sBRdW6E5fY8gVgTjjb8Zg9YDUGtmoGtlPK4F36k8Doea6GS0kTJqjOtimAEJqPyyOscx7f1KAiL-X-11pMVu-J3atU5QDNqQX6w2kEl-4_I3pXAIq2pDxsImjIkubn_A/s55/averta-image.png",
    name: "Wzard Dee",
    description: "4.89 ETH",
    id: 3,
  },
];
const theme = createTheme({
  // Tell MUI what the font-size on the html element is.
  typography: {
    subtitle1: {
      fontSize: 33,
      fontFamily: "Montserrat",
      fontWeight: 300,
      color: "#F5FBF2",
      letterSpacing: "0.0075em",
      verticalAlign: "middle",
      alignItems: "center",
      textAlign: "center",
      main: "#F5FBF2",
      marginTop: 20,
    },
  },
  palette: {
    neutral: {
      main: "#FFCE4E",
      contrastText: "#000000",
    },
  },
});
const Topcreator = () => {
  const [topcreator, setTopcreator] = useState([]);
  const [isloading, setLoading] = useState(true);
  async function SlicegetAllCreator() {
    const res = await getAllCreator();
    const sliceres = res.slice(0, 3);
    setTopcreator(sliceres);
    setLoading(false);
  }
  useEffect(() => {
    SlicegetAllCreator();
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <div style={{textAlign:"center"}}>
        <SectionTitle isCenter={true}  subtitle="Top Creator" />
      </div>
      
        {/* <Typography variant="subtitle1" sx={{mb:5}}>Top Creator</Typography> */}
      <Box p={5}>
        <Grid
          container
          spacing={20}
          alignItems="center"
          justifyContent="center"
        >
          {!isloading && !topcreator.length ? (
             <ThemeProvider>
                <Typography variant="subtitle1">
                  No Popular Trading Bot
                </Typography>
              </ThemeProvider> 
          ) : isloading ? (
            <Box direction={{ xs: "column", sm: "row" }} pt={10}>
              <Stack spacing={10} direction={{ xs: "column", sm: "row" }}>
                <SkeletonTopcreator />
                <SkeletonTopcreator />
                <SkeletonTopcreator />
              </Stack>
            </Box>  
          ) : (
            topcreator.map((d,i) => {
              return (
                <Grid key={i} item>
                  <Cardgridtopcretor {...d} />
                </Grid>
              );
            })
          )}
        </Grid>
        <Grid justifyContent="center" pt={5}>
          <Button
            style={{
              margin: "auto auto",
              display: "flex",
              width: "120px",
            }}
            href="alltopcreator"
            color="neutral"pr
            variant="outlined"
          >
            View All
          </Button>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};
export default Topcreator;