/* eslint-disable no-unused-vars */
import {
  Button,
  Container,
  createTheme,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import ImageUploading from "react-images-uploading";
import imagePlaceholder from "../../assets/image/image_placeholder.png";
// import {
//   getDownloadURL,
//   ref,
//   uploadBytes,
//   // uploadBytesResumable,
// } from "firebase/storage";
// import { storage } from "../../config/firebase";
import { getOwnUserDetails } from "../../services/api";
import { updateUser } from "../../services/api";
import { useParams } from "react-router-dom";
import { storeFirebaseImage } from "../../services/ipfs";

// import { async } from "@firebase/util";

const UpdateTradingBot = ({ account }) => {
  // get input img
  const [image, setImage] = useState(null);
  const onImagechange = (img, addUpdateIndex) => {
    setImage(img);
    setFormdata({
      ...formdata,
      image: img[0].data_url,
    });
  };

  const params = useParams();
  const userId = params.id;
  const gettoken = window.localStorage.getItem("token");
  const navigate = useNavigate();

  const theme = createTheme({
    typography: {
      title: {
        fontFamily: "Oxanium, cursive",
        fontSize: 40,
        fontWeight: 600,
        color: "White",
      },
      subtitle: {
        fontFamily: "Oxanium, sans-serif",
        fontSize: 24,
        fontWeight: 600,
        color: "White",
      },
      caption: {
        fontFamily: "Work sans, cursive",
        fontSize: 16,
        fontWeight: 200,
        color: "White",
      },
    },
  });
  // textFiel Style
  const customTextFieldStyling = {
    "& label.Mui-focused": {
      color: "white",
    },
    "&:placeholder": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        borderWidth: "2px",
        color: "#FFF",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
      color: "white",
    },
  };
  const [data, setData] = useState([]);
  const [valuetoken, setToken] = useState();
  // async function GetOwnUserDetials() {
  //   const gettoken = window.localStorage.getItem("token");
  //   setToken(gettoken);
  //   const res = await getOwnUserDetails(userId , gettoken);
  //   setData(res)
  // }
  const [formdata, setFormdata] = useState({
    name: "",
    password: "",
    apiKey: "",
    secretKey: "",
    image: "",
    role: "",
    bio: "",
  });

  useEffect(() => {
    async function getuserbyid() {
      try {
        const res = await getOwnUserDetails(userId, gettoken);
        setData(res);
        formdata.name = res.name;
        formdata.password = res.password;
        formdata.apiKey = res.apiKey;
        formdata.secretKey = res.secretKey;
        formdata.image = res.image;
        formdata.role = res.role;
        formdata.bio = res.bio;
      } catch (error) {}
    }

    getuserbyid();

    // passvaltoformdata()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function UpdateUser() {
    let url;

    if (image !== null) {
      url = await storeFirebaseImage(image);
      formdata.image = url;
    }

    await updateUser(
      userId,
      gettoken,
      formdata.password,
      formdata.name,
      formdata.bio,
      formdata.image,
      formdata.role,
      formdata.apiKey,
      formdata.secretKey
    );
    navigate("/viewownprofile");
  }

  const onChange = (e) =>
    setFormdata({ ...formdata, [e.target.name]: e.target.value });

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Container maxWidth="lg" className="p-4 pb-20">
          <Stack direction="column" spacing={4}>
            <div>
              <Typography
                display="block"
                variant="title"
                className="text-white"
              >
                Edit User's Profile
              </Typography>
              <Typography display="block" variant="caption">
                <span style={{ marginRight: 2, color: "#e91e63" }}>*</span>
                Required fields
              </Typography>
            </div>
            <div className="space-y-4">
              <SndTitle text="Image" />
              <CustomImageInput
                image={formdata.image}
                onImageChange={onImagechange}
              />
            </div>
            <div className="space-y-4">
              <SndTitle text="Name" />
              <TextField
                fullWidth
                // inputRef={valueName}
                onChange={onChange}
                name="name"
                value={formdata.name}
                type="text"
                variant="outlined"
                placeholder="Username"
                className="w-full"
                required
                sx={customTextFieldStyling}
              />
            </div>
            <div className="space-y-4">
              <SndTitle text="Password" />
              <TextField
                fullWidth
                // inputRef={valueName}
                onChange={onChange}
                name="password"
                value={formdata.password}
                type="password"
                variant="outlined"
                placeholder="password"
                className="w-full"
                required
                sx={customTextFieldStyling}
              />
            </div>
            <div className="space-y-4">
              <SndTitle text="Role" />
              <TextField
                fullWidth
                // inputRef={valueRole}
                type="url"
                name="role"
                onChange={onChange}
                value={formdata.role}
                variant="outlined"
                placeholder="User Role"
                className="w-full"
                sx={customTextFieldStyling}
              />
            </div>
            <div className="space-y-4">
              <SndTitle text="Api Key" />
              <TextField
                fullWidth
                // inputRef={valueApiKey}
                name="apiKey"
                value={formdata.apiKey}
                onChange={onChange}
                type="url"
                variant="outlined"
                placeholder="User’s bianance api Key"
                className="w-full"
                sx={customTextFieldStyling}
              />
            </div>
            <div className="space-y-4">
              <SndTitle text="Secret Key" />
              <TextField
                fullWidth
                // inputRef={valueSecretKey}
                onChange={onChange}
                name="secretKey"
                value={formdata.secretKey}
                type="url"
                variant="outlined"
                placeholder="User’s binance secret Key"
                className="w-full"
                sx={customTextFieldStyling}
              />
            </div>

            <div className="space-y-4">
              <SndTitle text="Bio" />
              <TextField
                onChange={onChange}
                name="bio"
                fullWidth
                type="text"
                // inputRef={valueBio}
                value={formdata.bio}
                multiline
                rows={7}
                variant="outlined"
                placeholder="Provide a detailed description of user bio."
                className="w-full"
                sx={customTextFieldStyling}
              />
            </div>
          </Stack>
          <div>
            <Button
              // onSubmit={onSubmit}
              variant="outlined"
              color="secondary"
              // onClick={sendValue}
              // onClick={onSubmit}
              onClick={UpdateUser}
              style={{
                fontWeight: "bold",
                margin: "auto ",
                marginTop: 30,
                marginBottom: 30,
                fontSize: 16,
                padding: "6px 12px",
                display: "flex",
                color: "#212121",
                backgroundColor: "#ffc400",
                "&:hover": {
                  backgroundColor: "#F5FBF2",
                },
              }}
            >
              Update
            </Button>
          </div>
        </Container>
      </ThemeProvider>
    </div>
  );
};
function SndTitle({ text, isRequired = false }) {
  return (
    <Typography display="block" variant="subtitle">
      {text}
      {isRequired && <span style={{ color: "#e91e63", marginLeft: 3 }}>*</span>}
    </Typography>
  );
}
function CustomImageInput({ image, onImageChange }) {
  return (
    <div>
      <ImageUploading
        value={[image]}
        onChange={onImageChange}
        maxNumber={1}
        dataURLKey="data_url"
      >
        {({ imageList, onImageUpdate }) => (
          <div>
            {imageList.length === 0 ? (
              <Box
                sx={{ border: "2px solid white", width: 300, height: 200 }}
                justifyContent="center"
                alignItems="center"
                onClick={onImageUpdate}
              >
                <img
                  src={imagePlaceholder}
                  alt="images_placeholder"
                  style={{
                    width: 50,
                    height: 40,
                    paddingTop: 80,
                    paddingLeft: 110,
                  }}
                />
              </Box>
            ) : (
              <div onClick={onImageUpdate}>
                <img
                  alt="images_placeholder"
                  src={image}
                  style={{
                    width: 300,
                    height: 200,
                  }}
                />
              </div>
            )}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
export default UpdateTradingBot;
