/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  createTheme,
  // IconButton,
  InputBase,
  Paper,
  ThemeProvider,
  Typography,
  Box,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { styled, alpha } from "@mui/material/styles";
// import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
// import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Cardallbotforsale from "./Cardallbotforsale";
import { CircularProgress } from "@mui/material";
import { allSaleBot } from "../../services/smart contract/Smartcontract";

const theme = createTheme({
  // Tell MUI what the font-size on the html element is.
  typography: {
    subtitle1: {
      fontSize: 33,
      fontFamily: "Montserrat",
      fontWeight: 300,
      color: "#F5FBF2",
      letterSpacing: "0.0075em",
      verticalAlign: "middle",
      alignItems: "center",
      textAlign: "center",
      // main: "#F5FBF2",
      marginTop: 20,
    },
  },
});

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  // color: 'inherit',
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "60ch",
      "&:focus": {
        width: "80ch",
      },
    },
  },
}));

const BotForSale = (DisplayBalance, account) => {
  const [searchTerm, setSearchTerm] = useState("");

  const [allsalebot, setAllsalebot] = useState([]);
  const [loading, setLoading] = useState(true);

  const GetAllSaleBot = async () => {
    try {
      const res = await allSaleBot();
      console.log(("res : ", res));
      setAllsalebot(res);
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // handle search function
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    // setFilterList((item) =>
    //   item.toLowerCase().includes(searchTerm.toLowerCase())
    // );
  };
  useEffect(() => {
    // Update the document title using the browser API
    GetAllSaleBot();
  }, []);
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography variant="subtitle1">Bot For Sale</Typography>
        <Toolbar sx={{ justifyContent: "center" }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon style={{ color: "white" }} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search Bot here…"
              value={searchTerm}
              onChange={handleSearch}
              inputProps={{
                "aria-label": "search",
                style: { fontFamily: "Arial", color: "white" },
              }}
            />
          </Search>
        </Toolbar>
        {loading ? ( // Display loading state when loading is true
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop="300px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Paper
            sx={{
              p: 2,
              mt: 10,
              background: "#302034",
            }}
          >
            {allsalebot.length === 0 ? (
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                {GetAllSaleBot ? "No bot for sale" : "Error fetching data"}
              </Typography>
            ) : (
              allsalebot
                .filter((props) =>
                  props.displayName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                )
                .map((i, index) => (
                  <Grid
                    container
                    item
                    sx={{
                      background:
                        "linear-gradient(90deg, rgba(36,36,36,1) 100%, rgba(0,212,255,1) 100%, rgba(255,255,255,1) 100%)",
                      margin: "auto",
                      mt: 3,
                      color: "white",
                      maxWidth: 1300,
                    }}
                    key={index}
                  >
                    <Cardallbotforsale
                      props={i}
                      DisplayBalance={DisplayBalance}
                      account={account}
                    />
                  </Grid>
                ))
            )}
          </Paper>
        )}
      </ThemeProvider>
    </div>
  );
};

export default BotForSale;
