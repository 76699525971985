import { SectionTitleStyle,SectionTitleWrappertyle } from "./sectionTitle.style";

import sectionTitleShapeRight from "../../assets/icon/steps.png"
import sectionTiitleShapeLeft from "../../assets/icon/steps2.png"

export const SectionTitle = ({ title, subtitle, isCenter, ...props }) => {
    return (
      <SectionTitleStyle {...props} className="section_title">
        {subtitle && (
          <span className="subtitle">
            {isCenter ? (
              <img src={sectionTiitleShapeLeft} alt="section title shape" />
            ) : (
              ""
            )}
            {subtitle}{" "}
            <img
              src={sectionTitleShapeRight}
              alt="bithu nft section title shape"
            />{" "}
          </span>
        )}
        {title && <h1>{title}</h1>}
      </SectionTitleStyle>
    );
  };
  
  export const SectionTitleWrapper = ({ children }) => {
    return (
      <SectionTitleWrappertyle className="section_title_wrapper">
        {children}
      </SectionTitleWrappertyle>
    );
  };