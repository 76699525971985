import {
  Button,
  CircularProgress,
  Container,
  createTheme,
  MenuItem,
  Select,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect } from "react";
import ImageUploading from "react-images-uploading";
import imagePlaceholder from "../../assets/image/image_placeholder.png";
import { useParams } from "react-router-dom";
// import { getBotById } from "../../services/smart contract/Smartcontract";
// eslint-disable-next-line no-unused-vars
import {
  updateBot,
  getBotById,
} from "../../services/smart contract/Smartcontract";
import { storeImageIPFS } from "../../services/ipfs";
import { useNavigate } from "react-router-dom";
import {
  connectBinanceCredential,
  disConnectBinanceCredential,
  getOwnUserDetails,
} from "../../services/api";
import { v4 as uuidv4 } from "uuid";
import { createContainer, removeContainer } from "../../services/dockerApi";

const UpdateTradingBot = ({ account }) => {
  const [images, setImages] = useState(null);
  const onImagechange = (img, addUpdateIndex) => {
    setImages(img);
    setFormData({
      ...formData,
      image: img[0].data_url,
    });
  };

  const [data, setData] = useState([]);
  const [isowner, SerISOwner] = useState(false);
  const [selectedAPI, setSelectedAPI] = useState([]);
  const [apikey, setAPikey] = useState([]);
  // loading state
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const userID = params.id;
  const navigate = useNavigate();
  //
  const [formData, setFormData] = useState({
    displayname: "",

    image: "",
    description: "",
    binanceCredentialID: "",
    newDockerContainerID: "",
  });
  // get token
  const token = localStorage.getItem("token");
  const useraddress = localStorage.getItem("useraddress");

  const onSubmit = async (e) => {
    setLoading(true);
    try {
      const [binanceCredentialID, API_KEY, API_SECRET] = selectedAPI.split(",");
      const API_Value = [`API_KEY=${API_KEY}`, `API_SECRET=${API_SECRET}`];
      const imageName = data.dockerImageID;
      const containerName = uuidv4();

      await createContainer(containerName, imageName, API_Value);
      await removeContainer(data.dockerContainerID);
      await disConnectBinanceCredential(
        account,
        data.binanceCredentialID,
        token
      );
      await connectBinanceCredential(account, binanceCredentialID, token);
      formData.id = data.id - 1;
      formData.displayname = displayname;

      // await uploadImgIPFS();
      let url;
      if (images !== null) {
        url = await storeImageIPFS(images[0].file, images[0].file.name);
        formData.image = url;
      }
      formData.description = description;

      formData.binanceCredentialID = binanceCredentialID;
      formData.newDockerContainerID = containerName;

      await updateBot(
        formData.id,
        formData.displayname,
        formData.image,
        formData.description,
        formData.newDockerContainerID,
        formData.binanceCredentialID,
        account
      );
      navigate("/viewownprofile");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  // eslint-disable-next-line no-unused-vars
  const { displayname, description, binanceCredentialID, containerName } =
    formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  // contrll select
  const SelectedAPIChange = (e) => {
    setSelectedAPI(e.target.value);
  };

  useEffect(() => {
    async function getbotbyid() {
      try {
        formData.id = data.id;
        formData.displayname = data.displayName;
        formData.image = data.image;
        formData.description = data.description;
        formData.newDockerContainerID = data.dockerContainerID;
        formData.binanceCredentialID = data.binanceCredentialID;
      } catch (error) {
        console.error(`Could not get products: ${error}`);
      }
    }
    async function checkauthaddress() {
      try {
        try {
          const res = await getBotById(userID);
          setData(res);
        } catch (error) {
          console.error(`Could not get products: ${error}`);
        }

        if (account === data.OwnerAddress) {
          SerISOwner(false);
        } else if (account !== data.OwnerAddress) {
          SerISOwner(true);
        }
      } catch (error) {}
    }
    async function getApikey() {
      try {
        const apikey = await getOwnUserDetails(useraddress, token);

        setAPikey(apikey.credentials);
      } catch (error) {
        console.log(error);
      }
    }
    getApikey();
    getbotbyid();
    checkauthaddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isowner]);

  // theme styles
  const theme = createTheme({
    typography: {
      title: {
        fontFamily: "Oxanium, cursive",
        fontSize: 40,
        fontWeight: 600,
        color: "White",
      },
      subtitle: {
        fontFamily: "Oxanium, sans-serif",
        fontSize: 24,
        fontWeight: 600,
        color: "White",
      },
      caption: {
        fontFamily: "Work sans, cursive",
        fontSize: 16,
        fontWeight: 200,
        color: "White",
      },
    },
  });
  // textFiel Style
  const customTextFieldStyling = {
    "& label.Mui-focused": {
      color: "white",
    },
    "&:placeholder": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        borderWidth: "2px",
        color: "#FFF",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
      color: "white",
    },
  };
  return (
    <div>
      {loading === true ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <div>
          {isowner === true ? (
            <></>
          ) : (
            <ThemeProvider theme={theme}>
              <Container maxWidth="lg" className="p-4 pb-20">
                <Stack direction="column" spacing={4}>
                  <div>
                    <Typography
                      display="block"
                      variant="title"
                      className="text-white"
                    >
                      Edit Bot Name: {data.displayName}
                    </Typography>
                    <Typography display="block" variant="caption">
                      <span style={{ marginRight: 2, color: "#e91e63" }}>
                        *
                      </span>
                      Required fields
                    </Typography>
                  </div>
                  <div className="space-y-4">
                    <SndTitle text="Image" isRequired={true} />
                    <CustomImageInput
                      image={formData.image}
                      onImageChange={onImagechange}
                    />
                  </div>
                  <div className="space-y-4">
                    <SndTitle text="Name" isRequired={true} />
                    <TextField
                      fullWidth
                      onChange={onChange}
                      name="displayname"
                      value={formData.displayname}
                      type="text"
                      variant="outlined"
                      placeholder="Bot name"
                      className="w-full"
                      required
                      sx={customTextFieldStyling}
                    />
                  </div>
                  <div className="space-y-4">
                    <SndTitle text="APIKEY" />
                    <Select
                      sx={{
                        color: "white",
                        width: "100%!important",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(228, 219, 233, 0.25)",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "white",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "rgba(228, 219, 233, 0.25)",
                        },
                        ".MuiSvgIcon-root": {
                          fill: "white !important",
                        },
                      }}
                      onChange={SelectedAPIChange}
                      value={selectedAPI}
                    >
                      {apikey?.map((credential) =>
                        credential.status === false ? (
                          <MenuItem
                            key={credential.credentialID}
                            value={`${credential.credentialID},${credential.apiKey},${credential.secretKey}`}
                          >
                            {credential.credentialName}
                          </MenuItem>
                        ) : null
                      )}
                    </Select>
                  </div>
                  <div className="space-y-4">
                    <SndTitle text="Description" />
                    <TextField
                      fullWidth
                      type="text"
                      onChange={onChange}
                      value={formData.description}
                      name="description"
                      multiline
                      rows={7}
                      variant="outlined"
                      placeholder="Provide a detailed description of your bot."
                      className="w-full"
                      sx={customTextFieldStyling}
                    />
                  </div>
                </Stack>
                <div>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={onSubmit}
                    style={{
                      fontWeight: "bold",
                      margin: "auto ",
                      marginTop: 30,
                      marginBottom: 30,
                      fontSize: 16,
                      padding: "6px 12px",
                      display: "flex",
                      color: "#212121",
                      backgroundColor: "#ffc400",
                      "&:hover": {
                        backgroundColor: "#F5FBF2",
                      },
                    }}
                  >
                    Update
                  </Button>
                </div>
              </Container>
            </ThemeProvider>
          )}
        </div>
      )}
    </div>
  );
};
function SndTitle({ text, isRequired = false }) {
  return (
    <Typography display="block" variant="subtitle">
      {text}
      {isRequired && <span style={{ color: "#e91e63", marginLeft: 3 }}>*</span>}
    </Typography>
  );
}
function CustomImageInput({ image, onImageChange }) {
  return (
    <div>
      <ImageUploading
        value={[image]}
        onChange={onImageChange}
        maxNumber={1}
        dataURLKey="data_url"
      >
        {({ imageList, onImageUpdate }) => (
          <div>
            {imageList.length === 0 ? (
              <Box
                sx={{ border: "2px solid white", width: 300, height: 200 }}
                justifyContent="center"
                alignItems="center"
                onClick={onImageUpdate}
              >
                <img
                  src={imagePlaceholder}
                  alt="images_placeholder"
                  style={{
                    width: 50,
                    height: 40,
                    paddingTop: 80,
                    paddingLeft: 110,
                  }}
                />
              </Box>
            ) : (
              <div onClick={onImageUpdate}>
                <img
                  src={image}
                  alt="updated_image"
                  style={{
                    width: 300,
                    height: 200,
                  }}
                />
              </div>
            )}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
export default UpdateTradingBot;
