import {
  Box,
  Button,
  createTheme,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React from "react";
import Count from "../../../common/count"
import binance from "../../../assets/image/binance.png";
import bitmex from "../../../assets/image/bitmex.png";
import blockchain from "../../../assets/image/blockchain.png";
import coinbase from "../../../assets/image/coinbase.png";
import cover from "../../../assets/image/cover-landng-page.png";
import {
  calStartDateEndDateList,
  getAccountInformation,
  getPositionInformation,
  getTradeHistory,
  getTradingBotTradingHistoryData,
} from "../../../services/binanceAPI/binanceAPI";

const theme = createTheme({
  // Tell MUI what the font-size on the html element is.
  typography: {
    subtitle1: {
      fontSize: 33,
      fontFamily: "Montserrat",
      fontWeight: "bold",
      color: "#F5FBF2",
      letterSpacing: "0.0075em",
      verticalAlign: "middle",
      alignItems: "center",
      textAlign: "center",
      main: "#F5FBF2",
    },
    subtitle2: {
      color: "#9B9E9A",
      main: "#9B9E9A",
      fontSize: 22,
    },
    yellofond: {
      color: "#FFCE4E",
      fontWeight: "bold",
    },
  },
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
    neutral: {
      main: "#FFCE4E",
      contrastText: "#000000",
    },
    createbutton: {
      main: "#FFFFFF",
      contrastText: "#FFFFFF",
    },
  },
});

const counter_text = [
  {
    id: 0,
    counter : 3200,
    text: "Trading Bots",
  },
  {
    id: 1,
    counter: 1200,
    text: "Actions"
  },
  {
    id:2,
    counter:50,
    text: "Creator"
  }
]
function Marketplace() {
  return (
    <div>
      <div>
        <ThemeProvider theme={theme}>
          <Box
            m={1}
            pr={1}
            pl={1}
            pt={7}
            direction={{ xs: "column", sm: "row" }}
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="center"
            >
              <Stack
                direction={{
                  xs: "column",
                  sm: "row",
                }}
              >
                <Grid
                  pr={{
                    xs: 1,
                    sm: 20,
                  }}
                >
                  <Typography pt={10} variant="subtitle1">
                    Marketplace for
                    <Typography variant="yellofond" pl={1}>
                      Trading Bot
                    </Typography>
                  </Typography>

                  <Stack direction="row" pt={5} justifyContent="center">
                    <Grid pr={5}>
                      <Button
                        // onClick={GetAllSaleBot}
                        href="botforsale"
                        variant="contained"
                        color="neutral"
                        sx={{ fontSize: 15 }}
                      >
                        Explore
                      </Button>
                    </Grid>
                    {/* <Grid>
                      <Button
                        href="createtradingbot"
                        variant="outlined"
                        color="neutral"
                        sx={{ fontSize: 15 }}
                      >
                        Create
                      </Button>
                    </Grid> */}
                    {/* <Grid pl={5}>
                      <Button
                        href="viewownprofile"
                        variant="outlined"
                        color="neutral"
                        sx={{ fontSize: 15 }}
                      >
                        profile
                      </Button>
                    </Grid> */}
                    {/* <Grid pl={5}>
                      <Button
                        onClick={() => {
                          getAccountInformation(
                            "3e5f37a080a714695ff6e7258208b810341b570a2670d60eb54a721fd85cbabe",
                            "21332e7b9ea914266fa6d954c674b064591ba4f82005fa82d40aebb3422ef5c8"
                          );
                        }}
                        variant="outlined"
                        color="neutral"
                        sx={{ fontSize: 15 }}
                      >
                        GET ACCOUNT INFO
                      </Button>
                    </Grid>
                    <Grid pl={5}>
                      <Button
                        onClick={getTradingBotTradingHistoryData}
                        variant="outlined"
                        color="neutral"
                        sx={{ fontSize: 15 }}
                      >
                        TEST CALDATE
                      </Button>
                    </Grid>
                    <Grid pl={5}>
                      <Button
                        onClick={() => {
                          getPositionInformation(
                            "3e5f37a080a714695ff6e7258208b810341b570a2670d60eb54a721fd85cbabe",
                            "21332e7b9ea914266fa6d954c674b064591ba4f82005fa82d40aebb3422ef5c8"
                          );
                        }}
                        variant="outlined"
                        color="neutral"
                        sx={{ fontSize: 15 }}
                      >
                        GET POSITION INFO
                      </Button>
                    </Grid>
                    <Grid pl={5}>
                      <Button
                        onClick={async () => {
                          let res = await getTradeHistory(
                            "3e5f37a080a714695ff6e7258208b810341b570a2670d60eb54a721fd85cbabe",
                            "21332e7b9ea914266fa6d954c674b064591ba4f82005fa82d40aebb3422ef5c8",
                            // 1682138451000,
                            // 1682675989000,
                            1682121600000,
                            1682676834000,
                          );

                          console.log(res);
                        }}
                        variant="outlined"
                        color="neutral"
                        sx={{ fontSize: 15 }}
                      >
                        GET TRADE HISTORY INFO
                      </Button>
                    </Grid> */}
                  </Stack>
                  <Stack direction={{ xs: "column", sm: "row" }} pt={5} pb={5} justifyContent="center">
                  
                  {/* {counter_text.map((item) => (
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography pr={2} variant="subtitle2">
                          {item.text}
                        </Typography>
                        </Grid>
                      <Grid item xs={6}>
                      <Typography pr={2} variant="subtitle2">
                        <Count  number={item.counter} />
                      </Typography>
                      </Grid>

                      </Grid>
                      
                    ))} */}
                    {counter_text.map((item) => (
                      <Grid pr={{xs: 1, lg: 5}}  style={{textAlign:"center"}}>
                        <Typography  variant="subtitle2">
                          {item.text}
                        </Typography>
                        <Typography  variant="subtitle1">
                          <Count  number={item.counter} />
                        </Typography>
                      </Grid>


                    ))}
{/* 
                    <Grid container spacing={2}>
                     {counter_text.map((item) => (
                      <Grid item xs={1}>
                        <Typography pr={2} variant="subtitle2">
                          {item.text}
                        </Typography>

                      </Grid>

                     ))}
                        
                    </Grid> */}

      
                  </Stack>
                  {/* <Stack direction="row" pb={5} justifyContent="center">
                  {counter_text.map((item) => (
                    <Typography pr={7} variant="subtitle2">
                     {item.text}
                    </Typography>
                  ))}
                  
                  </Stack> */}
                </Grid>
                <Grid style={{textAlign:"center"}}>
                  <Box
                    component="img"
                    sx={{
                      height: 500,
                      width: 450,
                      maxHeight: { xs: 400, md: 500 },
                      maxWidth: { xs: 300, md: 450 },
                    }}
                    alt="The house from the offer."
                    src={cover}
                  />
                </Grid>
              </Stack>
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="center"
              pt={10}
              pb={10}
              style={{textAlign:"center"}}
            >
              {" "}
              <Stack direction={{ xs: "column", sm: "row" }}>
                <Grid pr={5} pb={5}>
                  <img src={coinbase} alt="coinbase" height={28} width={178} />
                </Grid>
                <Grid pr={7} pb={5}>
                  <img
                    src={blockchain}
                    alt="blockchain"
                    height={28}
                    width={178}
                  />
                </Grid>
                <Grid pr={7} pb={5}>
                  <img src={binance} alt="v" height={28} width={178} />
                </Grid>
                <Grid pb={7}>
                  <img src={bitmex} alt="bitmex" height={28} width={178} />
                </Grid>
              </Stack>
            </Stack>
          </Box>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default Marketplace;
