import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

// ** Third Party Imports
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";

// // ** Icon Imports
// import Icon from "src/@core/components/icon";

// // ** Custom Components Imports
// import CustomChip from "src/@core/components/mui/chip";

// const data = [
//   { pv: 280, name: "7/12" },
//   { pv: 200, name: "8/12" },
//   { pv: 220, name: "9/12" },
//   { pv: 180, name: "10/12" },
//   { pv: 270, name: "11/12" },
//   { pv: 250, name: "12/12" },
//   { pv: 70, name: "13/12" },
//   { pv: 90, name: "14/12" },
//   { pv: 200, name: "15/12" },
//   { pv: 150, name: "16/12" },
//   { pv: 160, name: "17/12" },
//   { pv: 100, name: "18/12" },
//   { pv: 150, name: "19/12" },
//   { pv: 100, name: "20/12" },
//   { pv: 50, name: "21/12" },
// ];

const CustomTooltip = (props) => {
  // ** Props
  const { active, payload } = props;
  if (active && payload) {
    return (
      <div className="recharts-custom-tooltip">
        <Typography
          sx={{ fontSize: "0.875rem" }}
        >{`${payload[0].value}%`}</Typography>
      </div>
    );
  }

  return null;
};

const RechartsLineChart = ({ direction, data }) => {
  // Determine the min and max values of the 'realizedPnl' data
  const realizedPnlValues = data.map((entry) => entry.realizedPnl);
  const minValue = Math.min(...realizedPnlValues);
  const maxValue = Math.max(...realizedPnlValues);

  return (
    <Card sx={{ background: "transparent" }}>
      <CardHeader
        title="History trading Bot"
        subheader=""
        //  subheaderTypographyProps={{ sx: { color: theme => ${theme.palette.text.disabled} !important } }}
        sx={{
          flexDirection: ["column", "row"],
          alignItems: ["flex-start", "center"],
          "& .MuiCardHeader-action": { mb: 0 },
          "& .MuiCardHeader-content": { mb: [2, 0] },
        }}
        subheaderTypographyProps={{
          sx: {
            color: "red", // Set the desired text color here
          },
        }}
        action={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6" sx={{ mr: 5 }}></Typography>
          </Box>
        }
      />
      <CardContent>
        <Box sx={{ height: 350 }}>
          <ResponsiveContainer>
            <LineChart
              height={700}
              data={data}
              style={{ direction, color: "white" }}
              margin={{ left: 5 }}
            >
              <CartesianGrid />
              <XAxis dataKey="Time" reversed={direction === "rtl"} />
              <YAxis
                orientation={direction === "rtl" ? "right" : "left"}
                domain={[minValue, maxValue]} // Set the domain to include the full range
              />
              <Tooltip content={CustomTooltip} />
              <Line dataKey="realizedPnl" stroke="#ff9f43" strokeWidth={3} />
              <ReferenceLine y={0} stroke="red" strokeDasharray="3 3" />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RechartsLineChart;
