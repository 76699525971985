/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography,
  createTheme,
  ThemeProvider,
  Button,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import { Box } from "@mui/system";

import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import InputBase from "@mui/material/InputBase";
import { useState } from "react";
// import { alpha } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { deleteBinanceCredential, disConnectBinanceCredential } from "../../services/api";
import { CircularProgress } from "@mui/material";
import { getOwnUserDetails } from "../../services/api";

const theme = createTheme({
  // Tell MUI what the font-size on the html element is.
  typography: {
    subtitle1: {
      fontSize: 33,
      fontFamily: "Montserrat",
      fontWeight: 300,
      color: "#F5FBF2",
      letterSpacing: "0.0075em",
      verticalAlign: "middle",
      alignItems: "center",
      textAlign: "center",
      main: "#F5FBF2",
      marginTop: 20,
    },
    subtitle2: {
      fontSize: 17,
      fontFamily: "Montserrat",
      fontWeight: 300,
      color: "#FFFFFF",
      letterSpacing: "0.0075em",
      verticalAlign: "middle",
      alignItems: "center",
      textAlign: "left",
      main: "#F5FBF2",
      marginTop: 20,
    },
    leftsearch: {
      alignItems: "right",
      textAlign: "right",
    },
    tableToptext: {
      fontWeight: 600,
    },
  },
  palette: {
    neutral: {
      main: "#FFCE4E",
      contrastText: "#000000",
    },
    AppColor: {
      main: "#302034",
      contrastText: "#000000",
    },
    createbutton: {
      main: "#FFFFFF",
      contrastText: "#FFFFFF",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 20,
    fontWeight: 600,
    backgroundColor: "#251928",
    color: "#f0f0f0",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "#f0f0f0",
    backgroundColor: "#251928",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   marginRight: theme.spacing(2),
//   marginLeft: 0,
//   width: "100%",
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(3),
//     width: "auto",
//   },
// }));

// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));

// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "inherit",
//   "& .MuiInputBase-input": {
//     padding: theme.spacing(1, 1, 1, 0),
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {
//       width: "20ch",
//     },
//   },
// }));

const ViewOwnAPIKey = () => {
  // const ViewOwnProfile = () => {
  // const [databot, setDataBot] = useState([]);
  // const [value, setValue] = React.useState(0);
  //Search filter
  // const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const tokens = window.localStorage.getItem("token");
  const params = useParams();
  const AccountID = params.id;
  const account = window.localStorage.getItem("useraddress");

  async function DisConnect (id) {
    try{
      await disConnectBinanceCredential(account, id, tokens);
      window.location.reload();
    } catch(err){
      console.log(err);
    }
  }
  async function DeleteAPI(id) {
    try {
      await deleteBinanceCredential(account, id, tokens);
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  }
  useEffect(() => {
    async function GetOwnUserDetials() {
      const res = await getOwnUserDetails(AccountID, tokens);
      setData(res);
    }
    if (AccountID !== null && tokens !== null) {
      GetOwnUserDetials();
    }
  }, [AccountID]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography variant="subtitle1">View API</Typography>

        {/* <Box width={500} variant="subtitle2">
    <Search
      sx={{
        mt: 2,
      }}
    >
      <SearchIconWrapper>
        <SearchIcon color="createbutton" />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search me here ... "
        inputProps={{
          "aria-label": "search",
          style: { fontFamily: "Arial", color: "white" },
        }}
        fontcolor="green"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </Search>
  </Box> */}
        <Grid m={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            // display="flex"
            // justifyContent="flex-end"
            href="createAPIKey"
            variant="outlined"
            color="neutral"
            sx={{ fontSize: 13 }}
          >
            Create
          </Button>
        </Grid>

        {data.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 250px)", // Adjust the height based on your layout
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box direction="column" m={3}>
            <Paper>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">NO.</StyledTableCell>
                      <StyledTableCell align="center">API Name</StyledTableCell>
                      <StyledTableCell align="center">API Key</StyledTableCell>
                      <StyledTableCell align="center">
                        Secret Key
                      </StyledTableCell>
                      <StyledTableCell align="center">Status</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.credentials.length === 0 ? (
                      <TableRow>
                        <StyledTableCell colSpan={6} align="center">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              style={{
                                fontSize: "1.2rem",
                                fontWeight: "bold",
                                color: "#a4a4a4",
                                marginBottom: "1rem",
                              }}
                            >
                              No API
                            </Typography>
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      data.credentials.map((row, index) => (
                        <StyledTableRow key={row.id}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.credentialName}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.apiKey}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.secretKey}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.status === true ? "Active" : "Inactive"}
                            {/* <Button
                              variant="contained"
                              color="neutral"
                              onClick={() => DisConnect(row.credentialID)}
                            >
                              disconect
                            </Button> */}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Button
                              variant="contained"
                              color="neutral"
                              onClick={() => DeleteAPI(row.credentialID)}
                            >
                              Delete
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        )}
      </ThemeProvider>
    </div>
  );
};

export default ViewOwnAPIKey;
