import {SectionTitle} from "../../sectionTitle/sectionTitle"
import PartnerStyleWrapper from "./partner.style"
import partnerKit from "../../../assets/image/partner/partner-kit.png"
import partnerrupp from "../../../assets/image/partner/partner-rupp.png"
import partnerfe from "../../../assets/image/partner/partner-fe.png"
import partnerbianince from "../../../assets/image/partner/partner-binance.png"
import partneropensea from "../../../assets/image/partner/partner-opensea.png"
import partnermetamask from "../../../assets/image/partner/partner-metamask.png"
import partnerdocker from "../../../assets/image/partner/partner-docker.png"
import partnerpolygon from "../../../assets/image/partner/partner-polygon.png"
import partnermoralis from "../../../assets/image/partner/partner-moralis.png"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'

const data = [
    {
        image: partnerKit,
        link: "https://kit.edu.kh/"
    },
    {
        image: partnerrupp,
        link: "http://www.rupp.edu.kh/"
    },
    {
        image: partnerfe,
        link: "http://www.fe.rupp.edu.kh/#/"
    },
    {
        image: partnerbianince,
        link: "https://www.binance.com/"
    },
    {
        image: partneropensea,
        link: "https://www.opensea.io/"
    },
    {
        image: partnermetamask,
        link: "https://www.metamask.io/"
    },
    {
        image: partnerdocker,
        link: "https://www.docker.com/"
    },
    {
        image: partnerpolygon,
        link: "https://www.polygon.technology/"
    },
    {
        image: partnermoralis,
        link: "https://moralis.io/"
    }
  ];

  
const Partner = () => {
    const settings = {
        dots: false,
        arrows: false,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear",
        centerMode: true,
        centerPadding: "0px",
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        pauseOnHover: false,
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
            },
            },
            {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
            },
            },
            {
            breakpoint: 575,
            settings: {
                slidesToShow: 2,
            },
            },
            {
            breakpoint: 375,
            settings: {
                slidesToShow: 1,
                centerMode: false
            },
            },
        ],
      };
    return (
    <PartnerStyleWrapper>
      <div className>
        <SectionTitle isCenter={true} subtitle="OUR PARTNERS" />

        <Slider {...settings}>
            {data?.map((item, index) => {
                return (
                    <div key={index} style={{width: '5000px'}}>
                        <a href={item.link} target="_blank">
                            <img src={item.image} alt="partner thumb" />
                        </a>
                    </div>
                );
            })
            }

        </Slider>

      </div>
    </PartnerStyleWrapper>
    )
}
export default Partner;