import Eachteamdetails from './eachteamdetails'
import Box from '@mui/material/Box'
import data from "../../assets/data/team/data"
import { useParams } from "react-router-dom";
import NotFound from '../NotFound';
const Teamdetails = () => {
    const params = useParams();
    const userID = params.id;
    let foundMatch = false;

    return(
        <Box className="content-center">
            <div>
            {data.map((information, i) =>
          information.name === userID ? (
            <div key={i}>
              <Eachteamdetails {...information} />
              {foundMatch = true}
            </div>
          ) : null
        )}
        {!foundMatch && <NotFound />}
      </div> 
       </Box>
        
    )
}

export default Teamdetails;