import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect } from "react";
import { useState } from "react";
import Cards from "./cards";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import Skeletoncard from "./skeleton_card";
import { getAllBots } from "../../../services/smart contract/Smartcontract";
import { SectionTitle } from "../../sectionTitle/sectionTitle";


const theme = createTheme({
  // Tell MUI what the font-size on the html element is.
  typography: {
    subtitle1: {
      fontSize: 33,
      fontFamily: "Montserrat",
      fontWeight: 300,
      color: "#F5FBF2",
      letterSpacing: "0.0075em",
      verticalAlign: "middle",
      alignItems: "center",
      textAlign: "center",
      main: "#F5FBF2",
    },
  },
});



const metamask = window.localStorage.getItem("metamask");
const useraddress = window.localStorage.getItem("useraddress");
const network = window.localStorage.getItem("Network");
const ChainId = window.localStorage.getItem("ChainId");
const token = window.localStorage.getItem("token");
function Popular({ DisplayBalance, account }) {
  const [tradingbot, setTradingBot] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [isLoop, setIsLoop] = useState(false);
  async function GotALLBot() {
    const data = await getAllBots();
    setTradingBot(data);
    setisloading(false);
  }
  useEffect(() => {
    
    setIsLoop(true);
    GotALLBot();
    
  }, []);

  return (
    <nav className="pop" direction={{ xs: "column", sm: "row", md: "row" }}>
      <ThemeProvider theme={theme}>
        <div style={{textAlign: "center"}}>
          <SectionTitle isCenter={true} subtitle="POPULAR TRADING BOT" />
        </div>

      </ThemeProvider>
      <div>
        {!isloading && !tradingbot.length ? (
          <Box>
            <ThemeProvider theme={theme}>
              <Typography variant="subtitle1">
                Oh No.. No Popular Trading Bot
              </Typography>
            </ThemeProvider>
          </Box>
        ) : isloading && window.localStorage.getItem("useraddress") ? (
          <Grid>
            <Grid container alignItems="center" justifyContent="center" sx={{mb:3}}>
              <Skeletoncard />
              <Skeletoncard />
              <Skeletoncard />
            </Grid>
          </Grid>
        ) : window.localStorage.getItem("metamask") === "true" && window.localStorage.getItem("token") === null ? (
     <Box>
            <ThemeProvider theme={theme}>
              <Typography variant="subtitle1">
                you're not connect with metamask yet. we are support {network}              </Typography>
            </ThemeProvider>
          </Box>
) : window.localStorage.getItem("metamask") === "false" ? (
          <Box>
            <ThemeProvider theme={theme}>
              <Typography variant="subtitle1">
                Oh No.. You don't have metamask please install. we are support {network}
              </Typography>
            </ThemeProvider>
          </Box>

        ) : window.localStorage.getItem("ChainId") === '11155111' && window.localStorage.getItem("token") ? (
          <Grid container justifyContent="center">
            {tradingbot.slice(1, 4).map((i, index) => (
              <Grid
                style={{
                  width: "395px",
                }}
                m={"auto"}
                pt={10}
                pb={10}
                key={index}
              >
              
              <Cards
                note={i}
                DisplayBalance={DisplayBalance}
                account={account}
              />
              </Grid>
            ))}
          </Grid>
        ) : null}
      </div>
      { window.localStorage.getItem("useraddress") &&  window.localStorage.getItem("token")  ? (
        <div>
        <Button
          href="allpopulartradingbot"
          variant="outlined"
          color="neutral"
          style={{
            margin: "auto auto",
            display: "flex",
            width: "120px",
            "&:hover": {
              backgroundColor: "#F5FBF2",
            },
          }}
        >
          View All
        </Button>
      </div>
      ) : null
      }
      
    </nav>
  );
}

export default Popular;
