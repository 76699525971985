import React from 'react'

// ** MUI Components
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

// ** Styled Components
const BoxWrapper = styled(Box)(({theme}) => ({
    [theme.breakpoints.down('md')]: {
        width: '90vw'
    }
}))

  
const Img = styled('img')(({ theme }) => ({
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    [theme.breakpoints.down('lg')]: {
      height: 450,
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10)
    },
    [theme.breakpoints.down('md')]: {
      height: 400
    },
  }))
const Error500 = () => {
    return(
        <Box className="content-center"> 
        <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignIteme: 'center', textAlign:'center'}}>
            <BoxWrapper>
                <Typography variant='h1' sx={{ mb: 2.5 }} color="#FFFFFF">
                    500
                </Typography>
                <Typography variant='subtitle1' color="#FFFFFF" sx={{ mb: 2.5, letterSpacing: '0.18px', fontSize: '1.5rem !important' }}>
                    Internal server error 👨🏻‍💻
                </Typography>
                <Typography variant='body2' color="#FFFFFF">Oops, something went wrong!</Typography>
                <Img alt='error-illustraction' src='/pages/500.png'sx={{ display: 'block', mx: 'auto', my: 2 }}/>
                <Button href='/' variant="outlined"
                          color="neutral" sx={{ px: 5, mx: 'auto', mt:2}}>
                    Back to Home
                </Button>
            </BoxWrapper>
        </Box>
       </Box>

    )
}

export default Error500;