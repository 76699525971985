import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Typography,
  CircularProgress,
} from "@mui/material";
import { getAllCreator } from "../../services/TopCreator";
// eslint-disable-next-line no-unused-vars
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const theme = createTheme({
  // Tell MUI what the font-size on the html element is.
  typography: {
    subtitle1: {
      fontSize: 50,
      fontFamily: "Oxanium, cursive",
      fontWeight: 300,
      color: "#F5FBF2",
      letterSpacing: "0.0075em",
      verticalAlign: "middle",
      alignItems: "center",
      textAlign: "center",
      main: "#F5FBF2",
      marginTop: 100,
    },
    subtitle2: {
      fontSize: 20,
      fontFamily: "Oxanium, cursive",
      fontWeight: 100,
      color: "#F5FBF2",
      main: "#F5FBF2",
    },
    subtitle3: {
      fontSize: 10,
      fontFamily: "Oxanium, cursive",
      fontWeight: 50,
      color: "#F5FBF2",
      main: "#F5FBF2",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
  },
});
function Cardalltopcreator() {
  const navigate = useNavigate();
  const [allCreator, setAllCreator] = useState();

  useEffect(() => {
    const getAllCreatorData = async () => {
      const data = await getAllCreator();
      if (data) {
        setAllCreator(data);
      }
    };
    getAllCreatorData();
  }, []);

  return (
    <div>
      {!allCreator && <CircularProgress />}
      {allCreator && (
        <Grid
          container
          sx={{
            margin: "auto",
          }}
          spacing={2}
          columnSpacing={7}
          p={4}
          key={allCreator.length}
        >
          {allCreator.map((data, index) => (
            <Grid item xs={{ sm: 3, md: 4 }}>
              <Card sx={{ width: 400, background: "#4E2658", color: "white" }}>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <CardActionArea>
                    <Avatar
                      onClick={() => {
                        navigate(`/viewotherprofile/${data._id}`);
                      }}
                      sx={{ width: 100, height: 100, ml: 5, mt: 5, mb: 5 }}
                      src={data.image}
                    />
                  </CardActionArea>
                  <CardActionArea>
                    <ThemeProvider theme={theme}>
                      {" "}
                      <Typography gutterBottom variant="subtitle2">
                        {data.name}
                        hi
                      </Typography>
                      <Typography
                        variant="subtitle3"
                        color="textSecodaty"
                        component="p"
                      >
                        {0}
                      </Typography>
                    </ThemeProvider>
                  </CardActionArea>
                  <CardContent sx={{ mt: 10 }}>
                    <ThemeProvider theme={theme}>
                      <Typography variant="subtitle2">{data.role}</Typography>
                      <Divider
                        sx={{ width: 30, mr: 2, background: "#ffffff" }}
                        orientation="horizontal"
                      />
                      <Typography variant="subtitle3">Items</Typography>
                    </ThemeProvider>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
}

export default Cardalltopcreator;
