import React from "react";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
const SkeletonTopcreator = () => {
  return (
    <Card
      style={{
        color: "#FFFFFF",
        backgroundColor: "#3D2942",
        textAlign: "left",
      }}
      sx={{
        maxWidth: 395,
        fontWeight: "bold",
        fontSize: 16,
        borderRadius: "16px",
        ml: 8,
        mt: 3,
      }}
    >
      <Box m={1}>
        <Stack direction="row" justifyContent="space-between" pt={1} pb={1}>
          <Skeleton variant="circular" width={64} height={64} />
        </Stack>
        <Stack direction="row" justifyContent="space-between" pt={1} pb={1}>
          <Skeleton width={70} height={30} />
          <Skeleton width={70} height={30} />
        </Stack>
        <Skeleton width={200} height={30} />
        <Stack justifyContent="center" alignItems="center" pt={1} pb={1}>
          <Skeleton width={350} height={50} />
        </Stack>
      </Box>
    </Card>
  );
};

export default SkeletonTopcreator;
