const CryptoJS = require("crypto-js");
// import Promise from 'promise';

const baseUrl_testnetFuture = "https://testnet.binancefuture.com";

function calculateTimestamp() {
  // console.log("Date.now()", Date.now());
  return Date.now();

}

function calculateSignature(queryString, secretKey) {
  console.log("secretKey", secretKey, "queryString", queryString)
  const hash = CryptoJS.HmacSHA256(queryString, secretKey);
  return hash.toString(CryptoJS.enc.Hex);
}
// function calLastMonthTimestamp() {
//   const date = new Date();
//   const result = date.setMonth(date.getMonth() - 1);
//   return result;
// }
export async function getAccountInformation(apiKey, secretKey) {
  const timestamp = calculateTimestamp();
  const queryString = `timestamp=${timestamp}`;
  const signature = calculateSignature(queryString, secretKey);
  const url = `${baseUrl_testnetFuture}/fapi/v1/account?${queryString}&signature=${signature}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "X-MBX-APIKEY": apiKey,
      recvWindow: 60000, // adjust the value of recvWindow as needed
    },
  });
  console.log(`response: ${response}`);
  const data = await response.json();
  console.log(data);
  return data;
}
export async function getTradeHistory(
  apiKey,
  secretKey,
  startTime,
  endTime
  // startTime = Date.now() - 7 * 24 * 60 * 60 * 1000,
  // endTime = Date.now()
) {
  // console.log("1");
  const timestamp = calculateTimestamp();
  // console.log("Start time", startTime);
  // console.log("End time", endTime);
  const queryString = `startTime=${startTime}&endTime=${endTime}&timestamp=${timestamp}`;
  const signature = calculateSignature(queryString, secretKey);
  const url = `${baseUrl_testnetFuture}/fapi/v1/userTrades?${queryString}&signature=${signature}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "X-MBX-APIKEY": apiKey,
      recvWindow: 60000 ,
    },
  });
  const data = await response.json();
  // console.log(data);

  return data;
}

export async function getPositionInformation(
  apiKey,
  secretKey,
  symbol = "BTCUSDT"
  
) {
  const timestamp = calculateTimestamp();
  const queryString = `symbol=${symbol}&timestamp=${timestamp}`;
  const signature = calculateSignature(queryString, secretKey);
  const url = `${baseUrl_testnetFuture}/fapi/v1/positionRisk?${queryString}&signature=${signature}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "X-MBX-APIKEY": apiKey,
      recvWindow: 60000 , // adjust the value of recvWindow as needed
    },
  });

  const data = await response.json();
  console.log(data);
  return data;
}

export function calStartDateEndDateList(startTimestamp, endTimestamp) {
  const startDate = new Date(startTimestamp * 1000);
  const endDate = new Date(endTimestamp * 1000);
  // const startDate = new Date(startTimestamp);
  // const endDate = new Date(endTimestamp);

  endDate.setHours(23, 59, 59, 99); // set end date to midnight

  // const options = {
  //   weekday: "long",
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  // };

  // console.log(endDate);
  //   endDate.toLocaleDateString("en-US", options);

  const weeks = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    const weekStartDate = new Date(currentDate);
    const weekEndDate = new Date(
      currentDate.setDate(currentDate.getDate() + 6)
    );
    if (weekEndDate > endDate) {
      weekEndDate.setTime(endDate.getTime());
    }

    weekStartDate.setHours(23, 59, 59, 99);
    weekEndDate.setHours(23, 59, 59, 99);

    const startTimestamp = Date.parse(weekStartDate);
    const endTimestamp = Date.parse(weekEndDate);
    weeks.push({
      startDate: weekStartDate,
      endDate: weekEndDate,
      startDateTimeStamp: startTimestamp,
      endDateTimeStamp: endTimestamp,
    });
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return weeks;
}

export async function getTradingBotTradingHistoryData(
  credentialApiKey,
  credentialSecretKey,
  startTime,
  endTime
) {
  console.log("getTradingBotTradingHistoryData called!!");

  let tradingHistoryData = [];

  try {
    const weeks = calStartDateEndDateList(parseInt(startTime), parseInt(endTime));
    // const weeks = calStartDateEndDateList(1682138451000);

    if (weeks.length <= 0) {
      console.error("Invalid startTime and endTime");
    }
    // console.log('List of weeks: ',weeks)

    for (const week of weeks) {
      let start = Date.parse(week.startDate);
      let end = Date.parse(week.endDate);

      console.log("start:",start, "end:",end);

      const data = await getTradeHistory(
        credentialApiKey,
        credentialSecretKey,
        start,
        end
        // "3e5f37a080a714695ff6e7258208b810341b570a2670d60eb54a721fd85cbabe",
        // "21332e7b9ea914266fa6d954c674b064591ba4f82005fa82d40aebb3422ef5c8",
        // start,
        // end
      );
      console.log(data)

      if (data.length > 0) {
        // console.log("History data of week", weeks.indexOf(week) + 1, ":", data);
        tradingHistoryData = tradingHistoryData.concat(data);
        // console.log("list appended");
      }
    }
    // console.log('All Trading History:', tradingHistoryData);
    return tradingHistoryData;
  } catch (err) {
    console.error(err);
  }
}
