import React, { useState, useEffect } from "react";
import useSticky from "./use-sticky";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
  
const ScrollToTop = () => {
    const { sticky } = useSticky();
    
    const [showScroll, setShowScroll] = useState(false);
    
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
      }
    };

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    // eslint-disable-next-line
    useEffect(() => {

    
        window.addEventListener("scroll", checkScrollTop);
        return () => window.removeEventListener("scroll", checkScrollTop);
    }, []);

    return React.createElement(
        React.Fragment,
        null,
        /* <!-- Scroll-top --> */
        React.createElement(
          "div",
          {
            
            className: `scroll-top scroll-to-target ${sticky ? "open" : ""}`,
            "data-target": "html",
            style: { backgroundColor: "transparent" },
            sx:{position:"relative"}
          },
          React.createElement(
            ArrowUpwardIcon, 
            { 
            color: "secondary", 
            sx:{position:"fixed",fontSize: "35px"},
            onClick: scrollTop, })
          
        ),
        /* <!-- Scroll-top-end--> */
      );
  };
  


export default ScrollToTop;