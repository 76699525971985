import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";

const CardFeature = (props) => {
  return (
    <div>
      <Card
        sx={{
          width: 350,
          background: "#3D2942",
          color: "white",
          boxShadow: 5,
          borderRadius: 5,
        }}
      >
        <Grid container justifyContent="center">
          <img
            sx={{ width: 90, height: 90, ml: 16, mt: 1 }}
            src={props.img}
            alt="img"
            style={{
              padding: "30px",
              width: "90px",
              height: "90px",
              // marginLeft:'90px'
            }}
          />
        </Grid>
        <CardContent>
          <Typography align="center" gutterBottom variant="h5" component="h2">
            {props.title}
          </Typography>
          <Typography
            align="center"
            variant="body2"
            color="#B6B6BE"
            component="p"
          >
            {props.description}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default CardFeature;
