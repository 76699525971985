import axios from "axios";

import https from "https";
// const API = "http://157.230.47.113";
const API = "https://docker.tradingbothub.net";

const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

export const listImage = () => {
  axios
    .get(API + `/images/json?all=false`)
    .then((res) => {
      console.log("list image", res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const buildImage = async (imageName, remoteUrl) => {
  console.log(">>>>>>>", imageName, remoteUrl);
  await instance
    .post(API + `/build?t=${imageName}&remote=${remoteUrl}`)
    .then((res) => {
      console.log("successful build image", res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createContainer = async (
  containerName,
  imageName,
  clientEnv
) => {
  await axios
    .post(API + `/containers/create?name=${containerName}`, {
      Image: imageName,
      Env: clientEnv || [],
    })
    .then((res) => {
      console.log("successful create container", res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const startContainer = async (containerName) => {
  console.log(API + `/containers/${containerName}/start?detachKey=-d`);
  await axios
    .post(API + `/containers/${containerName}/start?detachKey=-d`)
    .then((res) => {
      console.log("successful start container", res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const stopContainer = async (containerName) => {
  await axios
    .post(API + `/containers/${containerName}/stop?t=1`)
    .then((res) => {
      console.log("successful stop container", res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const restartContainer = async (containerName) => {
  await axios
    .post(API + `/containers/${containerName}/restart`)
    .then((res) => {
      console.log("successful restart container", res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getContainerStatus = async (containerName) => {
  try {
    const res = await axios.get(API + `/containers/${containerName}/json`);
    console.log("successful get container status", res.data.State.Status);
    return res.data.State.Status;
  } catch (error) {
    console.log(error);
  }
};
export const removeContainer = async (containerName) => {
  await axios
    .delete(API + `/containers/${containerName}?v=true&force=true&link=false`)
    .then((res) => {
      console.log("successful restart container", res);
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
