/* eslint-disable react-hooks/exhaustive-deps */
import {
  Stack,
  Typography,
  createTheme,
  ThemeProvider,
  Button,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/system";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { alpha } from "@mui/material/styles";
import {
  getAllBotsByOwner,
  getAllSaleBotsByOwner,
} from "../../services/smart contract/Smartcontract";
// import { CircularProgress } from "@mui/material";

// eslint-disable-next-line no-unused-vars
import TradingBot from "../../services/smart contract/TradingBot";
// eslint-disable-next-line no-unused-vars
import { id } from "ethers/lib/utils";

import { ethers } from "ethers";
import { getOwnUserDetails } from "../../services/api";
const account = window.localStorage.getItem("useraddress");
const theme = createTheme({
  // Tell MUI what the font-size on the html element is.
  typography: {
    subtitle1: {
      fontSize: 33,
      fontFamily: "Montserrat",
      fontWeight: 300,
      color: "#F5FBF2",
      letterSpacing: "0.0075em",
      verticalAlign: "middle",
      alignItems: "center",
      textAlign: "center",
      main: "#F5FBF2",
      marginTop: 20,
    },
    subtitle2: {
      fontSize: 17,
      fontFamily: "Montserrat",
      fontWeight: 300,
      color: "#FFFFFF",
      letterSpacing: "0.0075em",
      verticalAlign: "middle",
      alignItems: "center",
      textAlign: "left",
      main: "#F5FBF2",
      marginTop: 20,
    },
    leftsearch: {
      alignItems: "right",
      textAlign: "right",
    },
    tableToptext: {
      fontWeight: 600,
    },
  },
  palette: {
    neutral: {
      main: "#FFCE4E",
      contrastText: "#000000",
    },
    AppColor: {
      main: "#302034",
      contrastText: "#000000",
    },
    createbutton: {
      main: "#FFFFFF",
      contrastText: "#FFFFFF",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 20,
    fontWeight: 600,
    backgroundColor: "#251928",
    color: "#f0f0f0",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "#f0f0f0",
    backgroundColor: "#251928",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const ViewOwnProfile = ({id, setID }) => {
  // const ViewOwnProfile = () => {
  const [databot, setDataBot] = useState([]);
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = useState(true);
  //Search filter
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const tokens = window.localStorage.getItem("token");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // async function GetAllBotsByOwner() {
  //   const tradingBotList = await getAllBotsByOwner(account);
  //   console.log(tradingBotList);
  //   setDataBot(tradingBotList);
  // }
  // async function GetAllSaleBotsByOwner() {
  //   const tradingBotList = await getAllSaleBotsByOwner(account);
  //   setDataBot(tradingBotList);
  // }
console.log("data bot",databot)
  useEffect(() => {
    async function GetAllBotsByOwner() {
      try {
        const tradingBotList = await getAllBotsByOwner(account);
        setDataBot(tradingBotList);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching bot list:", error);
        setLoading(false);
      }
    }

    async function GetAllSaleBotsByOwner() {
      try {
        const tradingBotList = await getAllSaleBotsByOwner(account);
        setDataBot(tradingBotList);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching sale bot list:", error);
        setLoading(false);
      }
    }

    async function GetOwnUserDetails() {
      try {
        const res = await getOwnUserDetails(account, tokens);
        setData(res);
      } catch (error) {
        console.log("Error fetching own user details:", error);
      }
    }

    setLoading(true); // Set loading state to true initially

    if (account && value === 0) {
      GetAllBotsByOwner();
    } else if (account && value === 1) {
      GetAllSaleBotsByOwner();
    }

    if (account !== null && tokens !== null) {
      GetOwnUserDetails();
    }
  }, [account, value]);

  // if (loading) {
  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: "calc(100vh - 250px)", // Adjust the height based on your layout
  //       }}
  //     >
  //       <CircularProgress />
  //     </Box>
  //   ); // Render loading state
  // }
  // const [have,setHave] = useState()

  // setHave(() => {
  //   alert("Noresult Matched !!!")
  // })
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Typography variant="subtitle1">Profile</Typography>
        <Box direction="column" pb={10} m={3}>
          <Stack direction="column">
            <Box direction="row">
              <Stack direction="row">
                <Stack pr={10}>
                  <Avatar
                    alt="Remy Sharp"
                    src={data.image}
                    sx={{ width: 160, height: 160 }}
                  />
                  <Box pt={5.6}>

                    {data._id ? (
                      <Button
                        href={`viewownprofile/` + data._id}
                        variant="outlined"
                        color="neutral"
                        sx={{ fontSize: 15, width: "130px" }}
                      >
                        View API
                      </Button>

                    ):(null)}
                    
                  </Box>
                </Stack>
                <Box direction="column">
                  <Stack direction="column" pr={50}>
                    <Typography variant="subtitle2">
                      Name: {data.name}
                    </Typography>
                    <Typography variant="subtitle2">
                      binance API: {data.apiKey}{" "}
                    </Typography>
                    <Typography variant="subtitle2">
                      Role: {data.role}{" "}
                    </Typography>
                    <Typography variant="subtitle2" pb={2}>
                      BIO: {data.bio}
                    </Typography>
                    <Button
                      href={`edituserprofile/` + data._id}
                      variant="outlined"
                      color="neutral"
                      sx={{ fontSize: 15, width: "130px" }}
                    >
                      Edit profile
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Box>
        <Box width={500} variant="subtitle2">
          <Search
            sx={{
              mt: 2,
            }}
          >
            <SearchIconWrapper>
              <SearchIcon color="createbutton" />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search me here ... "
              inputProps={{
                "aria-label": "search",
                style: { fontFamily: "Arial", color: "white" },
              }}
              fontcolor="green"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Search>
        </Box>
        <Grid m={3} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Collection" sx={{ color: "white" }} />
            <Tab label="Bot on Sale" sx={{ color: "white" }} />
          </Tabs>
          <Button
            href="createtradingbot"
            variant="outlined"
            color="neutral"
            sx={{ fontSize: 13 }}
          >
            Create
          </Button>
        </Grid>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              height: "calc(100vh - 250px)", // Adjust the height based on your layout
            }}
          >
            <CircularProgress />
          </Box>
        ) : databot.length === 0 ? (
          <Typography
            variant="subtitle2"
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              fontWeight: "bold",
              color: "#a4a4a4",
              marginBottom: "1rem",
            }}
          >
            {value === 1 ? "No bot on sale" : "No TradingBot"}
          </Typography>
        ) : (
          <Box direction="column" m={3}>
            <Paper>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">NO.</StyledTableCell>
                      <StyledTableCell align="center">Bot</StyledTableCell>
                      <StyledTableCell align="center">
                        Description
                      </StyledTableCell>
                      <StyledTableCell align="center">Address</StyledTableCell>
                      <StyledTableCell align="center">Price</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {databot
                      .filter((row) =>
                        row.displayName
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      )
                      .map((row) => row.id).length === 0 ? (
                      <TableRow>
                        <StyledTableCell colSpan={6} align="center">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              style={{
                                fontSize: "1.2rem",
                                fontWeight: "bold",
                                color: "#a4a4a4",
                                marginBottom: "1rem",
                              }}
                            >
                              Bot Not Found
                            </Typography>
                          </div>
                        </StyledTableCell>
                      </TableRow>
                    ) : (
                      databot
                        .filter((row) =>
                          row.displayName
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        )
                        .map((row, id) => (
                          <StyledTableRow key={row.id}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              align="center"
                            >
                              {id + 1}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Box direction="row">
                                <Stack direction="row" align="center">
                                  <Button href="viewownprofile" disabled>
                                    <Grid pr={2}>
                                      <Avatar
                                        alt="Remy Sharp"
                                        src={row.image}
                                      />
                                    </Grid>
                                    <Typography textAlign="right" color="white">
                                      {row.displayName}
                                    </Typography>
                                  </Button>
                                </Stack>
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Typography textAlign="left" color="white">
                                {row.description}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.OwnerAddress}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ethers.utils.formatEther(row.price)} Ether
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Button
                                variant="contained"
                                color="neutral"
                                href={`/viewbotdetail/` + row.id}
                              >
                                Detail
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        )}
      </ThemeProvider>
    </div>
  );
};

export default ViewOwnProfile;
