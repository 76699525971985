import axios from "axios";
// import cors from "cors";
// eslint-disable-next-line no-unused-vars
const API = require("../config/API.config");

// Create user
export async function createUser(id, pwd, role) {
  try {
    // const response = await axios.post("http://localhost:3000/api/user", {
    const response = await axios.post(
      "https://trading-bot-api.vercel.app/api/user",
      // "https://tradingbotapi.fly.dev/api/user",
      {
        _id: id,
        password: pwd,
        role: role,
      }
    );
    return response.data;
  } catch (err) {
    console.error(err);
  }
}

// Generate token user
export async function generateToken(id, pwd) {
  try {
    // const response = await axios.post('http://localhost:3000/api/user/generate-token', {
    const response = await axios.post(
      "https://trading-bot-api.vercel.app/api/user/generate-token",
      // "https://tradingbotapi.fly.dev/api/user/generate-token",
      {
        _id: id,
        password: pwd,
      }
    );
    return response.data["access_token"];
  } catch (err) {
    // console.log(err);
  }
}
// Update User
export async function updateUser(
  id,
  token,
  new_pwd,
  new_name,
  new_bio,
  new_image,
  new_role,
  new_apiKey,
  new_secretKey
) {
  try {
    // const res = await axios.patch(`http://localhost:3000/api/user/${id}`, {
    const res = await axios.patch(
      // `https://tradingbotapi.fly.dev/api/user/${id}`,
      `https://trading-bot-api.vercel.app/api/user/${id}`,

      {
        password: new_pwd,
        name: new_name,
        bio: new_bio,
        image: new_image,
        role: new_role,
        apiKey: new_apiKey,
        secretKey: new_secretKey,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (err) {
    console.error(err);
  }
}
// export async function updateUserRoleById(id, token) {
//   try {
//     const res = await axios.patch(`https://tradingbotapi.fly.dev/api/user/role/${id}`,
//       {
//         headers: { Authorization: `Bearer ${token}` }
//       })
//     return res
//   } catch (err) {
//     console.error(err)
//   }
// }

export async function updateUserRoleById(id, token) {
  return axios
    .patch(`https://trading-bot-api.vercel.app/api/user/role/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => res)
    .catch((err) => console.error(err));
}

// export async function updateUserRole(id, token) {
//   try {
//     // const res = await axios.patch(`http://localhost:3000/api/user/${id}`, {
//     const res = await axios.patch(
//       `https://tradingbotapi.fly.dev/api/user/role/${id}`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return res;
//   } catch (err) {
//     console.error(err);
//   }
// }

// get own-user-details by id
export async function getOwnUserDetails(id, token) {
  try {
    // const response = await axios.get(`http://localhost:3000/api/user/get-own/${id}`, { headers: { "Authorization": `Bearer ${token}` } })
    const response = await axios.get(
      // `http://localhost:3001/api/user/get-own/${id}`,
      `https://trading-bot-api.vercel.app/api/user/get-own/${id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const data = response.data;
    console.log(data);
    return data;
  } catch (err) {
    console.error(err);
  }
}

// the funciton required jwt token received from "login" to work properly
export async function deleteUserById(id, token) {
  try {
    // const res = await axios.delete(`http://localhost:3000/api/user/${id}`, { headers: { "Authorization": `Bearer ${token}` } })
    const res = axios.delete(
      `https://trading-bot-api.vercel.app/api/user/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return res;
  } catch (err) {
    console.error(err);
  }
}
// Validate User Address
export async function validateUserId(id) {
  try {
    const response = await axios.get(
      `https://trading-bot-api.vercel.app/api/user/validate-address/${id}`
    );
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
  }
}

// get user by id
export async function getUserById(id) {
  try {
    const response = await axios.get(
      // `http://localhost:3001/api/user/get-own/${id}`
      `https://trading-bot-api.vercel.app/api/user/${id}`
    );
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
  }
}

// get all creators
export async function getAllCreator() {
  try {
    const response = await axios.get(
      `https://trading-bot-api.vercel.app/api/user/creator`
    );
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
  }
}

// get all users
export async function getAllUsers() {
  try {
    const response = await axios.get(
      `https://trading-bot-api.vercel.app/api/user/`
    );
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
  }
}

// add user's binance credential
export async function addUserBinanceCredential(
  id,
  token,
  binanceApiKey,
  binanceSecretKey,
  bianceCredentialName
) {
  try {
    const response = await axios.post(
      `https://trading-bot-api.vercel.app/api/user/binance-credential/${id}`,
      {
        credentialName: bianceCredentialName,
        apiKey: binanceApiKey,
        secretKey: binanceSecretKey,
        status: false,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    // const data = response;
    // console.log(data)
    return response;
  } catch (error) {
    if (error.response && error.response.status === 400) {
      // Handle "Bad Request" error
      console.log('Bad Request:', error.response.data);
      return error.response;
    } else {
      // Handle other errors
      console.log('Error:', error.message);
    }
  }
}

// delete user's binance credential
export async function deleteBinanceCredential(id, binanceCredentialId, token) {
  try {
    const response = await axios.patch(
      `https://trading-bot-api.vercel.app/api/user/remove-binance-credential/${id}`,
      { credentialID: binanceCredentialId },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const data = response.data;
    // console.log(data)
    return data;
  } catch (err) {
    console.error(err);
  }
}

// connect binance credential to a tradingbot
export async function connectBinanceCredential(id, binanceCredentialId, token) {
  try {
    const response = await axios.patch(
      `https://trading-bot-api.vercel.app/api/user/connect-binance-credential/${id}`,
      { credentialID: binanceCredentialId },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const data = response.data;
    // console.log(data)
    return data;
  } catch (err) {
    console.error(err);
  }
}

// disconnect binance credential to a tradingbot
export async function disConnectBinanceCredential(
  id,
  binanceCredentialId,
  token
) {
  try {
    const response = await axios.patch(
      `https://trading-bot-api.vercel.app/api/user/disconnect-binance-credential/${id}`,
      { credentialID: binanceCredentialId },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const data = response.data;
    // console.log(data)
    return data;
  } catch (err) {
    console.error(err);
  }
}
