/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
// import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Alert from "@mui/material/Alert";
import {
  Grid,
  Stack,
  createTheme,
  ThemeProvider,
  Link,
  // eslint-disable-next-line no-unused-vars
} from "@mui/material";
import {
  calStartDateEndDateList,
  getTradingBotTradingHistoryData,
} from "../../services/binanceAPI/binanceAPI";
import {
  addUserBinanceCredential,
  connectBinanceCredential,
  deleteBinanceCredential,
  disConnectBinanceCredential,
} from "../../services/api";
import { getOwnUserDetails } from "../../services/api";
import logo from "../../assets/image/logo.png";
import { useState, useRef, useEffect  } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { createUser, generateToken } from "../../services/api";



import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


// eslint-disable-next-line no-unused-vars
import { async } from "@firebase/util";
// import MarketplaceAbi from '../contractsData/Marketplace.json'
// import MarketplaceAddress from '../contractsData/Marketplace-address.json'
// import NFTAbi from '../contractsData/NFT.json'
// import NFTAddress from '../contractsData/NFT-address.json'
const pages = [
  { name: "Market", rout: "/botforsale" },
  { name: "Suport", rout: "/supportpage" },
];
const Narbar = ({
  Gettem,
  web3Handler,
  onDisconnect,
  balance,
  isvalidate,
  SetIsvalidate,
  isSignIn,
  SetISSignIn,
  isLogin,
  setISLogin,
  token,
  SetToken,
}) => {
  const [formData, setFormData] = useState({
    password: "",
    id: "",
    role: "user",
  });

  const navigate = useNavigate();
  const account = window.localStorage.getItem("useraddress");

  const disconnectWallet = () => {
    onDisconnect();
    setvalidateToken(false);
    navigate("/");
  };
  // eslint-disable-next-line no-unused-vars
  const { id, password, role } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [data, setData] = useState([]);
  const [opens, setOpen] = useState(false);
  const [onConnect, setOnConnect] = useState(false);
  const [openAccount, SetOpenAccount] = useState(false);
  const [validateToken, setvalidateToken] = useState(false);
  // show alert when user input password
  const [alert, setAlert] = useState(false);
  const [alert_message, setAlert_message] = useState("");
  // eslint-disable-next-line no-unused-vars

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // reference for password input
  const passwordInputRef = useRef(null);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const tokens = window.localStorage.getItem("token");
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const theme = createTheme({
    // Tell MUI what the font-size on the html element is.
    palette: {
      neutral: {
        main: "#FFCE4E",
        contrastText: "#000000",
      },
      AppColor: {
        main: "#302034",
        contrastText: "#000000",
      },
      createbutton: {
        main: "#FFFFFF",
        contrastText: "#FFFFFF",
      },
    },
  });
console.log("alert after login", alert)
console.log("validationToken",validateToken)
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      formData.role = role;
      formData.password = password;
      // Username, password,bio,userimg,role,api,secretKey
      try {
        if (isvalidate === false && isSignIn === true) {
          const res = await createUser(
            account,
            formData.password,
            formData.role
          );
          //using local storage to store token
          window.localStorage.setItem("token", res.token);
          SetToken(res.token);
          setOnConnect(false);
          SetIsvalidate(true);
          setvalidateToken(true);
         
        } else if (isvalidate === true && isLogin === true) {
          const res = await generateToken(account, formData.password);

          if (res === undefined) {
            window.localStorage.setItem("token", res);
            SetToken(res);
            setOnConnect(false);
            SetIsvalidate(true);
            setvalidateToken(false);
            setAlert(true);
          } else {
            window.localStorage.setItem("token", res);
            setOnConnect(false);
            SetIsvalidate(true);
            setvalidateToken(true);
            // setvalidateToken(false);
            
          }
        }
      } catch (err) {}
    } catch (err) {}
  };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
    setOnConnect(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && formData.password !== "") {
      console.log("enter press here! ");
      onSubmit(event);
    }
  }

  async function GetOwnUserDetials() {
    const res = await getOwnUserDetails(account, tokens);
    // const historyResponseData = await getTradingBotTradingHistoryData()
    // console.log(res)
    setData(res);
  }

  // async function AddUserBinanceCredential() {
  //   const res = await addUserBinanceCredential(
  //     account,
  //     tokens,
  //     "apikeyReactTest",
  //     "secretKeyReactTest"
  //   );
  //   console.log(res);
  // }
  // async function ConnectBinanceCredentialToTradingBot() {
  //   const res = await connectBinanceCredential(
  //     account,
  //     "1682325558877",
  //     tokens
  //   );
  //   console.log(res);
  // }

  // async function DisConnectBinanceCredentialToTradingBot() {
  //   const res = await disConnectBinanceCredential(
  //     account,
  //     "1684747553922",
  //     tokens
  //   );
  //   console.log(res);
  // }

  // async function DeleteUserBinanceCredential() {
  //   // console.log("token:", tokens)
  //   // console.log("account:", account)
  //   const res = await deleteBinanceCredential(account, "1682325314259", tokens);
  //   console.log(res);
  // }

  // async function GetTradingBotTradingHistoryData() {
    // const credential1 = data.credentials[0]
    // const apiKey = credential1.apiKey
    // const secretKey = credential1.secretKey
    // const startTime = credential1.startTime

    // const historyData = await getTradingBotTradingHistoryData(apiKey, secretKey, startTime, endTime)
  //   const historyData = await getTradingBotTradingHistoryData(
  //     "3e5f37a080a714695ff6e7258208b810341b570a2670d60eb54a721fd85cbabe",
  //     "21332e7b9ea914266fa6d954c674b064591ba4f82005fa82d40aebb3422ef5c8",
  //     "1682640000",
  //     "1682812800"
  //   );
  //   console.log("Trading History Data:", historyData);
  // }
  // async function CalculateDateList() {
  //   const data = calStartDateEndDateList(1677722543, 1682738326);
  //   console.log(data);
  // }
  async function onConnectButtonClick() {
    console.log("....processing....")
    const chainId = window.localStorage.getItem("ChainId");
    console.log("this is return chanID", chainId)
    if (chainId === "11155111") {
      await web3Handler();
      setOnConnect(true);
    }else{
      console.log("please connect to Sepolia Test Netwok")
  }
  }


  useEffect(() => {
    // check if the token is valid
    /*
      add login to check token here
    */
     // testing validate token logic
     if (tokens !== null && tokens !== undefined && tokens !== "undefined") {
      setvalidateToken(true);
    } else {
      setvalidateToken(false);
    }

    setTimeout(() => {
      setAlert(false);
    }, 4000,  [tokens]);


    async function checkvalidat() {
      if (isvalidate === false && onConnect === true && isSignIn === true) {
        setOpen(true);
        setAlert_message("Please Sign In to Continue");
        SetOpenAccount(false);
      }

      if (isvalidate === true && onConnect === true && isLogin === true) {
        setOpen(true);
        setAlert_message("Please Login to Continue");
        SetOpenAccount(false);
      }
      if (isvalidate === true && onConnect === false) {
        setOpen(false);
        SetOpenAccount(true);
      }
    }

    if (account !== null && tokens !== null && validateToken === true) {
      GetOwnUserDetials();
      
    }

    web3Handler();
    checkvalidat();
  }, [
    opens,
    isvalidate,
    onConnect,
    isSignIn,
    isLogin,
    account,
    tokens,
    validateToken,
  ]);

  // active button
  const [alignment, setAlignment] = React.useState("web");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" color="AppColor">
        {/* <Container> */}
        <Toolbar
          disableGutters
          sx={{
            display: { xs: "flex" },
            flexDirection: "row",
            justifyContent: "space-around",
            mx: 8,
          }}
        >
          {/* make all child element is row and make space of child base on respone */}
          <Stack
            direction="row"
            spacing={{ xs: 1, sm: 1, md: 1, lg: 15, xl: 20 }}
          >
            {/* child logo */}
            <Grid pt={1}>
              <Link
                href="/"
                sx={{
                  cursor: "pointer",
                  display: { xs: "none", md: "block" },
                }}
              >
                <img alt="this is an logo" src={logo} />
              </Link>
            </Grid>

            {/* child menue display when the sreeen display on xs breakpoints  */}
            <Grid>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "flex", md: "none" },
                  mt: 2,
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="createbutton"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        navigate(page.rout);
                        handleCloseNavMenu();
                      }}
                    >
                      <Typography textAlign="center">{page.name}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Grid>

            {/* child menue display when the sreeen display on md breakpoints */}
            <Grid>
              <Stack direction="row">
                <Grid>
                  <Box
                    sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}
                  >
                    <ToggleButtonGroup
                      color="secondary"
                      value={alignment}
                      exclusive
                      onChange={handleChange}
                      aria-label="Platform"
                    >
                      {pages.map((page, index) => (
                        <ToggleButton
                          key={index}
                          onClick={() => {
                            navigate(page.rout);
                            handleCloseNavMenu();
                          }}
                          sx={{ my: 2, color: "white", display: "block" }}
                          value={page.name}
                        >
                          {page.name}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Box>
                </Grid>

                <Grid pt={{ xs: 2, sm: 2.5 }} pl={{ xs: 1, sm: 10 }}>
                  {tokens && openAccount === true && validateToken === true ? (
                    <Stack direction="row">
                      <Grid pr={2}>
                        <Button
                          onClick={disconnectWallet}
                          variant="outlined"
                          color="neutral"
                          sx={{
                            fontSize: 12,
                            fontWeight: "bold",
                            width: 140,
                          }}
                        >
                          DisConnect
                        </Button>
                      </Grid>
                      <Grid pr={4}>
                        <Tooltip title="Open settings">
                          <IconButton
                            onClick={() => navigate("/viewownprofile")}
                            sx={{ p: 0 }}
                          >
                            <Avatar alt="Remy Sharp" src={data.image} />
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      <Grid
                        sx={{
                          color: "white",
                          display: { xs: "none", md: "block" },
                        }}
                      >
                        <Typography align="left">
                          Address:
                          {account.slice(0, 5) + "..." + account.slice(38, 42)}
                        </Typography>
                        <Typography align="left">
                          Balance:{parseFloat(balance).toFixed(5)} ETH
                        </Typography>
                      </Grid>
                    </Stack>
                  ) : (
                    
                    <Grid>
                      <Grid>
                      {/* login alert */}
                      <Dialog open={opens} onClose={handleClose} aria-labelledby='form-dialog-title'> 
                        <Box style={{
                                width: 350,
                                height: 220,
                                background: "#302034",
                                display: "flex",
  
                                flexDirection: "column",
                              }}>
                          <DialogTitle 
                          id='form-dialog-title' color="white" 
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: "bold",
                            fontSize: 25,
                          }}> {alert_message}</DialogTitle>
                          <DialogContent
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }} >
                              <FormControl  sx={{m:2}} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password"
                                style={{ color:"#6D788D" }}>Password</InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  style={{ color: '#6D788D', background: '#302034', borderColor: '#3d6cb9' }}
                                  type={showPassword ? 'text' : 'password'}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        style={{color: '#6D788D'}}
                                      >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label="Password"
                                  name="password"
                                  onChange={onChange}
                                  ref={passwordInputRef}
                                  onKeyPress={handleKeyPress}

                                />
                              </FormControl>
                          </DialogContent>
                          <DialogActions className='dialog-actions-dense'
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            
                          }}>
                            <Button onClick={handleClose} 
                            sx={{
                                    ":hover": {
                                      color: "#6D788D",
                                    },
                                    
                                  }}
                            variant="outlined"
                            color="neutral"
                          >Cancel</Button>
                            <Button onClick={onSubmit}
                            sx={{
                                    ":hover": {
                                      color: "#12db00",
                                    },
                                  }}
                            variant="outlined"
                            color="neutral"
                                >Ok</Button>
                          </DialogActions>
                          </Box>
                      </Dialog>
                      
                        {/* alert after login*/}

                        <Dialog open={alert}>
                          <Alert variant="filled" severity="error">
                          Invalid Password
                          </Alert>
                        </Dialog>
                      </Grid>
                      <Button
                        onClick={
                          onConnectButtonClick}
                        variant="outlined"
                        color="neutral"
                        sx={{ fontSize: 12, fontWeight: "bold", width: 140 }}
                      >
                        Connect Wallet
                      </Button>
                      
                    </Grid>
                  )}
                </Grid>
              </Stack>
            </Grid>
          </Stack>
        </Toolbar>
        {/* </Container> */}
      </AppBar>
    </ThemeProvider>
  );
};
export default Narbar;