import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Web3 from "web3";
import { Box, Grid } from "@mui/material";
import Navbar from "./components/Navbar/Navbar";
import theme from "./components/style/theme";
import AboutUs from "./pages/About-Us/About-Us";
import AllTopCreator from "./pages/Alltop-Creator/Alltop-Creator";
import BotForSale from "./pages/Bot-For-Sale/Bot-For-Sale";
import Eachbotforsale from "./pages/Bot-For-Sale/Eachbotforsale";
import CreateBot from "./pages/Create-Tradingbot/Create-Tradingbot";
import EditUserProfile from "./pages/Edit-User-Profile/Edit-User-Profile";
import APIKey from "./pages/User-API-Key/User-API-Key";
import Main from "./pages/Main";
import SupportPage from "./pages/Support/Support-Page";
import UpdateTradingBot from "./pages/Update-Trandingbot/Update-Tradingbot";
import ALlPopularTradingBot from "./pages/view-All-popular-bot/view-all-popular-bot";
import ViewBotDetail from "./pages/View-Bot-Details/View-Bot-Details";
import ViewOtherProfile from "./pages/View-Other-Profile/View-Other-Profile";
import ViewOwnProfile from "./pages/View-Own-Profile/View-Own-Profile";
import TeamDetails from "./pages/TeamDetails/teamdetails";
import CreateAPI from "./pages/User-API-Key/Create-API";
import { validateUserId } from "./services/api";
import ProtectedRoute from "./utils/ProtectedRout";
import NotFound from "./pages/NotFound";
import ErrorPage from "./pages/500";
import Wrapper from "./components/wrapper";



function App() {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const [account, setAccount] = useState(null);
  const [isvalidate, SetIsvalidate] = useState(false);

  const [isSignIn, SetISSignIn] = useState(false);
  const [isLogin, setISLogin] = useState(false);
  const [token, SetToken] = useState(null);
  const [showWrapper, setShowWrapper] = useState(false);
  const [DisplayBalance, setDisplayBalance] = useState(0.0);



  useEffect(() => {
    if (!account) {
      const previousAddress = window.localStorage.getItem("useraddress");
      if (previousAddress) {
        setAccount(previousAddress);
      }
    }
    const previousBalance = window.localStorage.getItem("balance");

    if (DisplayBalance !== previousBalance) {
      setDisplayBalance(previousBalance);
    }
    setDisplayBalance(previousBalance);

    function handleScroll() {
      if (window.pageYOffset > 600) {
        setShowWrapper(true);
      } else {
        setShowWrapper(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [account, DisplayBalance, token]);
window.localStorage.setItem("Network", "Sepolia Test Netwok");
  // MetaMask Login/Connect
  const web3Handler = async () => {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const check_accounts = Web3.utils.toChecksumAddress(accounts[0]);
    setAccount(check_accounts);
    // Get provider from Metamask
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // Set signer
    const signer = provider.getSigner();
    window.ethereum.on("chainChanged", (chainId) => {
      window.location.reload();
    });
    window.ethereum.on("accountsChanged", async function (accounts) {
      //when the user changes the account in metamask we will remove token, balance and useraddress from local storage
      // user need to click connete button add the home page again
      // if the user on the other page we will redirect to home page
      window.localStorage.removeItem("useraddress");
      window.localStorage.removeItem("balance");
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("ChainId");
      setAccount(true);
      setAccount(check_accounts);
      await web3Handler();
    });
    loadContracts(signer);
    // formate bignumber into float number
    const balance = ethers.utils.formatEther(
      await provider.getBalance(check_accounts)
    );
    const DisplayBalance = parseFloat(balance);
    setDisplayBalance(DisplayBalance);

    // useing local storage to store address
    window.localStorage.setItem("useraddress", check_accounts);
    window.localStorage.setItem("balance", DisplayBalance);
    const uservalited = await validateUserId(check_accounts);
    if (uservalited) {
      setISLogin(true);
      SetIsvalidate(true);
    }
    if (!uservalited) {
      SetISSignIn(true);
      SetIsvalidate(false);
    }
  };

  const loadContracts = async (signer) => {
    setLoading(false);
  };


  const wrapperStyle = {
    display: showWrapper ? "block" : "none",
    position: "absolute",
    top: "400px",
    right: "45px",
    zIndex: "1000",
  };
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Box>
          <Grid style={wrapperStyle}>
            <Wrapper />
          </Grid>

          <Grid>
            <Navbar
              web3Handler={() => {
                web3Handler();
              }}
              onDisconnect={() => {
                setAccount(null);
                window.localStorage.removeItem("useraddress");
                window.localStorage.removeItem("balance");
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("ChainId");
              }}
              balance={DisplayBalance}
              isvalidate={isvalidate}
              SetIsvalidate={SetIsvalidate}
              isSignIn={isSignIn}
              SetISSignIn={SetISSignIn}
              isLogin={isLogin}
              setISLogin={setISLogin}
              token={token}
              SetToken={SetToken}
            />
          </Grid>

          <Grid>
            <Routes>
              <Route element={<ProtectedRoute />} account={account}>
                {" "}
                <Route
                  path="/viewownprofile"
                  element={<ViewOwnProfile account={account} />}
                />
                <Route
                  path="/viewownprofile/:id"
                  account={account}
                  element={<APIKey />}
                />
                <Route
                  path="viewownprofile/createAPIKey"
                  element={<CreateAPI account={account} />}
                />
                <Route
                  path="/createtradingbot"
                  element={<CreateBot account={account} />}
                />
                <Route
                  path="/botforsale"
                  element={
                    <BotForSale
                      account={account}
                      DisplayBalance={DisplayBalance}
                    />
                  }
                />
                <Route
                  path="/edituserprofile/:id"
                  account={account}
                  element={<EditUserProfile />}
                />
                <Route
                  path="/updatetradingbot/:id"
                  element={<UpdateTradingBot account={account} />}
                />
                <Route
                  path="/viewbotdetail/:id"
                  element={<ViewBotDetail account={account} />}
                />
              </Route>

              <Route
                path="/"
                element={
                  <Main account={account} DisplayBalance={DisplayBalance} />
                }
              />

              <Route path="/supportpage" element={<SupportPage />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/team-details/:id" element={<TeamDetails />} />
              <Route path="*" element={<NotFound />} />
              {/* Add Internal */}
              <Route element={<ErrorPage />} />

              <Route
                path="/alltopcreator"
                element={<AllTopCreator account={account} />}
              />

              <Route path="/viewotherprofile" element={<ViewOtherProfile />} />
              <Route
                path="/viewotherprofile/:id"
                element={<ViewOtherProfile />}
              />

              <Route
                path="/eachbotforsale/:id"
                element={
                  <Eachbotforsale
                    account={account}
                    DisplayBalance={DisplayBalance}
                  />
                }
              />
              <Route
                path="/allpopulartradingbot"
                element={
                  <ALlPopularTradingBot
                    account={account}
                    DisplayBalance={DisplayBalance}
                  />
                }
              />
            </Routes>
          </Grid>
        </Box>
      </BrowserRouter>
    </ThemeProvider>
    
  );
}

export default App;
