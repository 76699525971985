// import { combineReducers } from 'redux'
// import posts from './post'

// const allReducers=combineReducers({
//     users:posts
// });

// export default allReducers;

const initState = {
  post: [
    { id: "1", title: "helo jam", body: "hi" },
    { id: "2", title: "yumi", body: "tiger" },
    { id: "3", title: "ream", body: "em" },
  ],
  account: "hshsh",
};

// initState of redux
const allReducers = (state = initState, action) => {
  if (action.type === "Change_Acc") {
    let newacc = action.text;
    return {
      ...state,
      account: newacc,
    };
  }
  return state;
};

export default allReducers;
