import styled from "styled-components";

const TeamMemberStyleWrapper = styled.article`
  position: relative;
  background: #3D2942;
  backdrop-filter: blur(5px);
  margin-bottom: 30px;
  padding: 0 0 30px 0;
  text-align: center;
  transition: 1.4s;
  flex-direction: column;
  justify-content: space-between;

    /* Add box-shadow and transform properties */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  transform: translateY(-10px);
  transition: all 0.2s ease-in-out;
  
  

  div{
    color:#ffffff
  }

  h4.a{
    display: inline-block;
  }

  &.team-item {
    img {
      margin-bottom: 10px;
      width: 99.9%;
      height: 99.9%;
    }
    .dsc {
      margin-bottom: 15px;
    }
  }
  .team-title {
    font-size: 22px;
    margin-bottom: 8px;
    a {
      color: #ffffff;
      transition: 0.4s;
      text-decoration: none;
    }
  }

  .team-icon-list {
    display: flex;
    justify-content: center;
    align-items: center; 
    margin: 0; /* new style */
    padding: 0;
    li {
      
      display: inline-block;
      padding: 0 7px;
      a {
        color: rgba(255, 255, 255, 0.5);
        transition: 0.4s;
        
        svg {
          font-size: 20px;
        }
      }
    }
  }

  &:hover {
    background-image: radial-gradient(
      circle,
      rgba(137, 120, 211, 0.4) 0%,
      #1e1f35 100%
    );
    .card-hover-wrapper {
      opacity: 1;
      visibility: visible;
    }
  }

  @media only screen and (max-width: 991px) {
    &.team-item {
      img {
        margin-bottom: 30px;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    &.team-item {
      margin-bottom: 20px;
    }
  }
`;

export default TeamMemberStyleWrapper;
