import {
  Avatar,
  Button,
  ButtonBase,
  createTheme,
  TextField,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getBotById,
  listBotOnSale,
} from "../../services/smart contract/Smartcontract";
import { ethers } from "ethers";
// import { makeStyles } from '@material-ui/core/styles';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import buyTradingBot from "../../../src/services/smart contract/Smartcontract";
import {
  getAccountInformation,
  getPositionInformation,
  getTradingBotTradingHistoryData,
} from "../../../src/services/binanceAPI/binanceAPI";
import { CircularProgress } from "@mui/material";
// import { GET_ERRORS } from "./types";
import moment from "moment";
import {
  startContainer,
  stopContainer,
  getContainerStatus,
} from "../../services/dockerApi";
import {
  disConnectBinanceCredential,
  getOwnUserDetails,
} from "../../services/api";
import InformationBotCard from "../../components/InFormationBot";
import CustomButton from "./CustomButton";
import RechartsLineChart from "./RechartsLineChart";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
function formatDate(timestamp) {
  const date = new Date(timestamp);
  // Use the various Date methods to extract the relevant date parts and format them as needed
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}
const ViewBotDetails = ({ props, note, DisplayBalance, tokens }) => {
  const [data, setData] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [ownerbot, setOwnerBot] = useState([]);
  const [isowner, setIsOwner] = useState(true);
  const [openChart, setOpenChart] = useState(false);
  const params = useParams();
  const userID = params.id;
  const navigate = useNavigate();

  // fetch data from binanace api
  const [positionData, setPositionData] = useState([]);
  const [tradeHistoryData, setTradeHistoryData] = useState([]);

  const [accountInformationData, setAccountInformationData] = useState([]);
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
  });

  // state for fetch History
  const [apiKey, setApiKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [startTime, setStartTime] = useState("");

  // get APi key and secret key
  const token = localStorage.getItem("token");
  const account = window.localStorage.getItem("useraddress");

  const cuurent_time = Math.floor(Date.now() / 1000);
  useEffect(() => {
    // GetAllBotsByOwner()
    async function checkauthaddress() {
      try {
        try {
          const res = await getBotById(userID);
          console.log(res, "bot data");
          setData(res);
        } catch (error) {
          console.error(`Could not get products: ${error}`);
        }
        if (account === data.OwnerAddress) {
          setIsOwner(false);
        } else if (account !== data.OwnerAddress) {
          setIsOwner(true);
        }
      } catch (error) {
        console.error(`Could not get products: ${error}`);
      }
    }
    checkauthaddress();
  }, [isowner, userID, account, data.OwnerAddress]);

  useEffect(() => {
    async function getOwnUserDetailsData() {
      try {
        const res = await getOwnUserDetails(account, token);
        console.log("getOwnUserDetailsData", data.binanceCredentialID);
        if (data.binanceCredentialID !== null) {
          const credentialBot = res.credentials.filter(
            (credential) =>
              credential.status === true &&
              credential.credentialID === data.binanceCredentialID
          );

          console.log("credentialBot", credentialBot);
          setApiKey(credentialBot[0].apiKey);
          setSecretKey(credentialBot[0].secretKey);
          setStartTime(credentialBot[0].startTime);
        }
      } catch (error) {
        console.error(`Could not get products: ${error}`);
      }
    }
    getOwnUserDetailsData();
  }, [account, token, data.binanceCredentialID]);

  const getAccountInformationData = async () => {
    try {
      const res = await getAccountInformation(apiKey, secretKey);
      console.log("getAccountInformationData", res);
      setAccountInformationData(res);
    } catch (error) {
      console.error(`Could not get products: ${error}`);
    }
  };

  const getPositionInformationData = async () => {
    try {
      const res = await getPositionInformation(apiKey, secretKey);
      console.log("getPositionInformationData result", res);
      setPositionData(res);
    } catch (error) {
      console.error(`Could not get products: ${error}`);
    }
  };

  const getTradeHistoryData = async () => {
    try {
      const res = await getTradingBotTradingHistoryData(
        apiKey,
        secretKey,
        Math.floor(startTime / 1000),
        cuurent_time
      );
      // 1685948635
      // 1685954321 

      console.log(Math.floor(startTime / 1000), cuurent_time, "Time");
      setTradeHistoryData(res);
      console.log("getTradeHistoryData result", res);
    } catch (error) {
      console.error(`Could not get products: ${error}`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (apiKey && secretKey) {
        await getPositionInformationData();
        await getTradeHistoryData();
        await getAccountInformationData();
      }
    };

    fetchData();
  }, [apiKey, secretKey]);

  // filter data
  const filterData = (item) => {
    const { startDate, endDate } = filter;
    if (!startDate || !endDate) {
      return true;
    }
    const date = new Date(item.time * 1000);
    return date >= startDate && date <= endDate;
  };
  // handle change date
  const handleStartDateChange = (event) => {
    const date = new Date(event.target.value);
    setFilter((prevFilter) => ({
      ...prevFilter,
      startDate: date,
    }));
  };

  const handleEndDateChange = (event) => {
    const date = new Date(event.target.value);
    setFilter((prevFilter) => ({
      ...prevFilter,
      endDate: date,
    }));
  };
  // handle today click
  const handleTodayClick = () => {
    const today = new Date();
    const startOfDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    const endOfDay = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 1
    );

    setFilter({
      startDate: startOfDay,
      endDate: endOfDay,
    });
  };
  // handle last week click
  const handleLastWeekClick = () => {
    const today = new Date();
    const startOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    );
    const endOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay() + 7
    );

    setFilter({
      startDate: startOfWeek,
      endDate: endOfWeek,
    });
  };
  // handle last month click
  const handleLastMonthClick = () => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);

    setFilter({
      startDate: startOfMonth,
      endDate: endOfMonth,
    });
  };

  // last Three Month
  const handleLastThreeMonthsClick = () => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth() - 3, 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    console.log(startOfMonth, endOfMonth, "last three month");
    setFilter({
      startDate: startOfMonth,
      endDate: endOfMonth,
    });
  };

  // show all Histroty data
  const handleShowAllClick = () => {
    setFilter({
      startDate: null,
      endDate: null,
    });
  };

  // eslint-disable-next-line no-unused-vars

  // eslint-disable-next-line no-unused-vars
  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  });
  // eslint-disable-next-line no-unused-vars
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: "60px",
  }));
  // textfiled style
  // custom textfield
  const textfieldCustom = {
    "& label.Mui-focused": {
      color: "#6D788D",
    },
    "&:placeholder": {
      color: "#6D788D",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#6D788D",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#6D788D",
        borderWidth: "2px",
        color: "#6D788D",
      },
      "&:hover fieldset": {
        borderColor: "#6D788D",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6D788D",
      },
      color: "#6D788D",
    },
  };
  // const darkTheme = createTheme({ palette: { mode: 'dark' } });
  // eslint-disable-next-line no-unused-vars
  const lightTheme = createTheme({ palette: { mode: "light" } });

  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    price: 0,
  });
  // eslint-disable-next-line no-unused-vars
  const { id, price } = formData;
  // eslint-disable-next-line no-unused-vars
  const containerName = data.dockerContainerID;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (e) => {
    formData.id = note.id - 1;
    try {
      if (DisplayBalance >= ethers.utils.formatEther(note.price)) {
        formData.price = ethers.utils.formatEther(note.price);

        setOpen(false);
        await buyTradingBot(formData.id, account, formData.price);
        navigate("/viewownprofile");
      } else if (DisplayBalance < ethers.utils.formatEther(note.price)) {
        setOpen(false);
      }
    } catch (error) {}
  };
  // Got Id and Price
  const [priceData, setPriceData] = useState();
  const getPrice = (e) => setPriceData(e.target.value);

  const onConfirm = async (e) => {
    try {
      console.log("prices", priceData);

      setOpen(false);
      await listBotOnSale(userID - 1, priceData, account);
      await stopBot();

      await disConnectBinanceCredential(
        account,
        data.binanceCredentialID,
        tokens
      );

      navigate("/viewownprofile");
    } catch (err) {
      console.log(err);
    }
  };
  // console.log(containerName, "print Data");

  async function startBot() {
    await startContainer(containerName);
    setFetchCount((prevCount) => prevCount + 1);
  }

  async function stopBot() {
    await stopContainer(containerName);
    setFetchCount((prevCount) => prevCount + 1);
  }

  // getContainerStatus with useState and useEffect

  const [fetchCount, setFetchCount] = useState(0);

  const [containerStatus, setContainerStatus] = useState("");
  useEffect(() => {
    const fetchContainerStatus = async () => {
      try {
        const res = await getContainerStatus(containerName);
        if (res) {
          setContainerStatus(res);
          console.log(res, "res res");
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (containerName) {
      fetchContainerStatus();
    }
  }, [fetchCount, containerName]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Position Table Props
  const positionColums = [
    "Symbol",
    "Size",
    "Entry Price",
    "Mark Price",
    "Liq.Price",

    "unRealizedProfit",
  ];
  // solve the problem of undefined data
  if (!Array.isArray(positionData)) {
    return null; // or some other fallback
  }
  const requiredDataPosition = positionData.map((item) => {
    return {
      Symbol: item.symbol,
      Size: item.positionAmt,
      "Entry Price": item.entryPrice,
      "Mark Price": item.markPrice,
      "Liq.Price": item.liquidationPrice,

      unRealizedProfit: item.unRealizedProfit,
    };
  });

  // Trading History Table Props
  const tradingHistoryColums = [
    "Time",
    "Symbol",

    "Side",

    "Price",
    "commission",
    "realizedPnl",
  ];
  // solve the problem of undefined data
  if (!Array.isArray(tradeHistoryData)) {
    return null; // or some other fallback
  }
  const requiredDataTradingHistory = tradeHistoryData
    .filter(filterData)
    .map((item) => {
      return {
        Time: formatDate(item.time),
        Symbol: item.symbol,
        Side: item.side,

        Price: item.price,

        commission: item.commission + item.commissionAsset,
        realizedPnl: item.realizedPnl,
      };
    });

  const dataLineChart = tradeHistoryData.filter(filterData).map((item) => {
    return {
      Time: formatDate(item.time),
      realizedPnl: item.realizedPnl,
    };
  });
  // Accout Information Table Props
  const accountInformationColums = [
    "availableBalance",
    "totalWalletBalance",
    "totalCrossUnPnl",
    "totalCrossWalletBalance",
  ];
  const dataAccountInformation = [
    {
      availableBalance: accountInformationData.availableBalance,
      totalWalletBalance: accountInformationData.totalWalletBalance,
      totalCrossUnPnl: accountInformationData.totalCrossUnPnl,
      totalCrossWalletBalance: accountInformationData.totalCrossWalletBalance,
    },
  ];
  // textfield input props
  return (
    <div>
      <Paper
        sx={{
          p: 3,
          mt: 5,
          background: "transparent",
        }}
      >
        <Paper
          sx={{
            p: 2,

            margin: "auto",
            maxWidth: 1000,
            background: "transparent",
            border: "solid",
            borderColor: "black",
            borderWidth: 1,

            borderRadius: 3,
            // backgroundColor:"black",
          }}
        >
          <Grid container spacing={2} sx={{ backgroundColor: "transparent" }}>
            <Grid item>
              <ButtonBase sx={{ width: 350, height: 120 }}>
                {/* <Img alt="complex" src={props.image} /> */}
                <Avatar sx={{ width: 120, height: 120 }} src={data.image} />
                <Typography
                  sx={{
                    cursor: "pointer",
                    fontWeight: 50,
                    fontSize: 15,
                    ml: 2,
                  }}
                  variant="body2"
                  color="#A6A6A6"
                >
                  Bot Name :{data.displayName}
                </Typography>
              </ButtonBase>
              {/* <Button onClick={checkauthaddress}>Test</Button> */}
            </Grid>
            <Grid item xs={12} sm container>
              <Grid
                item
                xs
                container
                direction="column"
                spacing={1}
                color="#A6A6A6"
              >
                <Grid item>
                  <Typography
                    sx={{ mt: 6, ml: 5, fontWeight: 100, fontSize: 15 }}
                    variant="body2"
                  >
                    {moment.unix(Number(data.timeStamp)).format("L")}
                  </Typography>
                </Grid>
              </Grid>
              {containerStatus === "running" && (
                <Typography
                  sx={{ mt: 6, ml: 10, fontWeight: 20, color: "green" }}
                  variant="body2"
                >
                  Bot is Running
                </Typography>
              )}
              {containerStatus === "exited" && (
                <Typography
                  sx={{ mt: 6, ml: 10, fontWeight: 10, color: "red" }}
                  variant="body2"
                >
                  Bot is Stopped
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ color: "white" }}>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2} pt={3}>
                <Grid item>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    component="div"
                    color="#A6A6A6"
                  >
                    Bot Details
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ color: "#A6A6A6", mt: 2 }}
                  >
                    {data.description}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    component="div"
                    color="#A6A6A6"
                  >
                    Price
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ color: "#A6A6A6", mt: 2 }}
                  >
                    {data.price} Ether
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    gutterBottom
                    variant="subtitle1"
                    component="div"
                    color="#A6A6A6"
                  >
                    container ID
                  </Typography>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ color: "#A6A6A6", mt: 2 }}
                  >
                    {data.dockerContainerID}
                  </Typography>
                </Grid>
                <Grid>
                  {/* Form for user input price */}
                  {data.OwnerAddress === account && (
                    <Dialog open={open} onClose={handleClose}>
                      <DialogTitle>Put bot on sale</DialogTitle>
                      <DialogContent>
                        <DialogContentText></DialogContentText>
                        <TextField
                          id="filled-basic"
                          label="Price"
                          variant="filled"
                          color="secondary"
                          focused
                          helperText="Please input price to sale"
                          name="price"
                          onChange={getPrice}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={onConfirm}
                        >
                          {" "}
                          Sale
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                  {data.OwnerAddress !== account && (
                    <Dialog open={open} onClose={handleClose}>
                      <Box
                        style={{
                          width: 400,
                          height: 220,
                          background: "#302034",
                        }}
                      >
                        <DialogTitle color="#ededed">
                          Buy Trading Bot
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText
                            color="#ededed"
                            sx={{ fontSize: 15 }}
                          >
                            Comnfirm Payment
                          </DialogContentText>
                          <DialogContentText
                            color="#b28900"
                            sx={{ fontSize: 40 }}
                          >
                            {/* {ethers.utils.formatEther(props.price)} ETH */}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions
                          sx={{
                            background: "#302034",
                          }}
                        >
                          <Button
                            sx={{
                              ":hover": {
                                color: "#12db00",
                              },
                            }}
                            onClick={handleClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            sx={{
                              ":hover": {
                                color: "#12db00",
                              },
                            }}
                            onClick={onSubmit}
                          >
                            Ok
                          </Button>
                        </DialogActions>
                      </Box>
                    </Dialog>
                  )}
                </Grid>
                {/* Form for buy the bot */}

                <Box sx={{ "& button": { m: 1 } }}>
                  <div>
                    {data.isSaleBot === true &&
                    data.OwnerAddress !== account ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        maxwidth="500"
                        sx={{ fontSize: 15 }}
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        BUY
                      </Button>
                    ) : (
                      <></>
                    )}
                    {isowner === false ? (
                      <Box sx={{ width: "500" }}>
                        <div>
                          {containerStatus === "running" && (
                            <Button
                              variant="contained"
                              color="warning"
                              size="small"
                              sx={{ fontSize: 15, ml: 2 }}
                              onClick={stopBot}
                            >
                              Stop
                            </Button>
                          )}
                          {containerStatus === "created" && (
                            <Button
                              variant="contained"
                              color="warning"
                              size="small"
                              sx={{ fontSize: 15 }}
                              onClick={startBot}
                            >
                              Run
                            </Button>
                          )}
                          {containerStatus === "exited" && (
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              sx={{ fontSize: 15 }}
                              onClick={startBot}
                            >
                              run
                            </Button>
                          )}
                          <Button
                            href={`/updatetradingbot/` + data.id}
                            variant="contained"
                            color="secondary"
                            size="small"
                            sx={{ fontSize: 15 }}
                          >
                            Edit
                          </Button>
                          <Button
                            // href="botforsale"
                            disabled={isowner}
                            variant="contained"
                            color="secondary"
                            size="small"
                            sx={{ fontSize: 15 }}
                            onClick={handleClickOpen}
                          >
                            list
                          </Button>
                        </div>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
      {data.OwnerAddress === account && (
        <div>
          {apiKey === "" ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress />
            </Box>
          ) : (
            <div>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", color: "white" }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Account InFormation"
                    {...a11yProps(0)}
                    style={{ color: "white" }}
                  />

                  <Tab
                    label="Position"
                    {...a11yProps(1)}
                    style={{ color: "white" }}
                  />
                  <Tab
                    label="Trading History"
                    {...a11yProps(2)}
                    style={{ color: "white" }}
                  />
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <InformationBotCard
                  columns={accountInformationColums}
                  data={dataAccountInformation}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <InformationBotCard
                  columns={positionColums}
                  data={requiredDataPosition}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Box sx={{ m: 10 }}>
                  <TextField
                    type="date"
                    label="Start date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={textfieldCustom}
                    value={
                      filter.startDate === "" || filter.startDate === null
                        ? ""
                        : filter.startDate.toISOString().substr(0, 10)
                    }
                    onChange={handleStartDateChange}
                  />
                  <TextField
                    type="date"
                    label="End date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={textfieldCustom}
                    value={
                      filter.endDate === "" || filter.endDate === null
                        ? ""
                        : filter.endDate.toISOString().substr(0, 10)
                    }
                    onChange={handleEndDateChange}
                  />
                  <CustomButton variant="contained" onClick={handleTodayClick}>
                    Today
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    onClick={handleLastWeekClick}
                  >
                    Last week
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    onClick={handleLastMonthClick}
                  >
                    Last month
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    onClick={handleLastThreeMonthsClick}
                  >
                    Last year
                  </CustomButton>
                  <CustomButton
                    variant="contained"
                    onClick={handleShowAllClick}
                  >
                    Show All
                  </CustomButton>
                  {openChart && (
                    <CustomButton
                      variant="contained"
                      onClick={() => setOpenChart(false)}
                    >
                      Open Table
                    </CustomButton>
                  )}
                  {!openChart && (
                    <CustomButton
                      variant="contained"
                      onClick={() => setOpenChart(true)}
                    >
                      Open Graph
                    </CustomButton>
                  )}
                  {openChart ? (
                    <RechartsLineChart data={dataLineChart} />
                  ) : (
                    <InformationBotCard
                      columns={tradingHistoryColums}
                      data={requiredDataTradingHistory}
                    />
                  )}
                </Box>
              </TabPanel>
            </div>
          )}
        </div>
      )}

      {data.OwnerAddress !== account && (
        <div>
          {apiKey === "" ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ m: 10 }}>
              <TextField
                type="date"
                label="Start date"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={textfieldCustom}
                value={
                  filter.startDate === "" || filter.startDate === null
                    ? ""
                    : filter.startDate.toISOString().substr(0, 10)
                }
                onChange={handleStartDateChange}
              />
              <TextField
                type="date"
                label="End date"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={textfieldCustom}
                value={
                  filter.endDate === "" || filter.endDate === null
                    ? ""
                    : filter.endDate.toISOString().substr(0, 10)
                }
                onChange={handleEndDateChange}
              />
              <CustomButton variant="contained" onClick={handleTodayClick}>
                Today
              </CustomButton>
              <CustomButton variant="contained" onClick={handleLastWeekClick}>
                Last week
              </CustomButton>
              <CustomButton variant="contained" onClick={handleLastMonthClick}>
                Last month
              </CustomButton>
              <CustomButton
                variant="contained"
                onClick={handleLastThreeMonthsClick}
              >
                Last year
              </CustomButton>
              <CustomButton variant="contained" onClick={handleShowAllClick}>
                Show All
              </CustomButton>
              <InformationBotCard
                columns={tradingHistoryColums}
                data={requiredDataTradingHistory}
              />
            </Box>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewBotDetails;
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
