import {
  createTheme,
  IconButton,
  ThemeProvider,
  Typography,
  InputBase,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import React from "react";
import Paper from "@mui/material/Paper";
import Cardalltopcreator from "./Cardalltopcreator";

import { connect } from "react-redux";
const theme = createTheme({
  // Tell MUI what the font-size on the html element is.
  typography: {
    subtitle1: {
      fontSize: 33,
      fontFamily: "Montserrat",
      fontWeight: 300,
      color: "#F5FBF2",
      letterSpacing: "0.0075em",
      verticalAlign: "middle",
      alignItems: "center",
      textAlign: "center",
      main: "#F5FBF2",
      marginTop: 20,
    },
  },
});
// eslint-disable-next-line no-unused-vars
const dumydata = [
  {
    image:
      "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEimKJOmH-GNZZMqb_U_AGt1Jcugz22S3nhhotXk5UeX4knecLKWETU-JxN5b_sBRdW6E5fY8gVgTjjb8Zg9YDUGtmoGtlPK4F36k8Doea6GS0kTJqjOtimAEJqPyyOscx7f1KAiL-X-11pMVu-J3atU5QDNqQX6w2kEl-4_I3pXAIq2pDxsImjIkubn_A/s55/averta-image.png",
    name: "Wzard Dee",
    price: "4.89 ETH",
  },
  {
    image:
      "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEimKJOmH-GNZZMqb_U_AGt1Jcugz22S3nhhotXk5UeX4knecLKWETU-JxN5b_sBRdW6E5fY8gVgTjjb8Zg9YDUGtmoGtlPK4F36k8Doea6GS0kTJqjOtimAEJqPyyOscx7f1KAiL-X-11pMVu-J3atU5QDNqQX6w2kEl-4_I3pXAIq2pDxsImjIkubn_A/s55/averta-image.png",
    name: "Wzard Dee",
    price: "4.89 ETH",
  },
  {
    image:
      "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEimKJOmH-GNZZMqb_U_AGt1Jcugz22S3nhhotXk5UeX4knecLKWETU-JxN5b_sBRdW6E5fY8gVgTjjb8Zg9YDUGtmoGtlPK4F36k8Doea6GS0kTJqjOtimAEJqPyyOscx7f1KAiL-X-11pMVu-J3atU5QDNqQX6w2kEl-4_I3pXAIq2pDxsImjIkubn_A/s55/averta-image.png",
    name: "Wzard Dee",
    price: "4.89 ETH",
  },
  {
    image:
      "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEimKJOmH-GNZZMqb_U_AGt1Jcugz22S3nhhotXk5UeX4knecLKWETU-JxN5b_sBRdW6E5fY8gVgTjjb8Zg9YDUGtmoGtlPK4F36k8Doea6GS0kTJqjOtimAEJqPyyOscx7f1KAiL-X-11pMVu-J3atU5QDNqQX6w2kEl-4_I3pXAIq2pDxsImjIkubn_A/s55/averta-image.png",
    name: "Wzard Dee",
    price: "4.89 ETH",
  },
  {
    image:
      "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEimKJOmH-GNZZMqb_U_AGt1Jcugz22S3nhhotXk5UeX4knecLKWETU-JxN5b_sBRdW6E5fY8gVgTjjb8Zg9YDUGtmoGtlPK4F36k8Doea6GS0kTJqjOtimAEJqPyyOscx7f1KAiL-X-11pMVu-J3atU5QDNqQX6w2kEl-4_I3pXAIq2pDxsImjIkubn_A/s55/averta-image.png",
    name: "Wzard Dee",
    price: "4.89 ETH",
  },
];

class TopCreator extends React.Component {
  state = {
    text: "Theanlay acc",
  };
  changeaccounttext = () => {
    this.props.changeaccount(this.state.text);
    this.props.history.push("/alltopcreator");
  };

  render() {
    // const { text } = this.state;

    return (
      <div>
        <ThemeProvider theme={theme}>
          <Typography variant="subtitle1">All top Creator</Typography>
          {/* <Typography variant='subtitle1'>{this.props.posts[0].title}</Typography>
            <Typography variant='subtitle1'>{this.props.account}</Typography>
            <Button onClick={this.changeaccounttext}> Change Text</Button> */}
        </ThemeProvider>
        {/* Search  */}
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            margin: "auto",
            width: 800,
          }}
        >
          <IconButton sx={{ p: "10px" }} aria-label="menu">
            <MenuIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search "
            inputProps={{ "aria-label": "search google maps" }}
          />
          <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>

          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        </Paper>
        {/* End Search */}
        {/* card  */}
        <Paper
          sx={{
            p: 2,
            margin: "auto",
            mt: 2,
            backgroundColor: "GrayText",
          }}
        >
          <Cardalltopcreator />
        </Paper>
        {/* end card */}
      </div>
    );
  }
}

// pass data from redux center into class compoenet
const mapStateToProps = (state) => {
  // eslint-disable-next-line no-unused-vars
  let textchange = "this is an apple";
  return {
    posts: state.post,
    account: state.account,
  };
};

//dispatch action to change in redux

// create changeaccount action and textchange as the text change parameter to change value of acc

const mapDispatchToProps = (dispatch) => {
  return {
    changeaccount: (textchange) => {
      dispatch({ type: "Change_Acc", text: textchange });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopCreator);
