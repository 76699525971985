import React from "react";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
export default function skeletioncard() {
  return (
    <Card
      style={{
        color: "#FFFFFF",
        backgroundColor: "#3D2942",
        textAlign: "left",
      }}
      sx={{
        maxWidth: 395,
        fontWeight: "bold",
        fontSize: 16,
        borderRadius: "16px",
        ml: 8,
        mt: 3,
      }}
    >
      <Skeleton
        variant="rectangular"
        width={395}
        height={340}
        borderradius={16}
      />
      <Box m={1}>
        <Stack direction="row" justifyContent="space-between" pt={1} pb={1}>
          <Skeleton variant="circular" width={64} height={64} />
          {/* < Skeleton width={307} height={40} /> */}
        </Stack>
        <Stack direction="row" justifyContent="space-between" pt={1} pb={1}>
          <Skeleton width={70} height={30} />
          <Skeleton width={70} height={30} />
        </Stack>
        <Skeleton width={200} height={30} />
        <Stack justifyContent="center" alignItems="center" pt={1} pb={1}>
          <Skeleton width={350} height={50} />
        </Stack>
      </Box>
    </Card>
  );
}
