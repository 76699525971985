import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CardFeature from "./CardFeature";
import wallet from "../../../assets/image/secured_wallet.png";
import createList from "../../../assets/image/create_listing.png";
import searchingItem from "../../../assets/image/searching_for_items.png";
import marketplace from "../../../assets/image/market_place.png";
import filter from "../../../assets/image/filters.png";
import searchItem from "../../../assets/image/search_for_items.png";
import { SectionTitle } from "../../sectionTitle/sectionTitle";
const data = [
  {
    img: wallet,
    title: "Secured Wallet",
    description:
      " Specifically designed to securely store and manage cryptocurrencies and other digital assets",
  },
  {
    img: createList,
    title: "Create listings",
    description:
      "Attractive to create and list trading bot in market and market trades allow users to buy and sell",
  },
  {
    img: searchingItem,
    title: "Buy and Bid",
    description:
      "User can implement various search functionalities to help users find specific owner of trading bots.",
  },
  {
    img: marketplace,
    title: "Market Place",
    description:
      "Designed to list bots, sale bot relevant to their operation on the specific marketplace utilized",
  },
  {
    img: filter,
    title: "Filters",
    description:
      "Represent market trading bots in limit filters view an important in the functionality of a trading bot",
  },
  {
    img: searchItem,
    title: "Searching for items",
    description:
      " Cryptorio provide coin exchanges in multiple currencies including USDT,USD,EUR,GBP,JPD",
  },
];

const theme = createTheme({
  // Tell MUI what the font-size on the html element is.
  typography: {
    subtitle1: {
      fontSize: 33,
      fontFamily: "Montserrat",
      fontWeight: 300,
      color: "#F5FBF2",
      letterSpacing: "0.0075em",
      verticalAlign: "middle",
      alignItems: "center",
      textAlign: "center",
      main: "#F5FBF2",
    },
  },
});
const Features = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box m={5}>
        <div style={{ textAlign: "center" }}>
          <SectionTitle isCenter={true} subtitle="Our Features" />
        </div>
        <Grid
          p={3}
          container
          spacing={20}
          alignItems="center"
          justifyContent="center"
        >
          {data.map((d, index) => {
            return (
              <Grid key={index} item>
                <CardFeature {...d} color="white" />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};
export default Features;
