import {
  Avatar,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
const Cardgridtopcretor = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <CardActionArea
        onClick={() => {
          navigate(`/viewotherprofile/` + props._id);
        }}
        style={{
          color: "#FFFFFF",
          backgroundColor: "#3D2942",
          textAlign: "left",
        }}
        sx={{
          width: 350,
          fontWeight: "bold",
          fontSize: 16,
          borderRadius: "16px",
          boxShadow: 4,
        }}
      >
        <CardHeader
          avatar={<Avatar src={props.image}></Avatar>}
          titleTypographyProps={{ variant: "h8" }}
          title={props.name}
        />
        <CardContent>
          <Typography variant="body2" color="#B6B6BE">
            {props.bio}
          </Typography>
        </CardContent>
        <CardActions>
          <Typography sx={{ ml: 1 }} color="#A7A7BA">
            {props.role}
          </Typography>
        </CardActions>
      </CardActionArea>
    </div>
  );
};
export default Cardgridtopcretor;
