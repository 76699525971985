import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { purple, yellow } from "@mui/material/colors";
// Create a custom theme
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),

  padding: "10px 20px",
  margin: "10px 0 5px",
  borderRadius: "5px",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: yellow[900],
  },
}));

const CustomButton = ({ onClick, children }) => {
  return <ColorButton onClick={onClick}>{children}</ColorButton>;
};

export default CustomButton;
