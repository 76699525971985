import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const ProtectedRoute = () => {
  const [open] = useState(true);
  let [isAuth] = useState(false);
  // const closing = () => {
  //   setOpen(false);
  // };
  const navigate = useNavigate();
  let token = window.localStorage.getItem("token");
  // Check if token exists and is not expired
  if (token && token !== 'undefined') {
    // Token is valid
    isAuth = true;
  }
  return (isAuth) ? (
    <Outlet />
  ) : (
    <div>
      <Dialog open={open}>
        <DialogTitle>Please connect your wallet</DialogTitle>
  
        <DialogActions>
          <Button
            onClick={() => {
              navigate("/");
            }}
            variant="contained"
            color="secondary"
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ProtectedRoute;



// const ProtectedRoute = ({ children }) => {
//   const [open] = useState(true);
//   let [isAuth] = useState(false);
//   const navigate = useNavigate();
//   let token = window.localStorage.getItem("token");
//   // Check if token exists and is not expired
//   if (token && token !== 'undefined') {
//     // Token is valid
//     isAuth = true;
//   }else {
//     // Token is invalid or doesn't exist, redirect to login
//     isAuth = false;
//     navigate("/");
//   }
//   return (
//     <>
//       {isAuth ? (
//         children
//       ) : (
//         <div>
//           <Dialog open={open}>
//             <DialogTitle>Please connect your wallet</DialogTitle>

//             <DialogActions>
//               <Button
//                 onClick={() => {
//                   navigate("/");
//                 }}
//                 variant="contained"
//                 color="secondary"
//               >
//                 Okay
//               </Button>
//             </DialogActions>
//           </Dialog>
//         </div>
//       )}
//     </>
//   );
// };
// export default ProtectedRoute;
