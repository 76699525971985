import TradingBot from "./TradingBot";
import web3 from "../../web3";
// import Narbar from "../../components/Navbar/Navbar";

const Bot = async () => {
  const manager = await TradingBot.methods.manager().call();
  return <h2>{manager}</h2>;
};

export default Bot;

export async function getBotById(id) {
  const bot = await TradingBot.methods.Bots(id - 1).call();
  return bot;
}

export async function createBot(
  displayName,
  binanceCredentialID,
  image,
  description,
  dockerImageID,
  dockerContainerID,
  creatorAddress
) {
  // eslint-disable-next-line no-unused-vars
  const createBot = await TradingBot.methods
    .createBot(
      displayName,
      binanceCredentialID,
      image,
      description,
      dockerImageID,
      dockerContainerID
    )
    .send({ from: creatorAddress });
  return createBot;
}

export async function updateBot(
  id,
  new_displayName,
  new_image,
  new_desription,
  newDockerContainerID,
  newBinanceCredentialID,
  senderAddress
) {
  // eslint-disable-next-line no-unused-vars
  const updateBot = await TradingBot.methods
    .updateBot(id, new_displayName, new_image, new_desription, newDockerContainerID, newBinanceCredentialID)
    .send({
      from: senderAddress,
    });
}

export async function listBotOnSale(id, price, address) {
  // eslint-disable-next-line no-unused-vars
  const toWei = web3.utils.toWei(price, "ether"); // convert the price from ether to wei
  const botOnSale = await TradingBot.methods
    .sellBot(id, toWei)
    .send({ from: address });
  console.log(botOnSale);
}
export async function buyTradingBot(botId, newDockerContainerID, newBinanceCredentialID, buyerAddress, value) {
  // eslint-disable-next-line no-unused-vars
  const toWei = web3.utils.toWei(value, "ether"); // convert the price from ether to wei
  const res = await TradingBot.methods.buyTradingBotById(botId, newDockerContainerID, newBinanceCredentialID).send({
    from: buyerAddress,
    gas: "1000000",
    value: toWei,
    //   value: "1000000000000000000",
  });
  console.log(res);
}

export async function getAllBots() {
  var allBotsList = [];
  var len = await TradingBot.methods.botCount().call();
  for (let i = 0; i < len; i++) {
    const data = await TradingBot.methods.Bots(i).call();
    var bot = {
      id: data.id,
      displayName: data.displayName,
      binanceCredentialID: data.binanceCredentialID,
      image: data.image,
      description: data.description,
      dockerContainerID: data.dockerContainerID,
      dockerImageID: data.dockerImageID,
      OwnerAddress: data.OwnerAddress,
      CreatorAddress: data.CreatorAddress,
      price: data.price,
      isSaleBot: data.isSaleBot,
      timeStamp: data.timeStamp,
    };
    allBotsList.push(bot);
  }
  return allBotsList;
}

export async function allSaleBot() {
  var saleBotsList = [];
  var len = await TradingBot.methods.botCount().call();

  for (let i = 0; i < len; i++) {
    const data = await TradingBot.methods.Bots(i).call();
    if (data.isSaleBot) {
      var bot = {
        id: data.id,
        displayName: data.displayName,
        binanceCredentialID: data._binanceCredentialID,
        image: data.image,
        description: data.description,
        dockerContainerID: data.dockerContainerID,
        dockerImageID: data.dockerImageID,
        OwnerAddress: data.OwnerAddress,
        CreatorAddress: data.CreatorAddress,
        price: data.price,
        isSaleBot: data.isSaleBot,
        timeStamp: data.timeStamp,
      };
      saleBotsList.push(bot);
    }
  }
  return saleBotsList;
}

export async function getAllBotsByOwner(address) {
  var allBotIds = await TradingBot.methods.getAllBotsByOwner(address).call();
  var ownerAllBots = [];
  for (let i = 0; i < allBotIds.length; i++) {
    const data = await TradingBot.methods.Bots(allBotIds[i]).call();
    if (address === data.OwnerAddress) {
      var bot = {
        id: data.id,
        displayName: data.displayName,
        binanceCredentialID: data.binanceCredentialID,
        image: data.image,
        description: data.description,
        dockerContainerID: data.dockerContainerID,
        dockerImageID: data.dockerImageID,
        OwnerAddress: data.OwnerAddress,
        CreatorAddress: data.CreatorAddress,
        price: data.price,
        isSaleBot: data.isSaleBot,
        timeStamp: data.timeStamp,
      };
      ownerAllBots.push(bot);
    }
  }
  return ownerAllBots;
}
// return creator's all bot info
export async function getAllBotsByCreator(address) {
  var allBotIds = await TradingBot.methods.getAllBotsByCreator(address).call();
  var creatorAllBots = [];
  for (let i = 0; i < allBotIds.length; i++) {
    const data = await TradingBot.methods.Bots(allBotIds[i]).call();
    if (address === data.CreatorAddress) {
      var bot = {
        id: data.id,
        displayName: data.displayName,
        binanceCredentialID: data.binanceCredentialID,
        image: data.image,
        description: data.description,
        dockerContainerID: data.dockerContainerID,
        dockerImageID: data.dockerImageID,
        OwnerAddress: data.OwnerAddress,
        CreatorAddress: data.CreatorAddress,
        price: data.price,
        isSaleBot: data.isSaleBot,
        timeStamp: data.timeStamp,
      };
      creatorAllBots.push(bot);
    }
  }
  return creatorAllBots;
}

// return owner's all sale bot info
export async function getAllSaleBotsByOwner(address) {
  var allBotIds = await TradingBot.methods
    .getAllSaleBotsByOwner(address)
    .call();
  var ownerAllSaleBots = [];
  for (let i = 0; i < allBotIds.length; i++) {
    const data = await TradingBot.methods.Bots(allBotIds[i]).call();
    if (address === data.OwnerAddress && data.isSaleBot === true) {
      var bot = {
        id: data.id,
        displayName: data.displayName,
        binanceCredentialID: data.binanceCredentialID,
        image: data.image,
        description: data.description,
        dockerContainerID: data.dockerContainerID,
        dockerImageID: data.dockerImageID,
        OwnerAddress: data.OwnerAddress,
        CreatorAddress: data.CreatorAddress,
        price: data.price,
        isSaleBot: data.isSaleBot,
        timeStamp: data.timeStamp,
      };
      ownerAllSaleBots.push(bot);
    }
  }
  return ownerAllSaleBots;
}
