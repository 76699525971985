import axios from "axios";


// get all creators
export async function getAllCreator() {
  try {
    const data = await axios.get(
      `https://trading-bot-api.vercel.app/api/user/creator`
    );
    if (data.status === 200) {
      const allCreators = data.data;
      return allCreators;
    }
  } catch (err) {
    console.log(err);
  }
}
