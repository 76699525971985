import web3 from "../../web3.js";
const address = "0x911FFdFB097f1Dc17F48998ebE4e0c8a01f4bCA0"; // Sepolia Testnet deployed by Huameng on May 19 2023
// const address = "0x58550B19C2952d6baaB2BC091bCB2924a9f52b9A"; // Sepolia Testnet deployed by Huameng on May 04 2023

// const address = "0x3C0830Dbe7fa56d22E0B24330742180B8BAF478c";
// const address = "0xE38367E065ca300FB2Fe13c7B50e2E982d530506";

// const address = "0xE38367E065ca300FB2Fe13c7B50e2E982d530506";

const abi =
	[
		{
			"inputs": [],
			"stateMutability": "nonpayable",
			"type": "constructor"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "id",
					"type": "uint256"
				}
			],
			"name": "BuyTradingBot",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "id",
					"type": "uint256"
				},
				{
					"internalType": "string",
					"name": "new_dockerContainerID",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "new_binanceCredentialID",
					"type": "string"
				}
			],
			"name": "buyTradingBotById",
			"outputs": [],
			"stateMutability": "payable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "string",
					"name": "_displayName",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "_binanceCredentialID",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "_image",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "_description",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "_dockerImageID",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "_dockerContainerID",
					"type": "string"
				}
			],
			"name": "createBot",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "id",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "displayName",
					"type": "string"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "binanceCredentialID",
					"type": "string"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "image",
					"type": "string"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "description",
					"type": "string"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "dockerImageID",
					"type": "string"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "dockerContainerID",
					"type": "string"
				},
				{
					"indexed": false,
					"internalType": "address payable",
					"name": "OwnerAddress",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "address payable",
					"name": "CreatorAddress",
					"type": "address"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "price",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "timeStamp",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "bool",
					"name": "isSaleBot",
					"type": "bool"
				}
			],
			"name": "CreateTradingBot",
			"type": "event"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "Id",
					"type": "uint256"
				},
				{
					"internalType": "uint256",
					"name": "_price",
					"type": "uint256"
				}
			],
			"name": "sellBot",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "_id",
					"type": "uint256"
				},
				{
					"internalType": "string",
					"name": "new_displayName",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "new_image",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "new_description",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "new_containerID",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "new_binanceCredentialID",
					"type": "string"
				}
			],
			"name": "updateBot",
			"outputs": [],
			"stateMutability": "nonpayable",
			"type": "function"
		},
		{
			"anonymous": false,
			"inputs": [
				{
					"indexed": false,
					"internalType": "uint256",
					"name": "id",
					"type": "uint256"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "displayName",
					"type": "string"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "new_image",
					"type": "string"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "new_description",
					"type": "string"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "new_containerID",
					"type": "string"
				},
				{
					"indexed": false,
					"internalType": "string",
					"name": "new_binanceCredentialID",
					"type": "string"
				}
			],
			"name": "UpdateTradingBot",
			"type": "event"
		},
		{
			"inputs": [],
			"name": "botCount",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"name": "Bots",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "id",
					"type": "uint256"
				},
				{
					"internalType": "string",
					"name": "displayName",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "binanceCredentialID",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "image",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "description",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "dockerImageID",
					"type": "string"
				},
				{
					"internalType": "string",
					"name": "dockerContainerID",
					"type": "string"
				},
				{
					"internalType": "address payable",
					"name": "OwnerAddress",
					"type": "address"
				},
				{
					"internalType": "address payable",
					"name": "CreatorAddress",
					"type": "address"
				},
				{
					"internalType": "uint256",
					"name": "price",
					"type": "uint256"
				},
				{
					"internalType": "uint256",
					"name": "timeStamp",
					"type": "uint256"
				},
				{
					"internalType": "bool",
					"name": "isSaleBot",
					"type": "bool"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "account",
					"type": "address"
				}
			],
			"name": "checkBalance",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_creator",
					"type": "address"
				}
			],
			"name": "getAllBotsByCreator",
			"outputs": [
				{
					"internalType": "uint256[]",
					"name": "",
					"type": "uint256[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_owner",
					"type": "address"
				}
			],
			"name": "getAllBotsByOwner",
			"outputs": [
				{
					"internalType": "uint256[]",
					"name": "",
					"type": "uint256[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "getAllSaleBots",
			"outputs": [
				{
					"internalType": "uint256[]",
					"name": "",
					"type": "uint256[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "address",
					"name": "_owner",
					"type": "address"
				}
			],
			"name": "getAllSaleBotsByOwner",
			"outputs": [
				{
					"internalType": "uint256[]",
					"name": "",
					"type": "uint256[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "getAllSales",
			"outputs": [
				{
					"internalType": "uint256[]",
					"name": "",
					"type": "uint256[]"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [
				{
					"internalType": "uint256",
					"name": "id",
					"type": "uint256"
				}
			],
			"name": "getBotById",
			"outputs": [
				{
					"components": [
						{
							"internalType": "uint256",
							"name": "id",
							"type": "uint256"
						},
						{
							"internalType": "string",
							"name": "displayName",
							"type": "string"
						},
						{
							"internalType": "string",
							"name": "binanceCredentialID",
							"type": "string"
						},
						{
							"internalType": "string",
							"name": "image",
							"type": "string"
						},
						{
							"internalType": "string",
							"name": "description",
							"type": "string"
						},
						{
							"internalType": "string",
							"name": "dockerImageID",
							"type": "string"
						},
						{
							"internalType": "string",
							"name": "dockerContainerID",
							"type": "string"
						},
						{
							"internalType": "address payable",
							"name": "OwnerAddress",
							"type": "address"
						},
						{
							"internalType": "address payable",
							"name": "CreatorAddress",
							"type": "address"
						},
						{
							"internalType": "uint256",
							"name": "price",
							"type": "uint256"
						},
						{
							"internalType": "uint256",
							"name": "timeStamp",
							"type": "uint256"
						},
						{
							"internalType": "bool",
							"name": "isSaleBot",
							"type": "bool"
						}
					],
					"internalType": "struct TradingBot.Bot",
					"name": "",
					"type": "tuple"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "manager",
			"outputs": [
				{
					"internalType": "address payable",
					"name": "",
					"type": "address"
				}
			],
			"stateMutability": "view",
			"type": "function"
		},
		{
			"inputs": [],
			"name": "nextTradingBotId",
			"outputs": [
				{
					"internalType": "uint256",
					"name": "",
					"type": "uint256"
				}
			],
			"stateMutability": "view",
			"type": "function"
		}
	]

export default new web3.eth.Contract(abi, address);
