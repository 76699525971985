import {
  Button,
  Container,
  createTheme,
  MenuItem,
  Select,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";

import { createBot } from "../../services/smart contract/Smartcontract";
import React, { useState, useEffect } from "react";
import ImageUploading from "react-images-uploading";
import imagePlaceholder from "../../assets/image/image_placeholder.png";
// import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
// import { storage } from "../../config/firebase";
import { storeFirebaseImage } from "../../services/ipfs";
import { updateUserRoleById, getOwnUserDetails, connectBinanceCredential} from "../../services/api";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { createContainer,buildImage } from "../../services/dockerApi";

// import { useRouter } from 'next/router';
import CircularProgress from "@mui/material/CircularProgress";
const CreateTradingbot = () => {
  // get input img
  const [image, setImage] = useState(null);
  // const [user, setUser] = useState([]);
  const [data, setData] = useState([]);

  const tokens = window.localStorage.getItem("token");
  const account = window.localStorage.getItem("useraddress");
  const github_token = window.localStorage.getItem("github_accessToken");

  const onImagechange = (img) => {
    setImage(img);
    // let fileimage = image[0]
  };

  //creating a refernce for TextField Component
  const [displayName, setName] = useState(" ");
  const [branch, setBranch] = useState("");
  const [description, setDescription] = useState("");
  // const [expose_port, setExposePort] = useState("");
  // const [running_port, setRunningPort] = useState("");
  const [selectedAPI, setSelectedAPI] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  const navigate = useNavigate();
  // //eslint-disable-next-line no-unused-vars
  // const botRunningPortChange = (e) => {
  //   setRunningPort(e.target.value);
  // };
  // //eslint-disable-next-line no-unused-vars
  // const botExposePortChange = (e) => {
  //   setExposePort(e.target.value);
  // };

  const nameChange = (e) => {
    setName(e.target.value);
  };

  const setbranch = (e) => {
    setBranch(e.target.value);
  };

  // const serverinkChange = (e) => {
  //   setServer(e.target.value);
  // };

  const descriptionChange = (e) => {
    setDescription(e.target.value);
  };
  // const handleSelectChange = (selected) => {
  //   setSelectedAPI(selected);
  // };
const SelectedAPIChange = (e) => {

  setSelectedAPI(e.target.value);
}
  // theme styles
  const theme = createTheme({
    typography: {
      title: {
        fontFamily: "Oxanium, cursive",
        fontSize: 40,
        fontWeight: 600,
        color: "White",
      },
      subtitle: {
        fontFamily: "Oxanium, sans-serif",
        fontSize: 24,
        fontWeight: 600,
        color: "White",
      },
      caption: {
        fontFamily: "Work sans, cursive",
        fontSize: 16,
        fontWeight: 200,
        color: "White",
      },
    },
  });
  // textFiel Style
  const customTextFieldStyling = {
    "& label.Mui-focused": {
      color: "white",
    },
    "&:placeholder": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
        borderWidth: "2px",
        color: "#FFF",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
      color: "white",
    },
  };


  async function CreateBot() {

    console.log("selectedAPI", selectedAPI);
    // split value of selectedAPI into API_KEY and API_SECRET
    const [binanceCredentialID, API_KEY, API_SECRET] = selectedAPI.split(',');
    // Marge API_KEY and API_SECRET into Array Formate [API_KIEY=API_KEY,API_SECRET=API_SECRET]
    const API_Value = [ `API_KEY=${API_KEY}`, `API_SECRET=${API_SECRET}`];
    const url = await storeFirebaseImage(image);

    const imageName = uuidv4();
    const containerName = uuidv4();
    const remoteUrl = `https://${github_token}@github.com/${selectedValue}.git%23${branch}`;
    
    await buildImage(imageName, remoteUrl)
    await createContainer(containerName, imageName, API_Value);
     
    // paramter of crete Tradig bot (displayname,repo_url,image,description,dickerImageId, dockerContainerId,creatoraddress
    await createBot(
      displayName,
      binanceCredentialID,
      url,
      description,
      imageName,
      containerName,
      account
    );
    await updateUserRoleById(account,tokens)
    await connectBinanceCredential(account,binanceCredentialID, tokens)

    navigate("/viewownprofile");
  }

  const [allrepo, setAllrepo] = useState();
  const queryparam = window.location.search;
  const urlParams = new URLSearchParams(queryparam);
  const github_accessToken = localStorage.getItem("github_accessToken") || null;
  const github_username = localStorage.getItem("github_username") || null;
  const authorizeButton = () => {
    if (!queryparam) {
      if (!github_accessToken || !github_username) {
        return true;
      } else {
        listallRepo();
        return false;
      }
    } else if (queryparam) {
      const github_accessToken = urlParams.get("github_accessToken");
      const github_username = urlParams.get("github_username");
      localStorage.setItem("github_accessToken", github_accessToken);
      localStorage.setItem("github_username", github_username);
      if (!github_accessToken || !github_username) {
        return false;
      } else {
        listallRepo();
        return false;
      }
    }
  };
  // ** For Production mode
  const listallRepo = async () => {
    const res = await axios.post("https://trading-bot-api.vercel.app/api/repos", {
      github_accessToken,
      github_username,
    });
    const getdata = await res.data.items.map((data) => data.full_name);
    setAllrepo(getdata);
  };

  // ** For Developer mode 
  // const listallRepo = async () => {
  //   const res = await axios.post("http://157.230.47.113:3000/api/repos", {
  //     github_accessToken,
  //     github_username,
  //   });
  //   const getdata = await res.data.items.map((data) => data.full_name);
  //   setAllrepo(getdata);
  // };
  
  useEffect(() => {
    async function GetOwnUserDetials(){
      const res = await getOwnUserDetails(account, tokens);
      setData(res);
      console.log(res);
    }
    if (account !== null && tokens !== null) {
      GetOwnUserDetials();
    }
  }, [account, tokens])
  return (
    <form>
      <ThemeProvider theme={theme}>
        <Container maxWidth="lg" className="p-4 pb-20">
          <Stack direction="column" spacing={4}>
            <div>
              <Typography
                display="block"
                variant="title"
                className="text-white"
              >
                Create New Bot
              </Typography>
              <Typography display="block" variant="caption">
                <span style={{ marginRight: 2, color: "#e91e63" }}>*</span>
                Required fields
              </Typography>
            </div>
            <div className="space-y-4">
              <SndTitle text="Image" isRequired={true} />
              <CustomImageInput image={image} onImageChange={onImagechange} />
            </div>
            <div className="space-y-4">
              <SndTitle text="Name" isRequired={true} />
              <TextField
                fullWidth
                value={displayName}
                onChange={nameChange}
                type="text"
                variant="outlined"
                placeholder="Bot name"
                className="w-full"
                required
                sx={customTextFieldStyling}
              />
            </div>

            {authorizeButton() ? (
              <Button
                // ** For Production mode
                href="https://trading-bot-api.vercel.app/api/oauth/github/callback"
                // ** For Developer mode
                // href="http://157.230.47.113:3000/api/oauth/github/callback" 
                color="secondary"
                style={{
                  fontWeight: "bold",
                  width: 200,
                  marginTop: 30,
                  marginBottom: 30,
                  fontSize: 16,
                  padding: "6px 12px",
                  display: "flex",
                  color: "#212121",
                  backgroundColor: "#ffc400",
                  "&:hover": {
                    backgroundColor: "#F5FBF2",
                  },
                }}
              >
                authorize Github
              </Button>
            ) : allrepo ? (
              <Select
                // labelStyle={{ color: "white" }}
                sx={{
                  color: "white",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(228, 219, 233, 0.25)",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    // borderColor: "rgba(228, 219, 233, 0.25)",
                    borderColor: "white",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(228, 219, 233, 0.25)",
                  },
                  ".MuiSvgIcon-root ": {
                    fill: "white !important",
                  },
                }}
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value)}
              >
                {allrepo.map((d, i) => (
                  <MenuItem color="black" key={i} value={d}>
                    {d}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            )}
            <div className="space-y-4">
              <SndTitle text="Branch" />
              <TextField
                fullWidth
                value={branch}
                onChange={setbranch}
                type="text"
                variant="outlined"
                placeholder="Branch name"
                className="w-full"
                sx={customTextFieldStyling}
              />
            </div>
            <div className="space-y-4">
              <SndTitle text="Description" />
              <TextField
                fullWidth
                type="text"
                value={description}
                onChange={descriptionChange}
                multiline
                rows={7}
                variant="outlined"
                placeholder="Provide a detailed description of your bot."
                className="w-full"
                sx={customTextFieldStyling}
              />
            </div>
            <div className="space-y-4">
              <SndTitle text="API Key" />
              
              <Select
                sx={{
                    color: "white",
                    width: "100%!important",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      // borderColor: "rgba(228, 219, 233, 0.25)",
                      borderColor: "white",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(228, 219, 233, 0.25)",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "white !important",
                    },
                  }} 
                onChange={SelectedAPIChange} 
                value={selectedAPI}
                // onChange={(e) => setSelectedAPI(e.target.value)}
                >
                {data?.credentials?.map((credential, index) => (
                  credential.status === false ? (
                    <MenuItem color="black" key={index} value={`${credential.credentialID},${credential.apiKey},${credential.secretKey}`}>
                      {credential.credentialName}
                    </MenuItem>
                  ) : null
                ))}
              </Select>
            </div>
            {/* <div>{data.credentials}</div> */}
          </Stack>
          <div>
            <Button
              // href="viewownprofile"
              variant="outlined"
              onClick={CreateBot}
              // onClick={UpdateUser}
              // onClick={UpdateUserRole}
              // click to get link image from firestore
              // onClick={sendValue}
              // onClick={uploadImgIPFS}
              color="secondary"
              style={{
                fontWeight: "bold",
                width: 100,
                margin: "auto",
                marginTop: 30,
                marginBottom: 30,
                fontSize: 16,
                padding: "6px 12px",
                display: "flex",
                color: "#212121",
                backgroundColor: "#ffc400",
                "&:hover": {
                  backgroundColor: "#F5FBF2",
                },
              }}
            >
              Mint
            </Button>
            {/* <Button onClick={test}>Test</Button> */}
          </div>
        </Container>
      </ThemeProvider>
    </form>
  );
};
function SndTitle({ text, isRequired = false }) {
  return (
    <Typography display="block" variant="subtitle">
      {text}
      {isRequired && <span style={{ color: "#e91e63", marginLeft: 3 }}>*</span>}
    </Typography>
  );
}
function CustomImageInput({ image, onImageChange }) {
  return (
    <div>
      <ImageUploading
        value={image}
        onChange={onImageChange}
        maxNumber={1}
        dataURLKey="data_url"
      >
        {({ imageList, onImageUpdate }) => (
          <div>
            {imageList.length === 0 ? (
              <Box
                sx={{ border: "2px solid white", width: 300, height: 200 }}
                justifyContent="center"
                alignItems="center"
                onClick={onImageUpdate}
              >
                <img
                  src={imagePlaceholder}
                  alt="images_placeholder"
                  style={{
                    width: 50,
                    height: 40,
                    paddingTop: 80,
                    paddingLeft: 110,
                  }}
                />
              </Box>
            ) : (
              <div onClick={onImageUpdate}>
                <img
                  alt="images_placeholder"
                  src={image[0].data_url}
                  style={{
                    width: 300,
                    height: 200,
                  }}
                />
              </div>
            )}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

export default CreateTradingbot;
