import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import { Box } from "@mui/material";
import { Stack, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { ethers } from "ethers";
import { buyTradingBot } from "../../../services/smart contract/Smartcontract";
export default function Cards({ note, account, DisplayBalance }) {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    price: 0,
  });
  // eslint-disable-next-line no-unused-vars
  const { id, price } = formData;
  // eslint-disable-next-line no-unused-vars
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  // eslint-disable-next-line no-unused-vars
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();
  const onSubmit = async (e) => {
    formData.id = note.id - 1;
    try {
      if (DisplayBalance >= ethers.utils.formatEther(note.price)) {
        formData.price = ethers.utils.formatEther(note.price);
        setOpen(false);
        await buyTradingBot(formData.id, account, formData.price);
        navigate("/viewownprofile");
      } else if (DisplayBalance < ethers.utils.formatEther(note.price)) {
        setOpen(false);
      }
    } catch (error) {}
  };

  return (
    <div>
      <Card
        style={{
          color: "#FFFFFF",
          backgroundColor: "#3D2942",
          textAlign: "left",
        }}
        sx={{
          maxWidth: 395,
          fontWeight: "bold",
          fontSize: 16,
          borderRadius: "16px",
        }}
      >
        <CardMedia
          component="img"
          alt="green iguana"
          height="340"
          image={note.image}
          onClick={() => {
            navigate(`/viewbotdetail/` + note.id);
          }}
        />
        <CardHeader
          titleTypographyProps={{ variant: "h6" }}
          title={note.displayName}
        />
        <CardContent>
          <Box m={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">Current Bid</Typography>
              <Typography variant="body2">
                {ethers.utils.formatEther(note.price)} Ether
              </Typography>
            </Stack>
          </Box>
          <Typography variant="body2">{note.description}</Typography>
        </CardContent>
        <Box pb={3}>
          {note.isSaleBot === true && note.OwnerAddress !== account ? (
            <Grid>
              <Dialog open={open} onClose={handleClose}>
                <Box
                  style={{
                    width: 400,
                    height: 220,
                    background: "#302034",
                  }}
                >
                  <DialogTitle color="#ededed">Buy Trading Bot</DialogTitle>
                  <DialogContent>
                    <DialogContentText color="#ededed" sx={{ fontSize: 15 }}>
                      Comnfirm Payment
                    </DialogContentText>
                    <DialogContentText color="#b28900" sx={{ fontSize: 40 }}>
                      {ethers.utils.formatEther(note.price)} ETH
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      background: "#302034",
                    }}
                  >
                    <Button
                      sx={{
                        ":hover": {
                          color: "#12db00",
                        },
                      }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        ":hover": {
                          color: "#12db00",
                        },
                      }}
                      onClick={onSubmit}
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Box>
              </Dialog>
              <Button
                variant="outlined"
                color="neutral"
                onClick={() => {
                  setOpen(true);
                }}
                style={{
                  margin: "0 auto",
                  display: "flex",
                  width: "90%",
                  "&:hover": {
                    backgroundColor: "#F5FBF2",
                  },
                }}
              >
                Buy Now
              </Button>
            </Grid>
          ) : (
            <></>
          )}
        </Box>
      </Card>
    </div>
  );
}