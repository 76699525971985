import { SectionTitle } from "../../sectionTitle/sectionTitle"
import TeamMember from "./teammembercard/teammember"
import TeamStyleWrapper from "./team.style"
import data from "../../../assets/data/team/data"
import { Grid, Typography,Stack } from '@mui/material'

const Team = () => {
    return (
        <TeamStyleWrapper>
        <SectionTitle
          isCenter={true}
          title="MEET THE CREW"
          subtitle="TEAM MEMBERS"
        />
        <div className="container">
        <Grid container  p={{sx: 5, sm: 5, md: 10}} spacing={{sx: 2, sm: 5, md: 5}} columns={{sx: 4, sm: 8, md:16}}>
            {data?.map((member, index) => (
                <Grid
                item xs={4}
                style={{
                    width:"300px"
                    }}
                    m={"auto"}
                    key={index}
                    >
                   

                <TeamMember {...member} />
                </Grid>
            ))}
        </Grid> 
        </div>
      </TeamStyleWrapper>
    )
}

export default Team