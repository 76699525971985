import { createTheme } from '@mui/material/styles';

const arcBlue = "#0B2B9"
const arcOrange = "#FFBA60"
export default createTheme({
    palette: {
        primary: {
          main: `${arcBlue}`
        },
        secondary: {
          main: `${arcOrange}`
        },
        neutral: {
            main: '#FFCE4E',
            contrastText: '#FFCE4E',
            
        },
        input_color: {
            main: '#6D788D',
        },
        white:{
            main: '#FFFFFFF',
            contrastText: '#FFFFFFF',
        }
        
      },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '.wrapper': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999
          },
        },
      },
    },
})