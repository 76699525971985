import React from 'react'

const AboutUS = (props) => {
  return (
    <div>
        this is an about us 

    </div>
  )
}

export default AboutUS