import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Box } from '@mui/system'
import { Grid, Typography,Stack } from '@mui/material'

import { useState,useEffect } from 'react';
import Cards from '../../components/Main/Landing-Page/cards'
import { getAllBots } from '../../services/smart contract/Smartcontract';
import Skeletoncard from '../../components/Main/Landing-Page/skeleton_card'

const theme = createTheme({
    // Tell MUI what the font-size on the html element is.
    typography: {
      subtitle1: {
        fontSize: 33,
        fontFamily: "Montserrat",
        fontWeight: 300,
        color: "#F5FBF2",
        letterSpacing: "0.0075em",
        verticalAlign: "middle",
        alignItems: "center",
        textAlign: "center",
        main:'#F5FBF2',
        marginTop:20
      },
    },
    palette: {
      neutral: {
        main: '#FFCE4E',
        contrastText: '#000000',
      }
    }
  });
function View_all_popular_bot ({account,DisplayBalance}){
    // eslint-disable-next-line no-unused-vars
    const [sort, setSort] = useState('');
    const [po_bot, setPop_bot] = useState([])
      // eslint-disable-next-line no-unused-vars
    const handleChange = (event) => {
        setSort(event.target.value);
    };
    async function GotAllBot(){
        const data = await getAllBots();
        setPop_bot(data)
    }
    useEffect(() => {
        GotAllBot() 
    },[])
    return(
       <ThemeProvider theme={theme}>
        <Box p={5}>
            <Typography variant="subtitle1">ALL Popular Trading Bot</Typography>
            <Box >
          <Stack >
            {!po_bot.length ? 
            <Box>
                <Box direction={{ xs: "column", sm: "row" }} pt={10} pb={10} >
                <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between" >
                <Skeletoncard/>
                <Skeletoncard/>
                <Skeletoncard/>
                <Skeletoncard/>
                </Stack>
                </Box>
            </Box>
            :
            <Grid container spacing={{ xs: 1, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {po_bot.map((i,index) =>(
                <Grid
                    style={{
                    width:"395px"
                    }}
                    m={"auto"}
                    pt={10}
                    pb={10}
                    key={index}
                    >
                <Cards note={i} account={account} DisplayBalance={DisplayBalance}/>
                </Grid>
            ))}
            </Grid>
            }
        </Stack>
        </Box>
        </Box>
       </ThemeProvider>
    );
}
export default View_all_popular_bot;