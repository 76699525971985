import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link, Stack } from "@mui/material";
import logo from "../../assets/image/logo.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Footer = () => {
  const theme = createTheme({
    // Tell MUI what the font-size on the html element is.
    typography: {
      subtitle1: {
        fontSize: 33,
        fontFamily: "Montserrat",
        fontWeight: 300,
        color: "#F5FBF2",
        // letterSpacing: "0.0075em",
        main: "#F5FBF2",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        bgcolor="text.secondary"
        color="white"
        justifyContent="space-around"
        sx={{mt:5}}
      >
        <Stack direction={{ xs: "row", sm: "row" }} item xs={12} md={3}>
          <Grid
           sx={{ mt: 3, mr: 5 }}
           >
            <Box>
              <img src={logo} alt="logo" />
            </Box>
            <Box borderBottom={1} sx={{ mt: 5 }} md={{display:'none'}}></Box>
          </Grid>
        </Stack>
        <Stack direction={{ xs: "row", sm: "row" }} 
        >
          <Grid 
           >
            <p>
              Nec, enim sed lacus, magna pharetra.<br></br>Phasellus tincidunt
              nulla pharetra<br></br> gravida est.
            </p>
            <Box>
              <Link href="/" color="inherit" underline="none" sx={{ mr: 0 }}>
                <YouTubeIcon />
              </Link>
              <Link href="/" color="inherit" underline="none" sx={{ ml: 3 }}>
                <TwitterIcon />
              </Link>
              <Link href="/" color="inherit" underline="none" sx={{ ml: 3 }}>
                <FacebookIcon />
              </Link>
              <Link href="/" color="inherit" underline="none" sx={{ ml: 3 }}>
                <InstagramIcon />
              </Link>
            </Box>
            <p style={{ fontWeight: 80 }}>All rights reserved@2021</p>
          </Grid>
        </Stack>
        <Stack
          xs={{ ml: -6 }}
          // md={4}
          direction={{ xs: "row", sm: "row" }}
          justifyContent="start"
        >
          <Grid sx={{ mt: 3, mr: 3, mb: 5 }}>
            <Box>
              <Link
                href="/"
                color="inherit"
                underline="none"
                sx={{ fontWeight: 1000 }}
              >
                About us
              </Link>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Link href="/" color="inherit" underline="none">
                About NFTs
              </Link>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Link href="/" color="inherit" underline="none">
                Live Auctions
              </Link>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Link href="/" color="inherit" underline="none">
                NFT Blog
              </Link>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Link href="/" color="inherit" underline="none">
                Activity
              </Link>
            </Box>
          </Grid>
          <Grid sx={{ mt: 3, ml: 5, mb: 5 }}>
            <Box>
              <Link
                href="/"
                color="inherit"
                underline="none"
                sx={{ fontWeight: 1000 }}
              >
                Support
              </Link>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Link href="/" color="inherit" underline="none">
                Help & Support
              </Link>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Link href="/" color="inherit" underline="none">
                Item Details
              </Link>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Link href="/" color="inherit" underline="none">
                Author Profile
              </Link>
            </Box>
            <Box sx={{ mt: 1 }}>
              <Link href="/" color="inherit" underline="none">
                Collection
              </Link>
            </Box>
          </Grid>
        </Stack>
      </Grid>
    </ThemeProvider>
  );
};

export default Footer;
