import TeamDetailsStyleWrapper from './TeamDetails.style';
const eachteamdetails = ({averta, name, designation, socialLinks}) => {
    return(
        <TeamDetailsStyleWrapper>
            <di className="container">
                <div className='row' style={{textAlign:'center'}}>
                    <div className='col-md-5'>
                        <div className='left-content=thumb'>
                            <img src={averta} alt="thumb" />
                        </div>

                    </div>
                    <div className='col-md-7'>
                        <div className='right_content'>
                            <h3 className='member_title'>
                            {name}
                                <span>{designation}</span>
                            </h3>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            </p>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            </p>
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            </p>

                            <ul className="member_details">
                                <li>
                                    <strong>Experience: </strong> <span> 10+ years</span>
                                </li>
                                <li>
                                    <strong>Email:</strong> <span> gmail@gmail.com</span>
                                </li>
                                <li>
                                    <strong>Phone:</strong> <span> 123456789</span>
                                </li>
                                <li className='social_items'>
                                    <strong>Social: </strong>
                                    {socialLinks?.map((item, i) => (
                                    <li key={i}>
                                        <a href={item.url} target="_blank" rel="noreferrer">{item.icon}</a>
                                    </li>
                                    ))}

                                </li>
                            </ul>
                        </div>

                    </div>

                </div>

            </di>
        </TeamDetailsStyleWrapper>

    )
}

export default eachteamdetails;