import {
    TbBrandLinkedin,
    TbBrandTwitter,
    TbBrandFacebook,
    TbBrandGithub
  } from "react-icons/tb";

import averta_theanlay from "../../../assets/image/team/thomas_m.png"
import averta_Huameng from "../../../assets/image/team/thomas_g.png"
import averta_Chantha from "../../../assets/image/team/zac.png"
import averta_DoungChan from "../../../assets/image/team/sam.png"
import averta_Visoth from "../../../assets/image/team/jonathon.png"
import averta_Rothana from "../../../assets/image/team/eugeny.png"
import averta_Sereyvath from "../../../assets/image/team/ed.png"
import averta_Navin from "../../../assets/image/team/chris.png"

const data = [
    {
        averta: averta_theanlay,
        name: "Thorn Theanlay",
        designation: "CEO",
        socialLinks: [
            {
                icon: <TbBrandLinkedin />,
                url: "https://www.linkedin.com/in/thorn-theanlay-15588310a/",
            },
            {
                icon: <TbBrandTwitter />,
                url: "https://twitter.com/SokhengSeng",

            },
            {
                icon: <TbBrandFacebook />,
                url: "https://www.facebook.com/theanlay.thon",
            },
            {
                icon: <TbBrandGithub />,
                url: "https://github.com/theanlay"
            }

        ],
    },
    {
        averta: averta_Huameng,
        name: "Lim Huameng",
        designation: "CTO",
        socialLinks: [
            {
                icon: <TbBrandLinkedin />,
                url: "https://www.linkedin.com/in/lim-huameng/",
            },
            {
                icon: <TbBrandTwitter />,
                url: "https://twitter.com/huameng12810",
            },
            {
                icon: <TbBrandFacebook />,
                url: "https://www.facebook.com/huameng.lim",
            },
            {
                icon: <TbBrandGithub />,
                url: "https://github.com/Hua-Meng14"
            }
        ],

    },
    {
        averta: averta_Chantha,
        name: "Yin Chantha",
        designation: "Fullstack Developer",
        socialLinks: [
          {
            icon: <TbBrandLinkedin />,
            url: "https://www.linkedin.com/in/chantha-yin-a68a61203/",
          },
          {
            icon: <TbBrandTwitter />,
            url: "https://twitter.com/yinchantha5",
          },
          {
            icon: <TbBrandFacebook />,
            url: "https://www.facebook.com/chan.tha.7731",
          },
          {
            icon: <TbBrandGithub />,
            url: "https://github.com/YinChantha"
          }
        ],
      },
    {
        averta: averta_DoungChan,
        name: "Sreun DoungChan",
        designation: "Fullstack Developer",
        socialLinks: [
            {
                icon: <TbBrandLinkedin />,
                url: "https://www.linkedin.com/in/chantha-yin-1b1b3b1b2/",
            },
            {
                icon: <TbBrandTwitter />,
                url: "https://twitter.com/home",
            },
            {
                icon: <TbBrandFacebook />,
                url: "https://www.facebook.com/doung.chan.3950",
            },
            {
                icon: <TbBrandGithub />,
                url: "https://github.com/DoungChan"
            }
        ],
    },
    {
        averta: averta_Visoth,
        name: "Heng Rathanak Visoth",
        designation: "Fullstack Developer",
        socialLinks: [
            {
                icon: <TbBrandLinkedin />,
                url: "https://www.linkedin.com/feed/",
            },
            {
                icon: <TbBrandTwitter />,
                url: "https://twitter.com/home",
            },
            {
                icon: <TbBrandFacebook />,
                url: "https://www.facebook.com/chantha.yin.5/",
            },
            {
                icon: <TbBrandGithub />,
                url: "https://github.com/hengvisoth"
            }
        ],
    },
    {
        averta: averta_Rothana,
        name: "Krean Rothana",
        designation: "Fullstack Developer",
        socialLinks: [
            {
                icon: <TbBrandLinkedin />,
                url: "https://www.linkedin.com/in/rathana-krean-469888210/",
            },
            {
                icon: <TbBrandTwitter />,
                url: "https://twitter.com/home",
            },
            {
                icon: <TbBrandFacebook />,
                url: "https://www.facebook.com/krean.rathana",
            },
            {
                icon: <TbBrandGithub />,
                url: "https://github.com/rathanamsk"
            }
        ],
    },
    {
        averta: averta_Sereyvath,
        name: "Kim Sereyvath",
        designation: "Fullstack Developer",
        socialLinks: [
            {
                icon: <TbBrandLinkedin />,
                url: "https://www.linkedin.com/in/sereyvath/",
            },
            {
                icon: <TbBrandTwitter />,
                url: "https://twitter.com/home",
            },
            {
                icon: <TbBrandFacebook />,
                url: "https://www.facebook.com/ksvsereyvath",
            },
            {
                icon: <TbBrandGithub />,
                url: "https://github.com/ksereyvath"
            }
        ],
    },
    {
        averta: averta_Navin,
        name: "Seab Navin",
        designation: "Fullstack Developer",
        socialLinks: [
            {
                icon: <TbBrandLinkedin />,
                url: "https://www.linkedin.com/in/chantha-yin-1b1b3b1b2/",
            },
            {
                icon: <TbBrandTwitter />,
                url: "https://twitter.com/navin_seab",
            },
            {
                icon: <TbBrandFacebook />,
                url: "https://www.facebook.com/profile.php?id=100014509242190",
            },
            {
                icon: <TbBrandGithub />,
                url: "https://github.com/seabnavin19"
            }
        ],

    }


]

export default data;