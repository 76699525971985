import React from 'react'
import Popular from '../../components/Main/Landing-Page/popular'
import Topcreator from '../../components/Main/Landing-Page/Topcreator';
import Features from "../../components/Main/Landing-Page/Features"
import Marketplace from '../../components/Main/Landing-Page/Marketplace';
import Partners from '../../components/Main/Partner/index';
import Teams from '../../components/Main/teams'
const landing_page = ({DisplayBalance, account}) =>{
    return(
       <div>
        <Marketplace />
        <Popular DisplayBalance={DisplayBalance} account={account}/>
        <Features />
        <Topcreator />
        <Teams/>
        <Partners />
       </div>
    );
}

export default landing_page;